import React, { useState } from "react";
import styles from "../../Styles/adminNav.module.css";
import { NavLink } from "react-router-dom";
import DashboardIcon from "../../assets/Icons/dashboard.png";
import UsersIcon from "../../assets/Icons/group.png";
import Coupon from "../../assets/Icons/finance.png";
import CalendarIcon from "../../assets/Icons/calendarIcon.png";
import { GlobalContext } from "../../GlobalContext/GlobalContext";
import { useContext } from "react";
import AdminUser from "../../assets/Icons/adminUser.png";
import { fetchUserRole } from "../../API/apiService";

export default function AdminNavbar() {
  const { activeTab, handleActiveTab } = useContext(GlobalContext);
  const userRoles = fetchUserRole();
  return (
    <div className={styles.container}>
      <div className={styles.elements}>
        <h3 className={styles.title}>Admin Panel</h3>
        <div
          onClick={() => {
            handleActiveTab("dashboard");
          }}
        >
          <NavLink className="link" to="/admin-panel/dashboard/scan">
            <div className={styles.itemCont}>
              <img
                className={styles.icon}
                src={DashboardIcon}
                alt="dashboard-icon"
              />
              <p className={activeTab.dashboard ? styles.activeNavTab : ""}>
                Dashboard
              </p>
            </div>
          </NavLink>
        </div>

        <div
          onClick={() => {
            handleActiveTab("users");
          }}
        >
          <NavLink className="link" to="/admin-panel/users">
            <div className={styles.itemCont}>
              <img className={styles.icon} src={UsersIcon} alt="users-icon" />
              <p className={activeTab.users ? styles.activeNavTab : ""}>
                Users
              </p>
            </div>
          </NavLink>
        </div>
        {/* <div
          onClick={() => {
            handleActiveTab("calendar");
          }}
        >
          <NavLink to="/admin-panel/calendar" className="link">
            <div className={styles.itemCont}>
              <img
                className={styles.icon}
                src={CalendarIcon}
                alt="calendar-img"
              />
              <p className={activeTab.calendar ? styles.activeNavTab : ""}>
                Calendar
              </p>
            </div>
          </NavLink>
        </div> */}
        <div
          onClick={() => {
            handleActiveTab("coupons");
          }}
        >
          <NavLink to="/admin-panel/coupons" className="link">
            <div className={styles.itemCont}>
              <img className={styles.icon} src={Coupon} alt="coupon-img" />
              <p className={activeTab.coupons ? styles.activeNavTab : ""}>
                Coupon
              </p>
            </div>
          </NavLink>
        </div>
        {userRoles.includes("Superadmin") && (
          <div
            onClick={() => {
              handleActiveTab("admins");
            }}
          >
            <NavLink to="/admin-panel/admin-management" className="link">
              <div className={styles.itemCont} style={{ marginLeft: "20px" }} >
                <img className={styles.icon} src={AdminUser} alt="admins-img" />
                <p className={activeTab.admins ? styles.activeNavTab : ""}>
                  Manage Admins
                </p>
              </div>
            </NavLink>
          </div>
        )}
      </div>
    </div>
  );
}
