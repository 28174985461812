import React from 'react';
import { Grid, TextField, Typography, Tooltip, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Geocoding from '../Geocoding';
import ValidatedTextField from "./ValidateTextField";
import { useTranslation } from 'react-i18next';
import { textValidator, emailValidator, positiveNumberValidator, phoneNumberValidator, noValidator } from "../../Validators/validator";


const PropertyInfoScan = ({ realEstateScan, setRealEstateScan }) => {
  const { t } = useTranslation(); 

  const handleNestedInputChange = (event, parent, child) => {
    event.preventDefault();
    const { name, value } = event.target;

    if (child === 'AddressLine') {
      setRealEstateScan(prevState => ({
        ...prevState,
        PropertyInfoScan: {
          ...prevState.PropertyInfoScan,
          Address: {
            ...prevState.PropertyInfoScan.Address,
            AddressLine: value
          }
        }
      }));
    } else if (child === 'ProjectName') {
      setRealEstateScan(prevState => ({
        ...prevState,
        ProjectName: value
      }));
    } else {
      setRealEstateScan(prevState => ({
        ...prevState,
        [parent]: {
          ...prevState[parent],
          [child]: value
        }
      }));
    }
  };

 

  return (
    <div>
      <p className="gradient-text propInfo-title">
        <span>{t('propertyInfoScan.title')}</span>
      </p>
      <div className='propInfo-grid2'>
        <div>
          <ValidatedTextField
            label={t('propertyInfoScan.projectName')}
            type='text'
            parent='ProjectName'
            fieldName='ProjectName'
            showAdornment={false}
            validator={noValidator}
            onChange={handleNestedInputChange}
          />
          <ValidatedTextField
            label={t('propertyInfoScan.address')}
            type='text'
            parent='Address'
            fieldName='AddressLine'
            optional={true}
            validator={noValidator}
            onChange={handleNestedInputChange}
          />
        </div>
        <div>
          <ValidatedTextField
            label={t('propertyInfoScan.page')}
            type='text'
            parent='PropertyInfoScan'
            fieldName='Page'
            optional={true}
            validator={noValidator}
            onChange={handleNestedInputChange}
          />
          <ValidatedTextField
            label={t('propertyInfoScan.volume')}
            type='text'
            parent='PropertyInfoScan'
            fieldName='Volume'
            optional={true}
            validator={noValidator}
            onChange={handleNestedInputChange}
          />
        </div>
        <div>
          <ValidatedTextField
            label={t('propertyInfoScan.mapLegend')}
            type='text'
            parent='PropertyInfoScan'
            fieldName='NomenclatureMap'
            optional={true}
            validator={noValidator}
            onChange={handleNestedInputChange}
          />
          <ValidatedTextField
            label={t('propertyInfoScan.ownerName')}
            type='text'
            parent='PropertyInfoScan'
            fieldName='OwnerName'
            optional={true}
            validator={textValidator}
            onChange={handleNestedInputChange}
          />
        </div>
        <div>
          <ValidatedTextField
            label={t('propertyInfoScan.propertyNumber')}
            type='text'
            parent='PropertyInfoScan'
            fieldName='PropertyNumber'
            optional={true}
            validator={noValidator}
            onChange={handleNestedInputChange}
          />
          <ValidatedTextField
            label={t('propertyInfoScan.referencePrice')}
            type='number'
            parent='PropertyInfoScan'
            fieldName='ReferencePrice'
            optional={true}
            validator={positiveNumberValidator}
            onChange={handleNestedInputChange}
          />
        </div>
        <div>
          <ValidatedTextField
            label={t('propertyInfoScan.cadasterZone')}
            type='text'
            parent='PropertyInfoScan'
            fieldName='CadastralRegion'
            optional={true}
            validator={noValidator}
            onChange={handleNestedInputChange}
          />
        </div>
      </div>
    </div>
  );
};

export default PropertyInfoScan;
