import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableVirtuoso } from 'react-virtuoso';
import { getUsers } from '../../../API/apiService';
import { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import toast from 'react-hot-toast';


function createData(name, surname, email, scanOrders, digitalOrders) {
  return { name, surname, email, scanOrders, digitalOrders };
}

const columns = [
  {
    width: 200,
    label: 'Name',
    dataKey: 'name',
  },
  {
    width: 200,
    label: 'Surname',
    dataKey: 'surname',
  },
  {
    width: 300,
    label: 'Email',
    dataKey: 'email',
  },
  {
    width: 200,
    label: 'Scan Orders',
    dataKey: 'scanOrders',
  },
  {
    width: 200,
    label: 'Digital Order',
    dataKey: 'digitalOrders',
  },
];



const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
  ),
  TableHead: React.forwardRef((props, ref) => <TableHead {...props} ref={ref} />),
  TableRow,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

function fixedHeaderContent() {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          style={{ width: column.width, textAlign:'center' }}
          sx={{
            backgroundColor: 'background.paper',
          }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

function rowContent(_index, row) {
  return (
    <React.Fragment>
      {columns.map((column) => (
        <TableCell key={column.dataKey} style={{ textAlign: 'center' }}>
          {row[column.dataKey]}
        </TableCell>
      ))}
    </React.Fragment>
  );
}

export default function UsersGrid() {

  const [rows, setRows] = useState([]); // State to hold user data
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    // Fetch users when the component mounts
    setLoading(true);
    toast.dismiss();
    const toastId = toast.loading('...Loading users')
    const fetchUsers = async () => {
      try {
        const users = await getUsers(); // Assume getUsers fetches the data and returns an array
        const formattedUsers = users.map(user => 
          createData(user.name, user.surname, user.email, user.scanOrders, user.digitalOrders)
        );
        setRows(formattedUsers); // Update the state with fetched data
      } catch (error) {

      }finally{
        setLoading(false);
        toast.dismiss(toastId);
      }
    };

    fetchUsers(); // Call the fetch function
  }, []); // Empty dependency array ensures this runs once on mount


  return (
    loading=== false && (
       <>
    <h3 style={{margin:12, color:'#163D6C', fontSize:25, textAlign:'center'}}>Users</h3>
    {rows?.length > 0 ? 
    <Paper style={{ height: 650, width: '100%' }}>
      <TableVirtuoso
        data={rows}
        components={VirtuosoTableComponents}
        fixedHeaderContent={fixedHeaderContent}
        itemContent={rowContent}
        sx={{textAlign:'center'}}
      />
    </Paper>
  :
  <Typography
      sx={{textAlign:'center', mt:5,fontSize:22, color:'#353a44'}}
  >
    No user registerd in system yet.
  </Typography>
  }
      
    </>
    )
   
  
  );
}
