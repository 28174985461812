import React, { useState } from "react";
import { IconButton, Tooltip, TextField, Button } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import CandidateReviewImg from '../../assets/Images/candidateReviewImg.png';
import ValidatedTextField from "../Services/ValidateTextField";
import { emailValidator, textValidator, phoneNumberValidator, noValidator } from "../../Validators/validator";
import initialiCandidate from '../../Models/candidate';
import { candidateRequest } from "../../API/apiService";
import toast from "react-hot-toast";
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import {CircularProgress} from "@mui/material";


export default function CandidateSpecialist(props) {
  const { t } = useTranslation(); // Initialize useTranslation
  const [candidate, setCandidate] = useState(initialiCandidate);
  const candidateType = props.candidateType;
  const [loading, setLoading] = useState(false)
  const handleCandidateChange = (event, parent, child) => {
    const value = event.target.value;

    setCandidate(prevState => ({
      ...prevState,
      [child]: value
    }));
  };

  const handleCandidateRequest = async () => {
    
    if (candidate.name === '' || candidate.surname === '' || candidate.email === '' ||
      candidate.city === '' || candidate.phoneNumber === '') {
      toast.error(t('candidateSpecialist.errors.allFieldsRequired'));
      return;
    }
    try {
      setLoading(true)
      setCandidate(prevState => ({
        ...prevState,
        type: candidateType === 'scanning' ? 'scan' : 'bim'
      }));
      var response = await candidateRequest(candidate);
      if (response?.success) {
        toast.success(t('candidateSpecialist.errors.applicationSuccess'));
      } else {
        toast.error(t('candidateSpecialist.errors.applicationFailure'));
      }
    } catch (error) {
      
    }finally{
      setLoading(false)
      setCandidate(initialiCandidate)
    }
  };

  return (
    <div className="candidate-container">
      <div className="candidate-criteria">
        <p>{t('candidateSpecialist.criteriaTitle')}:</p>
        {candidateType === 'scanning' ?
          <ul>
            {t('candidateSpecialist.criteriaScanning', { returnObjects: true }).map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          :
          <ul>
            {t('candidateSpecialist.criteriaBIM', { returnObjects: true }).map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        }
      </div>
      <div className="candidate-form-container">
        <div className="candidate-formular">
          <div>
            <ValidatedTextField
              label={t('candidateSpecialist.formLabels.city')}
              type="text"
              fieldName="city"
              value={candidate.city}
              showAdornment={false}
              validator={textValidator}
              onChange={handleCandidateChange}
            />
            <ValidatedTextField
              label={t('candidateSpecialist.formLabels.name')}
              type="text"
              fieldName="name"
              value={candidate.name}
              showAdornment={false}
              validator={textValidator}
              onChange={handleCandidateChange}
            />
            <ValidatedTextField
              label={t('candidateSpecialist.formLabels.surname')}
              type="text"
              fieldName="surname"
              value={candidate.surname}
              showAdornment={false}
              validator={textValidator}
              onChange={handleCandidateChange}
            />
            <ValidatedTextField
              label={t('candidateSpecialist.formLabels.phoneNumber')}
              type="text"
              fieldName="phoneNumber"
              value={candidate.phoneNumber}
              showAdornment={false}
              validator={phoneNumberValidator}
              onChange={handleCandidateChange}
            />
            <ValidatedTextField
              label={t('candidateSpecialist.formLabels.email')}
              type="email"
              fieldName="email"
              value={candidate.email}
              showAdornment={false}
              validator={emailValidator}
              onChange={handleCandidateChange}
            />
            <ValidatedTextField
              label={t('candidateSpecialist.formLabels.cv')}
              type="text"
              fieldName="cv"
              value={candidate.cv}
              tooltip={t('candidateSpecialist.tooltip')}
              validator={noValidator}
              onChange={handleCandidateChange}
            />
            <ValidatedTextField
              label={t('candidateSpecialist.formLabels.portfolio')}
              type="text"
              fieldName="portfolio"
              value={candidate.portfolio}
              optional={true}
              tooltip={t('candidateSpecialist.tooltip')}
              validator={noValidator}
              onChange={handleCandidateChange}
            />
          </div>
          <div>
            <img className="img-candidate" src={CandidateReviewImg} alt="candidate-review-img" />
          </div>
        </div>
        <div>
          {/* <Button
            onClick={handleCandidateRequest}
            className="btn-submit-candidate"
          >
            {t('candidateSpecialist.submitButton')}
          </Button> */}
          <Button
            variant="contained"
            color="inherit"
            sx={{
              backgroundColor: "#163D6C",
              color: "white",
              padding: 1,
              textTransform: "none",
              width: 150,
              "&:hover": {
                backgroundColor: "#122c51",
              },
            }}
            onClick={handleCandidateRequest}
            disabled={loading}
            startIcon={loading ? <CircularProgress size={24} /> : null}
          >
            {loading ? t('priceScan.processing') : t('candidateSpecialist.submitButton')}
          </Button>
        </div>
      </div>
    </div>
  );
}
