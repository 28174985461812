import React from 'react';
import ReactPlayer from 'react-player';

const VideoPlayer = () => {
  return (
    <div className="player-wrapper">
   <iframe src="https://drive.google.com/file/d/1tI3ityhcKK_jrsWdp18E7r2ZAS786Ib4/preview" 
   className="more-info-img"
   height={700}
   allow="autoplay"></iframe>
    </div>
  );
};

export default VideoPlayer;
