import React from "react";
import Privacy1Img from '../../assets/Images/privacy1.png';
import Privacy2Img from '../../assets/Images/privacy2.png';
import Privacy3Img from '../../assets/Images/privacy3.png';
import Privacy4Img from '../../assets/Images/privacy4.png';
import Privacy5Img from '../../assets/Images/privacy5.png';
import Privacy6Img from '../../assets/Images/privacy6.png';
import MsgIcon from '../../assets/Icons/msgIcon.png';
import LocationIcon from '../../assets/Icons/locationIcon.png';
import CallIcon from '../../assets/Icons/callIcon.png';
import { useTranslation } from 'react-i18next';

export default function PrivacyPolicy() {
  const { t } = useTranslation();

  return (
    <div className="privacy-container">
      <div className="privacy-controller-container">
        <p>{t('privacyPolicy.title')}</p>
        <div className="privacy-content">
          <div className="pc-1">
            <div>
              <p>{t('privacyPolicy.introductionTitle')}</p>
              <p>{t('privacyPolicy.introductionText')}</p>
            </div>
            <div>
              <img src={Privacy1Img} alt="privacy-img-1" />
            </div>
          </div>
          <div className="pc-2">
            <div>
              <ul>
                <li>{t('privacyPolicy.purposePoint')}</li>
              </ul>
              <p>{t('privacyPolicy.purposeTitle')}</p>
              <p>{t('privacyPolicy.purposeText')}</p>
            </div>
            <div>
              <img src={Privacy2Img} alt="privacy-img-2" />
            </div>
          </div>
          <div className="pc-3">
            <div>
              <p>{t('privacyPolicy.controllerTitle')}</p>
              <p>{t('privacyPolicy.controllerText')}</p>
            </div>
            <div>
              <img src={Privacy3Img} alt="privacy-img-3" />
            </div>
          </div>
          <div className="privacy-contact">
            <div className="pc-4">
              <div>
                <p>{t('privacyPolicy.contactTitle')}</p>
                <p>{t('privacyPolicy.contactText')}</p>
              </div>
              <div>
                <img src={MsgIcon} alt="email-icon" />
                <p className="email-display">{t('privacyPolicy.email')}</p>
                {/* <img src={LocationIcon} alt="location-icon" />
                <p>{t('privacyPolicy.address')}</p>
                <img src={CallIcon} alt="call-icon" />
                <p>{t('privacyPolicy.phone')}</p> */}
              </div>
            </div>
            <div>
              <img src={Privacy4Img} alt="privacy-img-4" />
            </div>
          </div>
          <div className="pc-5">
            <div>
              <p>{t('privacyPolicy.informationCollectedTitle')}</p>
            </div>
            <div className="pc-5-content">
              <div>
                <p>{t('privacyPolicy.informationCollectedText')}</p>
                <ul>
                  <li>{t('privacyPolicy.informationCollectedItem1')}</li>
                  <li>{t('privacyPolicy.informationCollectedItem2')}</li>
                  <li>{t('privacyPolicy.informationCollectedItem3')}</li>
                  <li>{t('privacyPolicy.informationCollectedItem4')}</li>
                  <li>{t('privacyPolicy.informationCollectedItem5')}</li>
                  <li>{t('privacyPolicy.informationCollectedItem6')}</li>
                  <li>{t('privacyPolicy.informationCollectedItem7')}</li>
                  <li>{t('privacyPolicy.informationCollectedItem8')}</li>
                </ul>
              </div>
              <div>
                <img src={Privacy5Img} alt="privacy-img-5" />
              </div>
            </div>
          </div>
          <div className="pc-5">
            <div>
              <p>{t('privacyPolicy.informationUsesTitle')}</p>
            </div>
            <div className="pc-5-content">
              <div>
                <p>{t('privacyPolicy.informationUsesText')}</p>
                <ul>
                  <li>{t('privacyPolicy.informationUsesItem1')}</li>
                  <li>{t('privacyPolicy.informationUsesItem2')}</li>
                  <li>{t('privacyPolicy.informationUsesItem3')}</li>
                  <li>{t('privacyPolicy.informationUsesItem4')}</li>
                  <li>{t('privacyPolicy.informationUsesItem5')}</li>
                  <li>{t('privacyPolicy.informationUsesItem6')}</li>
                  <li>{t('privacyPolicy.informationUsesItem7')}</li>
                </ul>
              </div>
              <div>
                <img src={Privacy6Img} alt="privacy-img-6" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
