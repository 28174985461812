export const pricingScan = {
    Interior: {
      Residentiale: 0.35 ,
      Commerciale: 0.45 ,
      Industriale: 0.55,
      Institucionale: 0.65
    },
    Exterior: {
      Residentiale: 0.25,
      Commerciale: 0.35,
      Industriale: 0.45,
      Institucionale: 0.55
    },
    Site: {
      Residentiale: 0.15,
      Commerciale: 0.25,
      Industriale: 0.35,
      Institucionale: 0.45
    }
  };
  

  