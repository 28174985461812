import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import calcImage from "../assets/Images/calcImage.png";
import InfoIcon from "../assets/Icons/InfoIcon.png";
import {
  getTotalPriceDigital,
  getWokringDaysRequired,
} from "../Helpers/helpers";
import {
  postDigitalOrder,
  getCurrrentUser,
  paymentCheckout,
  checkCouponUsability,
  getCouponByName,
  assignCouponToUser,
  getDigitalProjectByName,
} from "../API/apiService";
import toast from "react-hot-toast";
import { validateDigitalProject } from "../Validators/validator";
import { validateFields } from "../Validators/validator";
import TextField from "@mui/material/TextField";
import { Tooltip } from "@mui/material";
import { IconButton, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import initialRealEstateDigital from "../Models/DigitalServiceModel";

const PricingCardDigital = ({ digitalModel, setDigitalModel }) => {
  const { t } = useTranslation();
  const [price, setPrice] = useState(0);
  const [workingDays, setTodalWorkingDays] = useState([]);
  const [coupon, setCoupon] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const spaceSize = digitalModel?.propertyInfoDigital?.propertySize;
  const unit = digitalModel?.propertyInfoDigital?.unit;
  const propertyType = digitalModel?.propertyInfoDigital?.propertyType;
  const interiorScope = digitalModel?.propertyInfoDigital?.interiorScope;
  const exteriorScope = digitalModel?.propertyInfoDigital?.exteriorScope;
  const siteSize = digitalModel?.propertyInfoDigital?.landSize;

  useEffect(() => {
    if (
      spaceSize > 0 &&
      ((unit === "metric" && spaceSize <= 30000) ||
        (unit === "imperial" && spaceSize <= 300000)) &&
      propertyType.length > 0 &&
      (interiorScope.length > 0 || exteriorScope.length > 0)
    ) {
      const totalPrice = getTotalPriceDigital(
        spaceSize,
        unit,
        propertyType,
        interiorScope,
        exteriorScope,
        siteSize
      );
      const getWorkingDays = getWokringDaysRequired(
        spaceSize,
        propertyType,
        interiorScope,
        exteriorScope,
        siteSize,
        unit
      );
      const priceInDollar = totalPrice + 1;
      
      setTodalWorkingDays(getWorkingDays);
      setPrice(priceInDollar.toFixed(2));
      if (
        (digitalModel?.userId === null || digitalModel?.userId === "") &&
        getCurrrentUser() !== null
      ) {
        setDigitalModel((prevState) => ({
          ...prevState,
          userId: getCurrrentUser()["id"],
        }));
      }
    } else {
      setPrice(0);
      setTodalWorkingDays([]);
    }
  }, [spaceSize, propertyType, interiorScope, unit, exteriorScope, siteSize]);

  const userUseCoupon = async (couponName) => {
    var couponDto = await getCouponByName(couponName);

    const useCouponDto = {
      userId: getCurrrentUser()["id"],
      couponId: couponDto.id,
    };
    // Assign the coupon to the user
    var useCouponResponse = await assignCouponToUser(useCouponDto);
  };

  const checkIfCouponCanBeUsed = async () => {
    const payload = {
      userId: getCurrrentUser()["id"],
      couponName: coupon,
    };

    try {
      // Call API to check if the coupon can be used
      var response = await checkCouponUsability(payload);

      // If the response indicates that the coupon can be used
      if (response.success) {
        return true; // Successfully assigned the coupon
      }
      return false; // Coupon cannot be used
    } catch (error) {
      return false;
    }
  };

  const handleDigitalOrderPost = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      if (getCurrrentUser() === null) {
        navigate("/login");
        return;
      }

      const validteProject = validateDigitalProject(digitalModel);

      if (validteProject.length === 0) {
        let canUseCoupon = false;
        let newPrice = price;
        if (coupon !== "") {
          canUseCoupon = await checkIfCouponCanBeUsed();
        }

        if (canUseCoupon) {
          var couponDto = await getCouponByName(coupon);
          newPrice = price - price * (couponDto.couponPercentage / 100);
        }
        if (canUseCoupon === false && coupon !== "") {
          return;
        }
        
        const projectExtis = await getDigitalProjectByName(digitalModel.projectName)
        if(!projectExtis?.success) return;

        const updatedDigitalModel = {
          ...digitalModel,
          couponName: canUseCoupon ? coupon : "",
          userId: getCurrrentUser()["id"],
          workingDays: workingDays
        };
        setDigitalModel(updatedDigitalModel);
        localStorage.setItem(
          "digitalModel",
          JSON.stringify(updatedDigitalModel)
        );
        const resp = await paymentCheckout({
          totalPrice: newPrice,
          serviceType: "digital",
        });
        window.location.href = resp.sessionUrl;
      } else {
        const formattedMessage = validteProject.join("\n");
        // Display the toast notification with formatted message
        toast.error(formattedMessage, { duration: 7000 });
      //  toast.error(validteProject, { duration: 7000 });
      }
    } catch (error) {
     
    } finally {
      setLoading(false);
    }
  };

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const projectId = query.get("id");
  // const success = query.get('success');

  const digitalOrder = async () => {
    try {
      const model = localStorage.getItem("digitalModel");

      let parsedModel = null;
      if (model) {
        try {
          parsedModel = JSON.parse(model);
        } catch (error) {
          // Handle the error (e.g., remove the invalid item from localStorage)
          localStorage.removeItem("digitalModel");
        }
      }
      setDigitalModel(parsedModel);

      // Simulate delay before checking the coupon
      await new Promise((resolve) => setTimeout(resolve, 1000)); // 1 second delay

      if (parsedModel.couponName !== "") {
        await userUseCoupon(parsedModel.couponName);
      }

      // Simulate delay before posting the order
      await new Promise((resolve) => setTimeout(resolve, 2000)); // 2 second delay

      parsedModel.projectId = projectId;
      const response = await postDigitalOrder(parsedModel);

      // Simulate delay after posting the order
      await new Promise((resolve) => setTimeout(resolve, 1000)); // 1 second delay

      // Assuming the response has a status or success indicator
      if (response && response.success) {
        navigate("/request-successful");
      }
      return response;
    } catch (error) {

      throw error;
    } finally {
      localStorage.removeItem("digitalModel");
      setLoading(false);
      setDigitalModel(initialRealEstateDigital);
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
 
      setLoading(true);
      const responseOrder = digitalOrder();
      // Call the digitalOrder function and handle loading state via promise
      toast.promise(
        responseOrder, // Ensure this returns a promise
        {
          loading: t('order.processing'),
          success: t('order.success'),
          error: t('order.error'),
        }
      ).finally(() => {
        setLoading(false);
      });

    }

    if (query.get("canceled")) {
      localStorage.removeItem("digitalModel");
      //toast.error("Order canceled. Please try again.");
      setLoading(false);
    }
  }, []);

  return (
    <div className="price-container">
      <div className="card-container">
        <h3 className="card-title">{t('digitalPrice.totalAmount')}</h3>
        <p className="price price-2">{t('digitalPrice.currencySymbol')} {price}</p>

        {workingDays?.length > 0 ? (
          <>
            {workingDays[0] !== "" && (
              <p className="days-txt">
                {t('digitalPrice.interior')}: {workingDays[0]} {t('digitalPrice.workingDays')}
              </p>
            )}
            {workingDays[1] !== "" && (
              <p className="days-txt">
                {t('digitalPrice.exterior')}: {workingDays[1]} {t('digitalPrice.workingDays')}
              </p>
            )}
            {workingDays[2] !== "" && (
              <p className="days-txt">
                {t('digitalPrice.site')}: {workingDays[2]} {t('digitalPrice.workingDays')}
              </p>
            )}
          </>
        ) : (
          <p className="days-txt">{t('digitalPrice.noWorkingDays')}</p>
        )}

        <Button
          variant="contained"
          color="inherit"
          sx={{
            backgroundColor: "#163D6C",
            color: "white",
            padding: 1,
            textTransform: "none",
            width: 150,
            "&:hover": {
              backgroundColor: "#122c51",
            },
          }}
          onClick={handleDigitalOrderPost}
          disabled={loading}
          startIcon={loading ? <CircularProgress size={24} /> : null}
        >
          {loading ? t('digitalPrice.processing') : t('digitalPrice.convertNow')}
        </Button>
      </div>
      <div className="img-price-container">
        <img className="imgCalc" src={calcImage} alt="imgCalculate" />
      </div>
      <div className="card-container">
        <h3 className="card-title">{t('digitalPrice.couponQuestion')}</h3>
        <TextField
          label={t('digitalPrice.couponCode')}
          variant="outlined"
          size="small"
          onChange={(e) => setCoupon(e.target.value)}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
              fontSize: "12px",
              padding: "6px",
            },
            width: "220px",
          }}
          InputProps={{
            fontSize: "12px",
            padding: "14px",
            endAdornment: (
              <Tooltip
                title={t('digitalPrice.couponTooltip')}
                arrow
                PopperProps={{
                  disablePortal: true,
                  style: { marginTop: "8px" },
                }}
              >
                <IconButton>
                  <img
                    style={{ width: "15px" }}
                    src={InfoIcon}
                    alt="info-icon"
                  />
                </IconButton>
              </Tooltip>
            ),
          }}
          InputLabelProps={{
            sx: {
              fontSize: "11px",
              fontWeight: "600",
              fontFamily: "Roboto, sans-serif",
              padding: "8px",
              marginLeft: "-3px",
              shrink: true,
            },
          }}
          style={{ marginBottom: 8, fontSize: "12px" }}
        />
      </div>
    </div>
  );
};

export default PricingCardDigital;
