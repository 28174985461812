import React, { createContext, useContext, useState } from "react";

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [digitalProjects, setDigitalProjects] = useState([]);
  const [scanProjects, setScanProjects] = useState([]);
  const [projectModified, setProjectModified] = useState(false)
  const [activeService, setActiveService] = useState('scan')
  const [projectSatistics, setProjectSatistics] = useState("");
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(
    {
      dashboard: true,
      users: false,
      coupons: false,
      calendar: false,
      admins: false,
    }
  )

  const handleActiveTab = (tabName) => {
    setActiveTab((prevState) => ({
      dashboard: false,
      users: false,
      coupons: false,
      calendar: false,
      admins: false,
      [tabName]: true, 
    }));

  };
  return (
    <GlobalContext.Provider 
    value={{ 
      digitalProjects, setDigitalProjects,
      scanProjects, setScanProjects,
      projectModified, setProjectModified,
      activeService, setActiveService,
      projectSatistics, setProjectSatistics,
      activeTab, handleActiveTab,
      loading, setLoading
       }}>
      {children}
    </GlobalContext.Provider>
  );
};
