export const initialUserRegister = {
    name: "",
    surname: "",
    email: "",
    password: "",
    confirmPassword: ""
  }

  export const initialLoginUser = {
    email : '',
    password: ''
  }