import React from "react";
import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Logo from "../assets/Images/LogoWhite.png";
import { Icon } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import { Link } from "react-router-dom";
import { getCurrrentUser, fetchUserRole } from "../API/apiService";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import Logout from "@mui/icons-material/Logout";

import {
  Accordion,
  Drawer,
  List,
  ListItem,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Flag from "react-world-flags";
import ListItemIcon from "@mui/material/ListItemIcon";
import UserIcon from "../assets/Icons/people.png";
import panelIcon from "../assets/Icons/dashboard.png";
import { useTranslation } from "react-i18next";
import { getCurrentLanguage } from "../Helpers/helpers";

const MyComponent = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElServices, setAnchorElServices] = useState(null);
  const [anchorElResources, setAnchorElResourses] = useState(null);
  const [languageAnchorEl, setLanguageAnchorEl] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const pages = ["Services", "Orders", "About Us"];
  const settings = ["Admin Panel", "Account", "Logout"];
  const settings2 = ["Account", "Logout"];
  const services = ["Digitalization", "Scanning"];
  const resources = [
    "About Us",
    "Our Work",
    "Looking for job?",
    "Privacy",
    "Terms & Condition",
  ];
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(
    getCurrentLanguage() || ""
  );

  const changeLanguage = (language) => {
    setCurrentLanguage(language);
    localStorage.setItem("language", language);
    i18n.changeLanguage(language);
  };

  const navigate = useNavigate();

  const userRole = fetchUserRole();
  const handleMenuItemClick = (setting) => {
    // Perform actions based on the setting clicked
    if (setting === "Logout") {
      localStorage.removeItem("userVirtunex");
      navigate("login");
    } else if (setting === "Admin Panel") {
      if (userRole.includes("Admin") || userRole.includes("Superadmin")) {
        navigate("admin-panel/dashboard/scan");
      }
    } else if (setting === "Account") {
      navigate("user-profile");
    }
    // Close the menu after handling the click
    handleCloseUserMenu();
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleToggleDrawer = () => {
    setOpen(!open);
  };
  const handleLanguageClick = (event) => {
    setLanguageAnchorEl(event.currentTarget);
  };
  const handleLanguageClose = (language) => {
    setLanguageAnchorEl(null);
    changeLanguage(language);
  };
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenServicesMenu = (event) => {
    setAnchorElServices(event.currentTarget);
  };
  const handleCloseServicesMenu = () => {
    setAnchorElServices(null);
  };
  const handleOpenResourcesMenu = (event) => {
    setAnchorElResourses(event.currentTarget);
  };
  const handleCloseResourcesMenu = () => {
    setAnchorElResourses(null);
  };

  return (
    <>
      <AppBar
        position="fixed"
        color="inherit"
        sx={{ bgcolor: "#163D6C", padding: "10px", marginBottom: "500px" }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Icon sx={{ display: { xs: "none", md: "flex" }, mr: 0 }} />
            <Link to="/" className="link">
              <Box
                component="img"
                sx={{
                  flexGrow: 0,
                  display: { xs: "none", md: "block" },
                  height: 60, // Adjust height
                  width: 80, // Adjust width
                  maxHeight: { xs: 200, md: 300 },
                  maxWidth: { xs: 200, md: 300 },
                  borderRadius: "6px",
                  marginLeft: "-30px", // Add rounded corners
                  // Add a shadow
                }}
                alt="Descriptive Alt Text"
                src={Logo} // Replace with your image URL or path
              />
            </Link>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", alignItems: "center", md: "none" },
                color: "white",
              }}
            >
              <IconButton
                size="large"
                sx={{ mb: -1 }}
                aria-label="menu"
                aria-controls="menu-drawer"
                aria-haspopup="true"
                onClick={handleToggleDrawer}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="top"
                open={open}
                onClose={handleToggleDrawer}
                sx={{
                  "& .MuiDrawer-paper": {
                    width: "100%",
                    backgroundColor: "#163D6C",
                    color: "white",
                    paddingTop: "56px", // Adjust this value based on your navbar height
                    position: "relative", // Make sure the close button is positioned relative to this container
                  },
                }}
              >
                <IconButton
                  size="large"
                  aria-label="close menu"
                  onClick={handleToggleDrawer}
                  sx={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    color: "white",
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <List sx={{ width: "100%" }}>
                  <ListItem>
                    <Typography
                      textAlign="center"
                      sx={{ textDecoration: "none", color: "inherit" }}
                      component={Link}
                      to="/"
                      onClick={handleToggleDrawer}
                    >
                      {t("nav.home")}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography
                      textAlign="center"
                      sx={{
                        textDecoration: "none",
                        color: "inherit",
                      }}
                      component={Link}
                      to="/orders/scan"
                      onClick={handleToggleDrawer}
                    >
                      {t("nav.orders")}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography
                      textAlign="center"
                      sx={{
                        textDecoration: "none",
                        color: "inherit",
                      }}
                      component={Link}
                      to="/projects"
                      onClick={handleToggleDrawer}
                    >
                      {t("nav.samples")}
                    </Typography>
                  </ListItem>
                  <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleAccordionChange("panel1")}
                    sx={{ backgroundColor: "inherit", color: "inherit" }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{ color: "white", width: "100%" }}
                        />
                      }
                    >
                      <Typography>{t("nav.services")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List>
                        <ListItem onClick={handleToggleDrawer}>
                          <Typography
                            sx={{ textDecoration: "none", color: "inherit" }}
                            component={Link}
                            to="/services/scan"
                          >
                            {t("nav.scan")}
                          </Typography>
                        </ListItem>
                        <ListItem onClick={handleToggleDrawer}>
                          <Typography
                            sx={{ textDecoration: "none", color: "inherit" }}
                            component={Link}
                            to="/services/digital"
                          >
                            {t("nav.digital")}
                          </Typography>
                        </ListItem>
                      </List>
                    </AccordionDetails>
                  </Accordion>
                  {/* <Accordion
                    expanded={expanded === 'panel2'}
                    onChange={handleAccordionChange('panel2')}
                    sx={{ backgroundColor: 'inherit', color: 'inherit' }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
                      <Typography>Resources</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List>
                        <ListItem onClick={handleToggleDrawer}>
                          <Typography
                            sx={{ textDecoration: 'none', color: 'inherit' }} component={Link} to='/aboutus'
                          >
                            About Us
                          </Typography>
                        </ListItem>
                        <ListItem onClick={handleToggleDrawer}>
                          <Typography
                            sx={{ textDecoration: 'none', color: 'inherit' }} component={Link} to='/projects'
                          >Our Work</Typography>
                        </ListItem>
                        <ListItem onClick={handleToggleDrawer}>
                          <Typography
                            sx={{ textDecoration: 'none', color: 'inherit' }} component={Link} to='/candidate'
                          >
                            Looking for job?
                          </Typography>
                        </ListItem>
                        <ListItem onClick={handleToggleDrawer}>
                          <Typography
                            sx={{ textDecoration: 'none', color: 'inherit' }} component={Link} to='/privacy-policy'
                          >Privacy
                          </Typography>
                        </ListItem>
                        <ListItem onClick={handleToggleDrawer}>
                          <Typography
                            sx={{ textDecoration: 'none', color: 'inherit' }} component={Link} to='/terms-Condition'
                          >
                            Terms & Conditions
                          </Typography>
                        </ListItem>
                      </List>
                    </AccordionDetails>
                  </Accordion> */}
                  <Accordion
                    expanded={expanded === "panel3"}
                    onChange={handleAccordionChange("panel3")}
                    sx={{ backgroundColor: "inherit", color: "inherit" }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                    >
                      <Typography>{t("nav.language")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List>
                        <ListItem onClick={handleToggleDrawer}>
                          <Typography
                            sx={{
                              border:
                                currentLanguage === "en"
                                  ? "2px solid white"
                                  : undefined,
                              padding: 0.7,
                              borderRadius: 2,
                              width: 80,
                              textAlign: "center",
                            }}
                            onClick={() => changeLanguage("en")}
                          >
                            {t("nav.eng")}
                          </Typography>
                        </ListItem>
                        <ListItem onClick={handleToggleDrawer}>
                          <Typography
                            sx={{
                              border:
                                currentLanguage === "al"
                                  ? "2px solid white"
                                  : undefined,
                              padding: 0.7,
                              borderRadius: 2,
                              width: 80,
                              textAlign: "center",
                            }}
                            onClick={() => changeLanguage("al")}
                          >
                            {t("nav.al")}
                          </Typography>
                        </ListItem>
                      </List>
                    </AccordionDetails>
                  </Accordion>
                </List>
              </Drawer>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: {
                  xs: "none",
                  md: "flex",
                  ml: "20px",
                  justifyContent: "flex-start",
                  gap: 50,
                },
              }}
            >
              <Typography>
                <Button
                  variant="contained"
                  size="large"
                  aria-label="language"
                  aria-controls="language-menu"
                  aria-haspopup="true"
                  onClick={handleLanguageClick}
                  sx={{
                    l: 1,
                    ml: "20px",
                    background: "none",
                    border: "1px solid white",
                    padding: "18px",
                    "&:hover": {
                      background: "none",
                      borderColor: "#3399ff",
                    },
                    borderRadius: "8px",
                    height: "30px",
                    maxWidth: "250px",
                  }}
                  startIcon={<LanguageIcon sx={{ color: "white", mt: 2 }} />}
                >
                  <Typography
                    variant="body1"
                    sx={{ color: "white", mr: 1, fontSize: "12px" }}
                  >
                    {currentLanguage === "en" ? t("nav.eng") : t("nav.al")}
                  </Typography>
                  <ExpandMoreIcon sx={{ color: "white", mt: 2.3 }} />
                </Button>
                <Menu
                  id="language-menu"
                  anchorEl={languageAnchorEl}
                  open={Boolean(languageAnchorEl)}
                  onClose={() => setLanguageAnchorEl(null)}
                  sx={{
                    mt: "15px",
                    textAlign: "left",
                    ml: -9,
                    height: "300px",
                    width: "250px",
                    "& .MuiPaper-root": {
                      backgroundColor: "#D6E8FF", // Set the background color for the entire dropdown menu
                      position: "relative",
                      padding: "0px",
                      borderRadius: "20px",
                      borderTopRightRadius: "0px",
                      "&::before": {
                        content: '""',
                        position: "absolute",
                        top: "-12px", // Adjust as needed to position the arrow correctly
                        right: "12px", // Adjust as needed to position the arrow correctly
                        width: "0",
                        height: "0",
                        borderLeft: "12px solid transparent",
                        borderRight: "12px solid transparent",
                        borderBottom: "12px solid #D6E8FF",
                      },
                    },
                    "& .MuiMenuItem-root": {
                      fontSize: "13px",
                      "&:hover": {
                        backgroundColor: "#D6E8FF", // Hover background color
                      },
                      "& .MuiTypography-root": {},
                    },
                  }}
                >
                  <MenuItem
                    onClick={() => handleLanguageClose("en")}
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                  >
                    <Flag
                      code="GBR"
                      alt="England flag"
                      style={{
                        width: "25px",
                        borderRadius: "25px",
                        height: "25px",
                      }}
                    />

                    <Typography
                      sx={{ color: "#595959", fontSize: "14px", mr: 4.1 }}
                    >
                      {t("nav.eng")}
                    </Typography>
                    <div className="input-group">
                      <input
                        className="custom-radio"
                        type="radio"
                        id="option1"
                        name="googleMap"
                        value="English"
                        checked={currentLanguage === "en"}
                        onChange={() => changeLanguage("en")}
                      />
                    </div>
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleLanguageClose("al")}
                    sx={{ my: 1, display: "flex", alignItems: "center" }}
                  >
                    <Flag
                      code="ALB"
                      alt="Albanian flag"
                      style={{
                        width: "25px",
                        borderRadius: "25px",
                        height: "25px",
                      }}
                    />
                    <Typography
                      sx={{
                        color: "#595959",
                        fontSize: "14px",
                        ml: 1,
                        mr: currentLanguage === "al" ? 7 : 4,
                      }}
                    >
                      {t("nav.al")}
                    </Typography>

                    <div className="input-group">
                      <input
                        className="custom-radio"
                        type="radio"
                        id="option2"
                        name="googleMap"
                        value="Albanian"
                        checked={currentLanguage === "al"}
                        onChange={() => changeLanguage("al")}
                      />
                    </div>
                  </MenuItem>
                </Menu>
              </Typography>
              <Box
                sx={{
                  flexGrow: 1,
                  display: {
                    xs: "none",
                    md: "flex",
                    justifyContent: "flex-end",
                    gap: 0,
                  },
                  mr: "40px",
                }}
              >
                <Button
                  component={Link}
                  to="/orders/scan"
                  sx={{
                    background: "none",
                    backgroundColor: "white",
                    "&:hover": {
                      backgroundColor: "white",
                      borderColor: "#3399ff",
                    },
                    borderRadius: "10px",
                    border: "2px solid white",
                    width: "110px",
                    height: "30px",
                    maxWidth: "250px",
                    textTransform: "none",
                    padding: "18px", // Remove all padding
                    minWidth: "auto", // Adjust to prevent default minWidth
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                      color: "#163D6C",
                      padding: "0px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("nav.orders")}
                  </Typography>
                </Button>

                <Tooltip title="Open services">
                  <IconButton
                    onClick={handleOpenServicesMenu}
                    sx={{
                      p: 0,
                      l: 1,
                      ml: "20px",
                      background: "none",
                      border: "1px solid white",
                      padding: "18px",
                      "&:hover": {
                        background: "none",
                        borderColor: "#3399ff",
                      },
                      borderRadius: "10px",
                      width: "110px",
                      height: "30px",
                      maxWidth: "250px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14.5px",
                        color: "white",
                        fontWeight: "500",
                      }}
                    >
                      {t("nav.services")}
                    </Typography>
                    <ExpandMoreIcon sx={{ ml: 0.5, mt: 2.3, color: "white" }} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{
                    mt: "15px",
                    textAlign: "left",
                    ml: -2,
                    width: "280px",
                    "& .MuiPaper-root": {
                      backgroundColor: "#D6E8FF", // Set the background color for the entire dropdown menu
                      position: "relative",
                      padding: "12px",
                      borderRadius: "25px",
                      borderTopRightRadius: "0px",
                      "&::before": {
                        content: '""',
                        position: "absolute",
                        top: "-12px", // Adjust as needed to position the arrow correctly
                        right: "12px", // Adjust as needed to position the arrow correctly
                        width: "0",
                        height: "0",
                        borderLeft: "12px solid transparent",
                        borderRight: "12px solid transparent",
                        borderBottom: "12px solid #D6E8FF",
                      },
                    },
                    "& .MuiMenuItem-root": {
                      fontSize: "14px",
                      "&:hover": {
                        backgroundColor: "#D6E8FF", // Hover background color
                      },
                      "& .MuiTypography-root": {
                        width: "200px",
                        textAlign: "left",
                      },
                    },
                  }}
                  id="menu-appbar"
                  anchorEl={anchorElServices}
                  anchororigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  open={Boolean(anchorElServices)}
                  onClose={handleCloseServicesMenu}
                >
                  {services.map((service) => (
                    <MenuItem
                      component={Link}
                      to={
                        service === "Scanning"
                          ? "/services/scan"
                          : "/services/digital"
                      }
                      key={service}
                      onClick={handleCloseServicesMenu}
                      sx={{ my: 1 }}
                    >
                      <Typography
                        sx={{
                          color: "#595959",
                          fontSize: "14px",

                          "&:hover": {
                            color: "#333333", // Hover background color
                          },
                        }}
                      >
                        {service === "Scanning"
                          ? t("nav.scan")
                          : t("nav.digital")}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
                <Button
                  component={Link}
                  to="/projects"
                  sx={{
                    background: "none",
                    backgroundColor: "white",
                    "&:hover": {
                      backgroundColor: "white",
                      borderColor: "#3399ff",
                    },
                    borderRadius: "10px",
                    border: "2px solid white",
                    width: "110px",
                    height: "30px",
                    marginLeft: "20px",
                    maxWidth: "250px",
                    textTransform: "none",
                    padding: "18px", // Remove all padding
                    minWidth: "auto", // Adjust to prevent default minWidth
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                      color: "#163D6C",
                      padding: "0px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("nav.samples")}
                  </Typography>
                </Button>
              </Box>
            </Box>
            {getCurrrentUser() !== null && (
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    {/* <Avatar alt={getCurrrentUser()['firstName']} src="/static/images/avatar/2.jpg" /> */}
                    <Button
                      sx={{
                        background: "none",
                        backgroundColor: "white",
                        "&:hover": {
                          backgroundColor: "white",
                          borderColor: "#3399ff",
                        },
                        borderRadius: "10px",
                        border: "2px solid white",
                        width: { xs: "50px", md: "80px" },
                        height: "30px",
                        textTransform: "none",
                        padding: "18px", // Remove all padding
                        minWidth: "auto", // Adjust to prevent default minWidth
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "15px",
                          color: "#163D6C",
                          padding: "0px",
                          fontWeight: "bold",
                        }}
                      >
                        {getCurrrentUser()
                          ["firstName"]?.charAt(0)
                          ?.toUpperCase() +
                          getCurrrentUser()
                            ["lastName"]?.charAt(0)
                            ?.toUpperCase()}
                      </Typography>
                    </Button>
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{
                    mt: "54px",
                    textAlign: "center",
                    ml: -2,
                    width: "280px",
                    "& .MuiPaper-root": {
                      backgroundColor: "#D6E8FF", // Set the background color for the entire dropdown menu
                      position: "relative",
                      padding: "12px",
                      borderRadius: "25px",
                      borderTopRightRadius: "0px",
                      "&::before": {
                        content: '""',
                        position: "absolute",
                        top: "-12px", // Adjust as needed to position the arrow correctly
                        right: "12px", // Adjust as needed to position the arrow correctly
                        width: "0",
                        height: "0",
                        borderLeft: "12px solid transparent",
                        borderRight: "12px solid transparent",
                        borderBottom: "12px solid #D6E8FF",
                      },
                    },
                    "& .MuiMenuItem-root": {
                      fontSize: "14px",
                      "&:hover": {
                        backgroundColor: "#D6E8FF", // Hover background color
                      },
                      "& .MuiTypography-root": {
                        width: "200px",
                        textAlign: "left",
                      },
                    },
                  }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {userRole.includes("Admin") || userRole.includes("Superadmin")
                    ? settings.map((setting) => (
                        <MenuItem
                          key={setting}
                          onClick={() => handleMenuItemClick(setting)}
                        >
                          <ListItemIcon>
                          {setting === "Admin Panel" && (
                              <img
                              style={{ width: "18px", marginLeft: "-3px" }}
                              src={panelIcon}
                              alt="dashboard"
                            />
                            )}
                          {setting === "Account" && (
                              <img
                                style={{ width: "22px", marginLeft: "-3px" }}
                                src={UserIcon}
                                alt="user-icon"
                              />
                            )}
                            {setting === "Logout" && (
                              <Logout
                                fontSize="small"
                                color="inherit"
                                sx={{ mb: 0, color: "#333333" }}
                              />
                            )}
                          </ListItemIcon>
                       
                          <Typography textAlign="center">{t(`nav.${setting.toLowerCase()}`)}</Typography> 
                      
                        </MenuItem>
                      ))
                    : settings2.map((setting) => (
                        <MenuItem
                          key={setting}
                          onClick={() => handleMenuItemClick(setting)}
                        >
                          <ListItemIcon>
                            {setting === "Logout" && (
                              <Logout
                                fontSize="small"
                                color="inherit"
                                sx={{ mb: 0, color: "#333333" }}
                              />
                            )}
                            {setting === "Account" && (
                              <img
                                style={{ width: "22px", marginLeft: "-3px" }}
                                src={UserIcon}
                                alt="user-icon"
                              />
                            )}
                          </ListItemIcon>
                          {setting === "Account" && (
                            <Typography textAlign="center">
                              {t("nav.account")}
                            </Typography>
                          )}
                          {setting === "Logout" && (
                            <Typography textAlign="center">
                              {t("nav.logout")}
                            </Typography>
                          )}
                        </MenuItem>
                      ))}
                </Menu>
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};
export default MyComponent;
