import React, { useState, useRef, useEffect } from "react";
import DownloadIcon from "../../assets/Images/downloadIcon.png";
import { getDigitalProjectById } from "../../API/apiService";
import { useParams } from "react-router-dom";
import ForgeViewer from "../AutoDeskForge/ForgeViewer";
import ForgeViewer2d from "../AutoDeskForge/ForgeViewer2d";
import ConvertIcon from "../../assets/Icons/convert.png";
import ShareIcon from "../../assets/Icons/share.png";
import ShareDialog from "../Resource/ShareButton";
import categoryForgeActive from "../../Models/categoryForgeActive";
import ArrowDown from "../../assets/Icons/arrowDown.png";
import ArrowUp from "../../assets/Icons/up-arrow.png";
import { useNavigate } from "react-router-dom";
import backIcon from '../../assets/Icons/left-chevron.png'
import { useTranslation } from "react-i18next";


export default function OrderDetailsScan() {
  const containerRef = useRef(null);
  const [project, setProject] = useState();
  const forgeViewerRef = useRef(null);
  const { t } = useTranslation(); 

  const [data, setData] = useState(null);
  const [activeElement, setActiveElement] = useState("");
  const [isViewerInitialized, setIsViewerInitialized] = useState(false);
  const [propertiesData, setPropetiesData] = useState();
  const [isViewer3dActive, setViewer3dActive] = useState(true);
  const [isShareDialogOpen, setShareDialogOpen] = useState(false);
  const [categoryTabActive, setCategoryTabActive] =
    useState(categoryForgeActive);
  const [openCard, setOpenCard] = useState(true);
  //const {loading, setLoading} = useContext(GlobalContext);
  const [loading, setLoading] = useState(false)
  let { orderId } = useParams();
  const navigate = useNavigate();
  // Callback function to handle initialization
  // const handleInitialization = (initialized) => {
  //   setIsViewerInitialized(initialized);
  // };

  useEffect(() => {
     const fetchData = async () => {
    try {
      setLoading(true)
      const response = await getDigitalProjectById(orderId);
      setProject(response.data);

    } catch (error) {
    }finally{
      setLoading(false)
    }
  };

    fetchData();
    //   initializeAllProperties();
  }, []); // Use the state as a dependency

 

  useEffect(() => {
    setCategoryTabActive(categoryForgeActive);
  }, [data]);

  const areaElements = ["ceilings", "floors"];
  const volumeElements = ["walls", "topography"];
  const countExcludedElements = [
    "walls",
    "ceilings",
    "ducts",
    "conduits",
    "floors",
    "pipes",
    "railings",
    "topography",
  ];
  const lengthElements = ["pipes", "ducts", "conduits", "railings"];

  const handleSetForgeCategoryActive = async (e, category) => {
    e.preventDefault();

    setCategoryTabActive((prevState) => ({
      [category]: !prevState[category], // Toggle the specific category
    }));
    if (category === "architecture") {
      const action = categoryTabActive.architecture ? "remove" : "add";
      //in the same method remove or add architecture, pas it as param , remove or add
      await forgeViewerRef.current.getArchitectureCategory(action);

      //  await forgeViewerRef.current.getArchitectureCategory();
    } else if (category === "furniture") {
      const action = categoryTabActive.furniture ? "remove" : "add";
      await forgeViewerRef.current.getFurnitureCategory(action);
    } else if (category === "mepf") {
      const action = categoryTabActive.mepf ? "remove" : "add";
      await forgeViewerRef.current.getMEPFCategory(action);
    }

  };

  const handleGetWalls = async () => {
    if (!forgeViewerRef.current) {
      return;
    }
    setActiveElement("walls");
    const wallsData = await forgeViewerRef.current.getWalls();
    setData(wallsData);

    return wallsData;
  };

  const handleGetCeilings = async () => {
    const ceilingsData = await forgeViewerRef.current.getCeilings();
    setData(ceilingsData);
    setActiveElement("ceilings");
  };

  const handleGetDoors = async () => {
    const doorsData = await forgeViewerRef.current.getDoors();
    setData(doorsData);
    setActiveElement("doors");
  };

  const handleGetWindows = async () => {
    const windowsData = await forgeViewerRef.current.getWindows();
    setData(windowsData);
    setActiveElement("windows");
    return windowsData;
  };

  const handleGetStairs = async () => {
    const stairsData = await forgeViewerRef.current.getStairs();
    setData(stairsData);
    setActiveElement("stairs");
  };

  const handleGetColumns = async () => {
    const columnsData = await forgeViewerRef.current.getColumns();
    setData(columnsData);
    setActiveElement("columns");
  };

  const handleGetStructuralFraming = async () => {
    const structuralFramingData =
      await forgeViewerRef.current.getStructuralFraming();
    setData(structuralFramingData);
    setActiveElement("structuralFraming");
  };

  const handleGetStructuralColumns = async () => {
    const structuralColumnsData =
      await forgeViewerRef.current.getStructuralColumns();
    setData(structuralColumnsData);
    setActiveElement("structuralColumns");
  };

  const handleGetFloors = async () => {
    const floorsData = await forgeViewerRef.current.getFloors();
    setData(floorsData);
    setActiveElement("floors");
  };

  const handleGetRailings = async () => {
    const railingsData = await forgeViewerRef.current.getRailings();
    setData(railingsData);
    setActiveElement("railings");
  };

  const handleGetPlumbingFixtures = async () => {
    const plumbingFixturesData =
      await forgeViewerRef.current.getPlumbingFixtures();
    setData(plumbingFixturesData);
    setActiveElement("plumbingFixtures");
  };

  const handleGetCasework = async () => {
    const caseworkData = await forgeViewerRef.current.getCasework();
    setData(caseworkData);
    setActiveElement("casework");
  };

  const handleGetLightingFixtures = async () => {
    const lightingFixturesData =
      await forgeViewerRef.current.getLightingFixtures();
    setData(lightingFixturesData);
    setActiveElement("lightingFixtures");
  };

  const handleGetFurniture = async () => {
    const furnitureData = await forgeViewerRef.current.getFurniture();
    setData(furnitureData);
    setActiveElement("furniture");
  };

  const handleGetGenericModels = async () => {
    const genericModelsData = await forgeViewerRef.current.getGenericModels();
    setData(genericModelsData);
    setActiveElement("genericModels");
  };

  const handleGetSpecialityEquipment = async () => {
    const specialityEquipmentData =
      await forgeViewerRef.current.getSpecialityEquipment();
    setData(specialityEquipmentData);
    setActiveElement("specialityEquipment");
  };

  const handleGetMechanicalEquipment = async () => {
    const mechanicalEquipmentData =
      await forgeViewerRef.current.getMechanicalEquipment();
    setData(mechanicalEquipmentData);
    setActiveElement("mechanicalEquipment");
  };

  const handleGetPipes = async () => {
    const pipesData = await forgeViewerRef.current.getPipes();
    setData(pipesData);
    setActiveElement("pipes");
  };

  const handleGetDucts = async () => {
    const ductsData = await forgeViewerRef.current.getDucts();
    setData(ductsData);
    setActiveElement("ducts");
  };

  const handleGetConduits = async () => {
    const conduitsData = await forgeViewerRef.current.getConduits();
    setData(conduitsData);
    setActiveElement("conduits");
  };

  const handleGetPipeFittings = async () => {
    const pipeFittingsData = await forgeViewerRef.current.getPipeFittings();
    setData(pipeFittingsData);
    setActiveElement("pipeFittings");
  };

  const handleGetDuctFittings = async () => {
    const ductFittingsData = await forgeViewerRef.current.getDuctFittings();
    setData(ductFittingsData);
    setActiveElement("ductFittings");
  };

  const handleGetConduitFittings = async () => {
    const conduitFittingsData =
      await forgeViewerRef.current.getConduitFittings();
    setData(conduitFittingsData);
    setActiveElement("conduitFittings");
  };

  const handleGetSites = async () => {
    const siteData = await forgeViewerRef.current.getSite();
    setData(siteData);
    setActiveElement("site");
  };

  const handleGetPlantings = async () => {
    const plantingsData = await forgeViewerRef.current.getPlantings();
    setData(plantingsData);
    setActiveElement("plantings");
  };

  const handleGetTopography = async () => {
    const topographyData = await forgeViewerRef.current.getTopography();
    setData(topographyData);
    setActiveElement("topography");
  };

  const getSelectedProperties = async (typeName) => {
    const selectedGroup = data?.find((prop) => prop.typeName === typeName);
    if (selectedGroup) {
      const dbIds = [];
      selectedGroup.elements.forEach((element) => {
        dbIds.push(element.dbId);
      });
      if (dbIds) {
        await forgeViewerRef.current.isolateGroup(dbIds);
      }
    }
    return selectedGroup;
  };
  const isAreaRequired = () => {
    return ["walls", "floor", "ceilings"].includes(activeElement);
  };

  function getWallArea(wall) {
    //const wall = wallProperties.find(wall => wall.dbId === wallDbId);
    if (wall) {
      const areaProperty = wall.properties.find(
        (prop) => prop.displayName.toLowerCase() === "area"
      );
      if (areaProperty) {
        return parseFloat(areaProperty.displayValue).toFixed(2);
      }
    }
    return null; // Return null if the wall or area property is not found
  }

  function getTotalArea(elements) {
    let totalArea = 0;
    if (elements) {
      elements.forEach((element) => {
        totalArea += Number(element.area);
      });
      return totalArea.toFixed(2);
    }
  }
  function getTotalVolume(elements) {
    let totalVolume = 0;
    if (elements) {
      elements.forEach((element) => {
        totalVolume += Number(element.volume);
      });
      return totalVolume.toFixed(2);
    }
  }
  function getTotalLength(elements) {
    let totalLength = 0;
    if (elements) {
      elements.forEach((element) => {
        totalLength += Number(element.length) * element.elements.length;
      });
      return totalLength.toFixed(2);
    }
  }

  function getTotalElements(array) {
    let totalElements = 0;

    array?.forEach((item) => {
      if (item.elements && Array.isArray(item.elements)) {
        totalElements += item.elements.length;
      }
    });

    return totalElements;
  }

  const propertyTypeExist = (propType) => {
    const spaces = project?.propertyInfoDigital?.propertyType;
    return spaces ? spaces.some((prop) => prop.type === propType) : false;
  };

  const interiorScopeExist = (interiorScope) => {
    const intScope = project?.propertyInfoDigital?.interiorScope;
    return intScope
      ? intScope.some((interior) => interior.type === interiorScope)
      : false;
  };

  const exteriorScopeExist = (exteriorScope) => {

    const extScope = project?.propertyInfoDigital?.exteriorScope;
    return extScope
      ? extScope.some((exterior) => exterior.type === exteriorScope)
      : false;
  };


  const shareUrl = `${import.meta.env.VITE_APP_REACT_BASE_URL}/orders/digital/${orderId}`;
  const shareTitle = "Check out this property digitalization!";

  return (
    <div className="top-od-cont">
        <img
      onClick={() => navigate('/orders/scan')}
      style={{width:'30px', height:'30px', cursor:'pointer'}}
      aria-label="back"
      src={backIcon} alt="back-icon" />
      {loading ? 
        <div className="spinner-container">
        <div className="spinner"></div>
      </div>
      :
      <>
       {project && (
        <>
          <p className="gradient-text">
            <span>{t('orderDetailsDigital.orderDetailsTitle')}</span>
          </p>
          <div></div>
          <div className="order-details-container">
            {project?.status === "Completed" && (
              <>
                <div
                  className={`content3d-container${isViewer3dActive ? "-3d-Active" : "-3dInactive"}`}
                  style={{
                    width: isViewer3dActive ? "600px" : "800px",
                    height: "600px",
                  }}
                >
                  <div className={`${isViewer3dActive ? 'digital-icons' : 'digital-icons-2d'}`}>
                    <img
                      onClick={() => setViewer3dActive(!isViewer3dActive)}
                      className="convert-icon"
                      src={ConvertIcon}
                      alt="content-3d"
                    />
                    {isViewer3dActive && (
                      <>
                        <div className="digital-category-tab">
                          <p
                            className={`${
                              categoryTabActive.architecture === true
                                ? "active-button"
                                : "inactive-button"
                            }`}
                            onClick={(e) =>
                              handleSetForgeCategoryActive(e, "architecture")
                            }
                          >
                            {t('orderDetailsDigital.architecture')}
                          </p>
                          <p
                            className={`${
                              categoryTabActive.furniture === true
                                ? "active-button"
                                : "inactive-button"
                            }`}
                            onClick={(e) =>
                              handleSetForgeCategoryActive(e, "furniture")
                            }
                          >
                            {t('orderDetailsDigital.furniture')}
                          </p>
                          <p
                            className={`${
                              categoryTabActive.mepf === true
                                ? "active-button"
                                : "inactive-button"
                            }`}
                            onClick={(e) =>
                              handleSetForgeCategoryActive(e, "mepf")
                            }
                          >
                             {t('orderDetailsDigital.mepf')}
                          </p>
                        </div>
                        <div className="digital-svg">
                          <img
                            className={`${
                              categoryTabActive.architecture === true
                                ? "active-button"
                                : "inactive-button"
                            }`}
                            onClick={(e) =>
                              handleSetForgeCategoryActive(e, "architecture")
                            }
                            src="/arch-scope-icon.svg"
                            alt="Arch Icon"
                            width="24"
                            height="24"
                          />
                          <img
                            className={`${
                              categoryTabActive.furniture === true
                                ? "active-button"
                                : "inactive-button"
                            }`}
                            onClick={(e) =>
                              handleSetForgeCategoryActive(e, "furniture")
                            }
                            src="/furn-scope-icon.svg"
                            alt="Furn Icon"
                            width="24"
                            height="24"
                          />
                          <img
                            className={`${
                              categoryTabActive.mepf === true
                                ? "active-button"
                                : "inactive-button"
                            }`}
                            onClick={(e) =>
                              handleSetForgeCategoryActive(e, "mepf")
                            }
                            src="/mep-scope-icon.svg"
                            alt="Mep Icon"
                            width="24"
                            height="24"
                          />
                        </div>
                      </>
                    )}

                    <img
                      onClick={() => setShareDialogOpen(!isShareDialogOpen)}
                      className="share-icon"
                      src={ShareIcon}
                      alt="share-icon"
                    />
                  </div>
                    <div className={`${isViewer3dActive ? 'forge-container' : 'forge-container-2d'}`}>
                          {isViewer3dActive ? (
                    <ForgeViewer urn={project.urn} ref={forgeViewerRef} />
                  ) : (
                    <ForgeViewer2d urn={project.urn} />
                  )}
                    </div>
              
                </div>
                <ShareDialog
                  open={isShareDialogOpen}
                  onClose={() => setShareDialogOpen(false)}
                  url={shareUrl}
                  title={shareTitle}
                />
              </>
            )}
            {project.status !== "Completed" && (
              <>
                <div className="message-container">
                  <div className="message-header">
                  {project?.status !== "Canceled" && t('orderDetailsScan.thankYouMessage')}
                  </div>
                  <div className="message-body">
                  {project?.status === 'Canceled'? 
                <>
                  {t('orderDetailsDigital.canceledOrderMessage')}
                </>  
                :
                <>
                  {t('orderDetailsDigital.processingOrderMessage')}
                </>
              }
                  </div>
                  {/* <VideoPlayer src={WorInProgressGif} /> */}
                  <div className="message-footer">
                  {project?.status !== "Canceled" && ( t('orderDetailsDigital.appreciationMessage'))}   
                  </div>
                </div>
              </>
            )}
            {isViewer3dActive &&(
              <div className="order-details-content">
                <div className="arrow-down-cont">
                  {project.status === "Completed" && (
                     <img
                    onClick={() => {
                      setOpenCard(!openCard);
                    }}
                    className="arrow-digital"
                    src={openCard ? ArrowDown : ArrowUp}
                    alt="arrow-down"
                  />
                  )}
                 
                </div>
                {openCard && (
                  <>
                    <p className="proj-name-st">{project.projectName}</p>
                    <p className="proj-address-st">{project.propertyInfoDigital?.address}</p>
                    <p className="proj-title-st"> {t('orderDetailsDigital.digitalizationDetailsTitle')}</p>
                    <div className="od-types-cont">
                      <div className="od-space-cont">
                        <div>
                          <p> {t('orderDetailsDigital.spaceType')}</p>
                        </div>

                        <div className="space-type-cont">
                          <p
                            className={`space-type${
                              propertyTypeExist("Standard")
                                ? "-exist"
                                : "-notExist"
                            }`}
                          >
                            {t('orderDetailsDigital.spaceTypeStandard')}
                          </p>
                          <p
                            className={`space-type${
                              propertyTypeExist("Industrial")
                                ? "-exist"
                                : "-notExist"
                            }`}
                          >
                            {t('orderDetailsDigital.spaceTypeIndustrial')}
                          </p>
                        </div>
                        <div>
                          <p> {t('orderDetailsDigital.spaceSiteSize')}</p>
                          <p>
                            {project.propertyInfoDigital.propertySize}/
                            {project.propertyInfoDigital.landSize}
                            <span>
                              {project.propertyInfoDigital.unit === "metric"
                                ? " m²"
                                : " SF"}
                            </span>
                          </p>
                        </div>
                        <div className="unit-cont2">
                          <p> {t('orderDetailsDigital.unit')}</p>
                          <div>
                            <p
                              className={`space-type${
                                project.propertyInfoDigital?.unit === "imperial"
                                  ? "-exist"
                                  : "-notExist"
                              }`}
                            >
                              {t('orderDetailsDigital.imperial')}
                            </p>
                            <p
                              className={`space-type${
                                project.propertyInfoDigital?.unit === "metric"
                                  ? "-exist"
                                  : "-notExist"
                              }`}
                            >
                               {t('orderDetailsDigital.metric')}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="od-model-cont">
                        <div className="scope-title">
                          <p> {t('orderDetailsDigital.interiorScopeTitle')}</p>
                        </div>

                        <div className="model-type model-type-1 cm-type">
                          {propertyTypeExist("Standard") ? (
                            <>
                              <p
                                className={`space-type${
                                  interiorScopeExist("Architecture")
                                    ? "-exist"
                                    : "-notExist"
                                }`}
                              >
                                {t('orderDetailsDigital.interiorScopeArchitecture')}
                              </p>
                              <p
                                className={`space-type${
                                  interiorScopeExist("Furniture")
                                    ? "-exist"
                                    : "-notExist"
                                }`}
                              >
                              {t('orderDetailsDigital.interiorScopeFurniture')}
                              </p>
                              <p
                                className={`space-type${
                                  interiorScopeExist("Installations")
                                    ? "-exist"
                                    : "-notExist"
                                }`}
                              >
                               {t('orderDetailsDigital.interiorScopeInstallations')}
                              </p>
                            </>
                          ) : (
                            <p
                              className={`space-type${
                                interiorScopeExist("COMPLEX Installations")
                                  ? "-exist"
                                  : "-notExist"
                              }`}
                            >
                              {t('orderDetailsDigital.complexInstallations')}
                            </p>
                          )}
                        </div>

                        {/* {project.propertyInfoDigital?.exteriorScope.length >
                          0 && (
                          <> */}
                            <div className="scope-title">
                              <p>{t('orderDetailsDigital.exteriorScopeTitle')}</p>
                            </div>
                            <div className="model-type">
                              <p
                                className={`space-type${
                                  exteriorScopeExist("Architecture")
                                    ? "-exist"
                                    : "-notExist"
                                }`}
                              >
                                {t('orderDetailsDigital.interiorScopeArchitecture')}
                              </p>
                              <p
                                className={`space-type${
                                  exteriorScopeExist("Furniture")
                                    ? "-exist"
                                    : "-notExist"
                                }`}
                              >
                                 {t('orderDetailsDigital.interiorScopeFurniture')}
                              </p>
                              <p
                                className={`space-type${
                                  exteriorScopeExist("Installations")
                                    ? "-exist"
                                    : "-notExist"
                                }`}
                              >
                                 {t('orderDetailsDigital.interiorScopeInstallations')}
                              </p>
                            </div>
                          {/* </>
                        )} */}
                        <a
                          className="link"
                          href={project.workLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="btn-download-work-cont">
                            <img
                              style={{
                                width: "14px",
                                maxWidth: "14px",
                                marginRight: "4px",
                              }}
                              src={DownloadIcon}
                              alt="download-icon"
                            />
                             {t('orderDetailsDigital.downloadLinkText')}
                          </div>
                        </a>
                      </div>
                    </div>
                  </>
                )}
                {project.status === "Completed" && (

                
                <>
                <div className="package-container" ref={containerRef}>
                  <p
                    className={`element-button ${
                      handleGetWalls.length === 0 ? "no-data" : ""
                    }
                   ${
                     activeElement === "walls"
                       ? "active-button"
                       : "inactive-button"
                   }`}
                    onClick={handleGetWalls}
                  >
                     {t('elements.walls')}
                  </p>
                  <p
                    className={`element-button ${
                      activeElement === "ceilings"
                        ? "active-button"
                        : "inactive-button"
                    }`}
                    onClick={handleGetCeilings}
                  >
                     {t('elements.ceilings')}
                  </p>
                  <p
                    className={`element-button ${
                      activeElement === "doors"
                        ? "active-button"
                        : "inactive-button"
                    }`}
                    onClick={handleGetDoors}
                  >
                     {t('elements.doors')}
                  </p>
                  <p
                    className={`element-button ${
                      handleGetWindows.length === 0 ? "no-data" : ""
                    }
                    ${
                      activeElement === "windows"
                        ? "active-button"
                        : "inactive-button"
                    }`}
                    onClick={handleGetWindows}
                  >
                   {t('elements.windows')}
                  </p>
                  <p
                    className={`element-button ${
                      activeElement === "stairs"
                        ? "active-button"
                        : "inactive-button"
                    }`}
                    onClick={handleGetStairs}
                  >
                     {t('elements.stairs')}
                  </p>
                  <p
                    className={`custom-paragraph element-button ${
                      activeElement === "columns"
                        ? "active-button"
                        : "inactive-button"
                    }`}
                    onClick={handleGetColumns}
                  >
                      {t('elements.columns')}
                  </p>
                  <p
                    className={`element-button ${
                      activeElement === "structuralFraming"
                        ? "active-button"
                        : "inactive-button"
                    }`}
                    onClick={handleGetStructuralFraming}
                  >
                      {t('elements.structuralFraming')}
                  </p>
                  <p
                    className={`element-button ${
                      activeElement === "structuralColumns"
                        ? "active-button"
                        : "inactive-button"
                    }`}
                    onClick={handleGetStructuralColumns}
                  >
                    {t('elements.structuralColumns')}
                  </p>
                  <p
                    className={`element-button ${
                      activeElement === "floors"
                        ? "active-button"
                        : "inactive-button"
                    }`}
                    onClick={handleGetFloors}
                  >
                      {t('elements.floors')}
                  </p>
                  <p
                    className={`element-button ${
                      activeElement === "railings"
                        ? "active-button"
                        : "inactive-button"
                    }`}
                    onClick={handleGetRailings}
                  >
                      {t('elements.railings')}
                  </p>
                  <p
                    className={`element-button ${
                      activeElement === "plumbingFixtures"
                        ? "active-button"
                        : "inactive-button"
                    }`}
                    onClick={handleGetPlumbingFixtures}
                  >
                     {t('elements.plumbingFixtures')}
                  </p>
                  <p
                    className={`element-button ${
                      activeElement === "casework"
                        ? "active-button"
                        : "inactive-button"
                    }`}
                    onClick={handleGetCasework}
                  >
                      {t('elements.casework')}
                  </p>
                  <p
                    className={`element-button ${
                      activeElement === "lightingFixtures"
                        ? "active-button"
                        : "inactive-button"
                    }`}
                    onClick={handleGetLightingFixtures}
                  >
                      {t('elements.lightingFixtures')}
                  </p>
                  <p
                    className={`element-button ${
                      activeElement === "furniture"
                        ? "active-button"
                        : "inactive-button"
                    }`}
                    onClick={handleGetFurniture}
                  >
                      {t('elements.furniture')}
                  </p>
                  <p
                    className={`element-button ${
                      activeElement === "genericModels"
                        ? "active-button"
                        : "inactive-button"
                    }`}
                    onClick={handleGetGenericModels}
                  >
                  {t('elements.genericModels')}
                  </p>
                  <p
                    className={`element-button ${
                      activeElement === "specialityEquipment"
                        ? "active-button"
                        : "inactive-button"
                    }`}
                    onClick={handleGetSpecialityEquipment}
                  >
                   {t('elements.specialityEquipment')}
                  </p>
                  <p
                    className={`element-button ${
                      activeElement === "mechanicalEquipment"
                        ? "active-button"
                        : "inactive-button"
                    }`}
                    onClick={handleGetMechanicalEquipment}
                  >
                   {t('elements.mechanicalEquipment')}
                  </p>
                  <p
                    className={`element-button ${
                      activeElement === "pipes"
                        ? "active-button"
                        : "inactive-button"
                    }`}
                    onClick={handleGetPipes}
                  >
                    {t('elements.pipes')}
                  </p>
                  <p
                    className={`element-button ${
                      activeElement === "ducts"
                        ? "active-button"
                        : "inactive-button"
                    }`}
                    onClick={handleGetDucts}
                  >
                    {t('elements.ducts')}
                  </p>
                  <p
                    className={`element-button ${
                      activeElement === "conduits"
                        ? "active-button"
                        : "inactive-button"
                    }`}
                    onClick={handleGetConduits}
                  >
                    {t('elements.conduits')}
                  </p>
                  <p
                    className={`element-button ${
                      activeElement === "pipeFittings"
                        ? "active-button"
                        : "inactive-button"
                    }`}
                    onClick={handleGetPipeFittings}
                  >
                  {t('elements.pipeFittings')}
                  </p>
                  <p
                    className={`element-button ${
                      activeElement === "ductFittings"
                        ? "active-button"
                        : "inactive-button"
                    }`}
                    onClick={handleGetDuctFittings}
                  >
                   {t('elements.ductFittings')}
                  </p>
                  <p
                    className={`element-button ${
                      activeElement === "conduitFittings"
                        ? "active-button"
                        : "inactive-button"
                    }`}
                    onClick={handleGetConduitFittings}
                  >
                    {t('elements.conduitFittings')}
                  </p>
                  <p
                    className={`element-button ${
                      activeElement === "site"
                        ? "active-button"
                        : "inactive-button"
                    }`}
                    onClick={handleGetSites}
                  >
                    {t('elements.site')}
                  </p>
                  <p
                    className={`element-button ${
                      activeElement === "plantings"
                        ? "active-button"
                        : "inactive-button"
                    }`}
                    onClick={handleGetPlantings}
                  >
                     {t('elements.plantings')}
                  </p>
                  <p
                    className={`element-button ${
                      activeElement === "topography"
                        ? "active-button"
                        : "inactive-button"
                    }`}
                    onClick={handleGetTopography}
                  >
                     {t('elements.topography')}
                  </p>
                </div>
                <div className="order-details-digital-tab">
                  <div className="digital-details-title">
                    <p className="pd-title-av"> {t('elements.type')}</p>

                    {areaElements.includes(activeElement) && (
                      <p className="pd-title-av"> {t('elements.area')}</p>
                    )}
                    {volumeElements.includes(activeElement) && (
                      <p className="pd-title-av">{t('elements.volume')}</p>
                    )}
                    {!countExcludedElements.includes(activeElement) && (
                      <p className="pd-title-av">{t('elements.count')}</p>
                    )}

                    {lengthElements.includes(activeElement) && (
                      <p className="pd-title-av">{t('elements.length')}</p>
                    )}
                  </div>
                  {(activeElement === "ceilings" ||
                    activeElement === "floors") && (
                    <>
                      <div className="property-total">
                        <p>{t('elements.total')}</p>
                        <p>
                          {getTotalArea(data)}
                          {project.propertyInfoDigital.unit === "metric"
                            ? "m²"
                            : "SF"}
                        </p>
                      </div>
                      {data?.map((property) => (
                        <div
                          key={property.typeName}
                          className="property-type-info"
                          onClick={() =>
                            getSelectedProperties(property.typeName)
                          }
                        >
                          <p className="property-digital">
                            {property.typeName}
                          </p>
                          <p className="property-digital property-data">
                            {property.area}
                            {project.propertyInfoDigital.unit === "metric"
                              ? " m²"
                              : " SF"}
                          </p>
                        </div>
                      ))}
                    </>
                  )}

                  {(activeElement === "walls" ||
                    activeElement === "topography") && (
                    <>
                      <div className="property-total">
                        <p>{t('elements.total')}</p>
                        <p>
                          {getTotalVolume(data)}
                          {project.propertyInfoDigital.unit === "metric"
                            ? "m³"
                            : "CF"}
                        </p>
                      </div>
                      {data?.map((property) => (
                        <div
                          key={property.typeName}
                          className="property-type-info"
                          onClick={() =>
                            getSelectedProperties(property.typeName)
                          }
                        >
                          <p className="property-digital">
                            {property.typeName}
                          </p>
                          <p className="property-digital property-data">
                            {property.volume}
                            {project.propertyInfoDigital.unit === "metric"
                              ? " m³"
                              : " CF"}
                          </p>
                        </div>
                      ))}
                    </>
                  )}

                  {(activeElement === "pipes" ||
                    activeElement === "ducts" ||
                    activeElement === "conduits" ||
                    activeElement === "railings") && (
                      <>
                        <div className="property-total">
                          <p>{t('elements.total')}</p>
                          <p>
                            {getTotalLength(data)}
                            {project.propertyInfoDigital.unit === "metric"
                              ? "m"
                              : "ft"}
                          </p>
                        </div>
                        {data?.map((pipe) => (
                          <div
                            key={pipe.typeName}
                            className="property-type-info"
                            onClick={() => getSelectedProperties(pipe.typeName)}
                          >
                            <p className="property-digital">{pipe.typeName}</p>
                            <p className="property-digital property-data">
                              {pipe.length}{" "}
                              {project.propertyInfoDigital.unit === "metric"
                                ? "m"
                                : "ft"}
                            </p>
                          </div>
                        ))}
                      </>
                    )}

                  {activeElement !== "walls" &&
                    activeElement !== "ceilings" &&
                    activeElement !== "ducts" &&
                    activeElement !== "conduits" &&
                    activeElement !== "floors" &&
                    activeElement !== "pipes" &&
                    activeElement !== "railings" &&
                    activeElement !== "topography" && (
                      <>
                        <div className="property-total">
                          <p>{t('elements.total')}</p>
                          <p>{getTotalElements(data)}</p>
                        </div>
                        {data?.map((property) => (
                          <div
                            key={property.typeName}
                            className="property-type-info"
                            onClick={() =>
                              getSelectedProperties(property.typeName)
                            }
                          >
                            <p className="property-digital">
                              {property.typeName}{" "}
                            </p>
                            <p className="property-digital property-data">
                              {property.elements?.length}
                            </p>
                          </div>
                        ))}
                      </>
                    )}
                </div>
                </>
              )}

              </div>
            )}
          </div>
        </>
      )}
      </>  
    }
        
    </div>
  );
}
