import React from "react";
import { useTranslation } from 'react-i18next';
import FAQ from './FAQ';
import DownloadIcon from '../../assets/Icons/downloadIcon.png';
import ScanImg from '../../assets/Images/scanImg.png';
import DigitalImg from '../../assets/Images/digitalImg.png';
import ForgeViewer from "../AutoDeskForge/ForgeViewer";


export default function Projects() {
  const { t } = useTranslation();

  return (
    <div className="projects-container">
      <p className='gradient-text'><span>{t('projects.title')}</span></p>
      <p className='gradient-text'><span>{t('projects.exampleTitle')}</span></p>

      <div className="digital-projects">
        <div>
          <div className="download-projects">
            <h3>{t('projects.digitalizationExample')}</h3>

            <div>
              <p>.RVT file</p>
              <a 
                className="dl-icon-project" 
                target="_blank" rel="noopener noreferrer"
                href={t('projects.links.rvt')}
              >
                <img src={DownloadIcon} alt="download-icon" />
              </a>       
              <div className="tooltip-project">{t('projects.fileDownloads.rvt')}</div>
            </div>
            <div>
              <p>.IFC File</p>
              <a 
                className="dl-icon-project" 
                target="_blank" rel="noopener noreferrer"
                href={t('projects.links.ifc')}
              >
                <img src={DownloadIcon} alt="download-icon" />
              </a>       
              <div className="tooltip-project">{t('projects.fileDownloads.ifc')}</div>
            </div>
            <div>
              <p>.DWG File</p>
              <a 
                className="dl-icon-project" 
                target="_blank" rel="noopener noreferrer"
                href={t('projects.links.dwg')}
              >
                <img src={DownloadIcon} alt="download-icon" />
              </a>       
              <div className="tooltip-project">{t('projects.fileDownloads.dwg')}</div>
            </div>
            <div>
              <p>.RCS File</p>
              <a 
                className="dl-icon-project" 
                target="_blank" rel="noopener noreferrer"
                href={t('projects.links.rcs')}
              >
                <img src={DownloadIcon} alt="download-icon" />
              </a>       
              <div className="tooltip-project">{t('projects.fileDownloads.rcs')}</div>
            </div>
            <div>
              <p>.PNG File</p>
              <a 
                className="dl-icon-project" 
                target="_blank" rel="noopener noreferrer"
                href={t('projects.links.jpeg')}
              >
                <img src={DownloadIcon} alt="download-icon" />
              </a>       
              <div className="tooltip-project">{t('projects.fileDownloads.jpeg')}</div>
            </div>
          </div>
          <div className="project-img">
            <ForgeViewer                     
              urn={import.meta.env.VITE_APP_PROJECT_URN}
            />
          </div>
        </div>
      </div>
      <br />
      <div className="digital-projects">        
        <div>
          <div className="download-projects">
            <h3>{t('projects.scanningExample')}</h3>
            <div>
              <p>.e57 File</p>
              <a 
                className="dl-icon-project" 
                target="_blank" rel="noopener noreferrer"
                href={t('projects.links.xyz')}
              >
                <img src={DownloadIcon} alt="download-icon" />
              </a>       
              <div className="tooltip-project">{t('projects.fileDownloads.xyz')}</div>
            </div>
            <div>
              <p>.PNG File</p>
              <a 
                className="dl-icon-project" 
                target="_blank" rel="noopener noreferrer"
                href={t('projects.links.img')}
              >
                <img src={DownloadIcon} alt="download-icon" />
              </a>       
              <div className="tooltip-project">{t('projects.fileDownloads.img')}</div>
            </div>
          </div>
          <div className="project-img">
          <iframe 
          src="https://realsee.ai/rnDDGeG7"
           width="100%"  
           allow="fullscreen; autoplay=0"
           style={{border:'none'}}>
            </iframe>  
     
            {/* <a 
              target="_blank" rel="noopener noreferrer"
              href={t('projects.links.scan')}
            >
              <img src={ScanImg} alt="project-video" />
            </a> */}
          </div>
        </div>
      </div>
      <FAQ/>
    </div>
  );
}
