import React, { useState } from "react";
import {
  InputLabel,
  MenuItem,
  TextField,
  FormControl,
  Select,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import DigitalBannerImg from "../../assets/Images/digitalBanner.png";
import Button from "@mui/joy/Button";
import FileDownloadOutlinedIcon from "../../assets/Icons/downloadIcon.png";
import ValidatedTextField from "./ValidateTextField";
import excelIcon from '../../assets/Icons/excel.png'
import {
  emailValidator,
  noValidator,
  requiredValidator,
  validatorWithMaxValue,
} from "../../Validators/validator";
import initialRealEstateDigital from "../../Models/DigitalServiceModel";
import PricingCardDigital from "../PriceCardDigital";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

export default function RealEstateDigital() {
  const { t } = useTranslation();
  const [unit, setUnit] = useState("imperial");
  const [architectures, setArchitectures] = useState({
    archInterior: true,
    archExterior: true,
  });
  const [digitalModel, setDigitalModel] = useState(initialRealEstateDigital);

  const [propertyType, setPropertyType] = useState({
    standart: true,
    industrial: false,
  });

  const [interiorScope, setInteriorScope] = useState({
    architecture: true,
    furniture: false,
    installations: false,
    complex: false,
  });

  const [exteriorScope, setExteriorScope] = useState({
    architecture: true,
    furniture: false,
    installations: false,
  });

  const handleOptionChange = (event, fieldName) => {
    const value = event.target.value;
    setDigitalModel((prevState) => ({
      ...prevState,
      propertyInfoDigital: {
        ...prevState.propertyInfoDigital,
        [fieldName]: value === "Yes",
      },
    }));

    if (fieldName === "landDigital" && value === "No") {
      setDigitalModel((prevState) => ({
        ...prevState,
        propertyInfoDigital: {
          ...prevState.propertyInfoDigital,
          landSize: 0,
        },
      }));
    } else if (fieldName === "exteriorDigital" && value === "No") {
      setExteriorScope({
        architecture: false,
        furniture: false,
        installations: false,
      });

      setDigitalModel((prevState) => ({
        ...prevState,
        propertyInfoDigital: {
          ...prevState.propertyInfoDigital,
          exteriorScope: [],
        },
      }));
    } else if (fieldName === "exteriorDigital" && value === "Yes") {
      setExteriorScope({
        architecture: true,
        furniture: false,
        installations: false,
      });

      setDigitalModel((prevState) => ({
        ...prevState,
        propertyInfoDigital: {
          ...prevState.propertyInfoDigital,
          exteriorScope: [
            {
              id: "887ffbc3-00e7-449e-ab3f-02803c0d0054a",
              type: "architecture",
            },
          ],
        },
      }));
    }
  };

  const handleNestedInputChange = (event, parent, child) => {
    event.preventDefault();
    const { name, value } = event.target;
    if (
      child === "projectUrl" ||
      child === "email" ||
      child === "projectName"
    ) {
      setDigitalModel((prevState) => ({
        ...prevState,
        [child]: value,
      }));
    } else {
      let parsedVale = value;
      if (child === "propertySize" || child === "landSize") {
        parsedVale = parseFloat(value);
      }
      setDigitalModel((prevState) => ({
        ...prevState,
        [parent]: {
          ...prevState[parent],
          [child]: parsedVale,
        },
      }));
    }
  };

  const isScopeSelected = (type, state) => {
    return state[type] === true;
  };

  const createScopeType = (value) => {
    let newType = { id: "", type: "" };
    if (value === "architecture") {
      newType = { id: "887ffbc3-00e7-449e-ab3f-02803c0d0054a", type: value };
    } else if (value === "installations") {
      newType = { id: "01d5c1da-3272-442e-89f9-42c4a6fc714e", type: value };
    } else if (value === "furniture") {
      newType = { id: "1d82066f-8e56-4d4a-8d38-ab77e8bbb505", type: value };
    } else if (value === "complex") {
      newType = { id: "66148a00-e2ae-4622-870a-349f9fe29ad1", type: value };
    }
    return newType;
  };

  const toggleButtonState = (value, type) => () => {
    const isPropertySelected = isScopeSelected(type, propertyType);
    //let newType = createScopeType(value, type);
    let newType = { id: "", type: value };
    if (type === "property") {
      setPropertyType({
        standart: false,
        industrial: false,
        [newType.type]: true,
      });

      if (type === "property" && value === "industrial") {
        //Update interiorScope
        setArchitectures((prevState) => ({
          ...prevState,
          archInterior: true,
        }));
        // Update digitalModel
        setDigitalModel((prevState) => ({
          ...prevState,
          propertyInfoDigital: {
            ...prevState.propertyInfoDigital,
            landSize: 0,
            landDigital:false,
            interiorScope: [
              { id: "66148a00-e2ae-4622-870a-349f9fe29ad1", type: "complex" },
            ],
            exteriorScope: [],
            propertyType: [
              {
                id: "60d6fc78-d620-4e7b-960f-a358f1dc6fe5",
                type: "industrial",
              },
            ],
          },
        }));

        setInteriorScope({
          architecture: false,
          furniture: false,
          installations: false,
          complex: true,
        });
      }

      if (!isPropertySelected && type === "property" && value === "standart") {
        setInteriorScope({
          architecture: true,
          furniture: false,
          installations: false,
          complex: false,
        });
        setDigitalModel((prevState) => ({
          ...prevState,
          propertyInfoDigital: {
            ...prevState.propertyInfoDigital,
            interiorScope: [
              {
                id: "887ffbc3-00e7-449e-ab3f-02803c0d0054a",
                type: "architecture",
              },
            ],
            propertyType: [
              { id: "241e5c0d-a1e0-4d55-9213-b53ef4316551", type: "standart" },
            ],
          },
        }));
      }

      // const updatedRealEstateDigital = { ...digitalModel };
      // updatedRealEstateDigital.propertyInfoDigital.propertyType = [newType];
      // setDigitalModel(updatedRealEstateDigital);
    }
    const scopeType = createScopeType(value);
    if (type === "scopeInterior") {
      const isSelected = isScopeSelected(value, interiorScope);
      if (!isSelected && value === "architecture") {
        setArchitectures((prevState) => ({
          ...prevState,
          archInterior: true,
        }));
      } else if (
        isSelected &&
        value === "architecture" &&
        architectures.archExterior
      ) {
        setArchitectures((prevState) => ({
          ...prevState,
          archInterior: false,
        }));
        setInteriorScope({
          architecture: false,
          furniture: false,
          installations: false,
        });

        setDigitalModel((prevState) => ({
          ...prevState,
          propertyInfoDigital: {
            ...prevState.propertyInfoDigital,
            interiorScope: [],
          },
        }));
        return;
      } else if (
        isSelected &&
        value === "architecture" &&
        !architectures.archExterior
      ) {
        return;
      } else if (isSelected && value === "complex") {
        return;
      } else if (!isSelected && value !== "architecture" && "complex") {
        const isArchitectureSelected = isScopeSelected(
          "architecture",
          interiorScope
        );
        if (!isArchitectureSelected) {
          setArchitectures((prevState) => ({
            ...prevState,
            archInterior: true,
          }));

          setInteriorScope((prevState) => ({
            ...prevState,
            architecture: true,
          }));

          setDigitalModel((prevState) => ({
            ...prevState,
            propertyInfoDigital: {
              ...prevState.propertyInfoDigital,
              interiorScope: [
                ...prevState.propertyInfoDigital.interiorScope,
                {
                  id: "887ffbc3-00e7-449e-ab3f-02803c0d0054a",
                  type: "architecture",
                },
              ],
            },
          }));
        }
      }

      setDigitalModel((prevState) => {
        // Check if PropertyInfoScan exists
        const propertyInfoDigital = prevState.propertyInfoDigital || {};

        // Ensure ScanningType is an array
        const intScope = Array.isArray(propertyInfoDigital.interiorScope)
          ? propertyInfoDigital.interiorScope
          : [];

        // Check if newType is already in the array and remove it if selected
        const updatedScopeInterior = isSelected
          ? intScope.filter((interior) => interior.type !== value)
          : [...intScope, scopeType];

        // Return updated state
        return {
          ...prevState,
          propertyInfoDigital: {
            ...propertyInfoDigital,
            interiorScope: updatedScopeInterior,
          },
        };
      });
      setInteriorScope((prevState) => ({
        ...prevState,
        [value]: !prevState[value],
      }));
    }

    if (type === "scopeExterior") {
      const isSelected = isScopeSelected(value, exteriorScope);

      if (!isSelected && value === "architecture") {
        setArchitectures((prevState) => ({
          ...prevState,
          archExterior: true,
        }));
      } else if (
        isSelected &&
        value === "architecture" &&
        architectures.archInterior
      ) {
        setArchitectures((prevState) => ({
          ...prevState,
          archExterior: false,
        }));
        setExteriorScope({
          architecture: false,
          furniture: false,
          installations: false,
        });

        setDigitalModel((prevState) => ({
          ...prevState,
          propertyInfoDigital: {
            ...prevState.propertyInfoDigital,
            exteriorScope: [],
          },
        }));
        return;
      } else if (
        isSelected &&
        value === "architecture" &&
        !architectures.archInterior
      ) {
        return;
      } else if (!isSelected && value !== "architecture") {
        const isArchitectureSelected = isScopeSelected(
          "architecture",
          exteriorScope
        );
        if (!isArchitectureSelected) {
          setArchitectures((prevState) => ({
            ...prevState,
            archExterior: true,
          }));

          setExteriorScope((prevState) => ({
            ...prevState,
            architecture: true,
          }));

          setDigitalModel((prevState) => ({
            ...prevState,
            propertyInfoDigital: {
              ...prevState.propertyInfoDigital,
              exteriorScope: [
                ...prevState.propertyInfoDigital.exteriorScope,
                {
                  id: "887ffbc3-00e7-449e-ab3f-02803c0d0054a",
                  type: "architecture",
                },
              ],
            },
          }));
        }
      }
      setDigitalModel((prevState) => {
        // Check if PropertyInfoScan exists
        const propertyInfoDigital = prevState.propertyInfoDigital || {};

        // Ensure ScanningType is an array
        const extScope = Array.isArray(propertyInfoDigital.exteriorScope)
          ? propertyInfoDigital.exteriorScope
          : [];

        // Check if newType is already in the array and remove it if selected
        const updatedScopeExterior = isSelected
          ? extScope.filter((exterior) => exterior.type !== value)
          : [...extScope, scopeType];

        // Return updated state
        return {
          ...prevState,
          propertyInfoDigital: {
            ...propertyInfoDigital,
            exteriorScope: updatedScopeExterior,
          },
        };
      });
      setExteriorScope((prevState) => ({
        ...prevState,
        [value]: !prevState[value],
      }));
    }
  };

  const handleChangeUnit = (selectedUnit) => {
    setUnit(selectedUnit); // This updates the `unit` state.

    // Update the nested `propertyInfoDigital.unit` in the `digitalModel` state.
    setDigitalModel((prevState) => ({
      ...prevState,
      propertyInfoDigital: {
        ...prevState.propertyInfoDigital,
        unit: selectedUnit,
      },
    }));
  };

  const ToggleButton = ({ label, selected, onClick }) => (
    <Button
      onClick={onClick}
      sx={{
        marginBottom: 1.5,
        width: { xs: 300, md: 400 },
        borderRadius: "12px",
        fontFamily: "Arial, Helvetica, sans-serif",
        backgroundColor: selected ? "#163D6C" : "white",
        color: selected ? "white" : "#404040",
        border: "0.5px solid #a6a6a6",
        "&:hover": {
          backgroundColor: selected ? "#163D6C" : "white",
        },
      }}
    >
      <Typography
        sx={{
          textTransform: "none",
          fontSize: "12px",
          padding: "7px",
          fontWeight: 500,
        }}
      >
        {label}
      </Typography>
    </Button>
  );

  return (
    <div className="digital-container">
      <div className="contet-digital-container">
        <img
          className="digitalBanner-img"
          src={DigitalBannerImg}
          alt="digitalBanner"
        />
        <div className="btnDownload-container">
          <IconButton
            variant="outlined"
            color="inherit"
            sx={{
              color: "#1a477f",
              "&:hover": {
                backgroundColor: "transparent", // Remove hover background color
              },
              "&:active": {
                backgroundColor: "transparent", // Remove click background color
                boxShadow: "none", // Remove click shadow
              },
              "&:focus": {
                backgroundColor: "transparent", // Remove focus background color
                boxShadow: "none", // Remove focus shadow
              },
              "&.MuiButtonBase-root": {
                transition: "none", // Remove transition effects
              },
            }}
          >
            <a
              className="link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://docs.google.com/spreadsheets/d/1StjP9z6JCF2vfqJ0UZkqgMcd96e90lLWfUIPWp5Wc_8/edit?usp=sharing"
            >
              <div className="btn-download-content">
                <img
                
                  className="digital-download-icon"
                  style={{ width: "18px", marginRight: "3px" }}
                  src={excelIcon}
                  alt="download-icon"
                />
                <span className="download-txt">
                  {t("digitalService.downloadBEP")}
                </span>
              </div>{" "}
            </a>
          </IconButton>
        </div>
        <br />
        <div>
          <div>
            <ValidatedTextField
              label={t("digitalService.uploadFilesLabel")}
              type="text"
              parent="projectUrl"
              fieldName="projectUrl"
              tooltip={t("digitalService.uploadFilesTooltip")}
              validator={requiredValidator}
              onChange={handleNestedInputChange}
            />
          </div>
          <div>
            <ValidatedTextField
              label={t("digitalService.enterSizeLabel")}
              type="number"
              parent="propertyInfoDigital"
              fieldName="propertySize"
              tooltip={t("digitalService.enterSizeTooltip")}
              maxValue={unit === "imperial" ? 300000 : 30000}
              validator={validatorWithMaxValue}
              onChange={handleNestedInputChange}
            />
          </div>

          <div className="unit-top-cont">
            <p className="unit-cont">
              <span
                className={`unit unit-1 ${unit === "imperial" ? "active" : ""}`}
                onClick={() => handleChangeUnit("imperial")}
              >
                {t("digitalService.imperial")}
              </span>
              <span> / </span>
              <span
                className={`unit unit-2 ${unit === "metric" ? "active" : ""}`}
                onClick={() => handleChangeUnit("metric")}
              >
                {t("digitalService.metric")}
              </span>
            </p>
          </div>
        </div>

        <div >
          <p className="type-title">{t("digitalService.selectPropertyType")}</p>
          <div>
            <ToggleButton
              label={t("digitalService.standart")}
              selected={propertyType.standart}
              onClick={toggleButtonState("standart", "property")}
            />
          </div>

          <ToggleButton
            label={t("digitalService.industrial")}
            selected={propertyType.industrial}
            onClick={toggleButtonState("industrial", "property")}
          />

       
        </div>
        {propertyType.standart && (
          <div>
       
          <p className="type-title">{t("digitalService.scopeInteriorTitle")}</p>
          <div>
               <ToggleButton
              label={t("digitalService.architecture")}
              selected={interiorScope.architecture}
              onClick={
                architectures.archExterior &&
                toggleButtonState("architecture", "scopeInterior")
              }
            />
          </div>
         <div>
            <ToggleButton
              label={t("digitalService.furniture")}
              selected={interiorScope.furniture}
              onClick={toggleButtonState("furniture", "scopeInterior")}
            />
         </div>
          <div>
             <ToggleButton
              label={t("digitalService.installations")}
              selected={interiorScope.installations}
              onClick={toggleButtonState("installations", "scopeInterior")}
            />  
          </div>
         
          </div>
        )}
        {propertyType.industrial && (
          <>
            <ToggleButton
              label={t("digitalService.complexInstallations")}
              selected={interiorScope.complex}
              onClick={toggleButtonState("complex", "scopeInterior")}
            />
          </>
        )}

  
        {/* {digitalModel.propertyInfoDigital?.exteriorDigital && ( */}
        {propertyType.industrial === false && (

          <>      
          <div className="scan-file digital-file">
      
       
          {/* <div>
            <form className="form-container">
              <div className="input-group">
                <input
                  className="custom-radio"
                  type="radio" id="option1"
                  name="options" value="Yes"
                  checked={digitalModel.propertyInfoDigital?.exteriorDigital}
                  onChange={(e) => handleOptionChange(e, 'exteriorDigital')}
                />
                <label htmlFor="option1">{t('digitalService.yes')}</label>
              </div>
              <div
                className="input-group">
                <input className="custom-radio" type="radio"
                  id="option2" name="options"
                  value="No"
                  checked={!digitalModel.propertyInfoDigital?.exteriorDigital}
                  onChange={(event) => handleOptionChange(event, 'exteriorDigital')}
                />
                <label htmlFor="option2">{t('digitalService.no')}</label>
              </div>
            </form>
          </div> */}
      
            <div className="exterior-scope">
        
              <p className="type-title">{t("digitalService.digitalizationExterior")}</p>
         
              <ToggleButton
                label={t("digitalService.architecture")}
                selected={exteriorScope.architecture}
                onClick={
                  architectures.archInterior &&
                  toggleButtonState("architecture", "scopeExterior")
                }
              />
              <ToggleButton
                label={t("digitalService.furniture")}
                selected={exteriorScope.furniture}
                onClick={toggleButtonState("furniture", "scopeExterior")}
              />
              <ToggleButton
                label={t("digitalService.installations")}
                selected={exteriorScope.installations}
                onClick={toggleButtonState("installations", "scopeExterior")}
              />
            </div>  </div>
            {/* )} */}
            {digitalModel.propertyInfoDigital?.exteriorScope?.length > 0 && (
              <>
                <div className="scan-file digital-file">
                  <div>
                      <p className="type-title">{t("digitalService.digitalizationSite")}</p>
                  </div>
                  <br />
                  <div>
                    <form className="form-container">
                      <div className="input-group">
                        <input
                          className="custom-radio"
                          type="radio"
                          id="option1"
                          name="options"
                          value="Yes"
                          checked={
                            digitalModel.propertyInfoDigital?.landDigital
                          }
                          onChange={(e) => handleOptionChange(e, "landDigital")}
                        />
                        <label htmlFor="option1">
                          {t("digitalService.yes")}
                        </label>
                      </div>
                      <div className="input-group">
                        <input
                          className="custom-radio"
                          type="radio"
                          id="option2"
                          name="options"
                          value="No"
                          checked={
                            !digitalModel.propertyInfoDigital?.landDigital
                          }
                          onChange={(e) => handleOptionChange(e, "landDigital")}
                        />
                        <label htmlFor="option2">
                          {t("digitalService.no")}
                        </label>
                      </div>
                    </form>
                  </div>
                </div>

                <br />
                {digitalModel.propertyInfoDigital?.landDigital && (
                  <ValidatedTextField
                    label={t("digitalService.enterSiteSize")}
                    type="number"
                    tooltip={t("digitalService.tooltipSite")}
                    parent="propertyInfoDigital"
                    fieldName="landSize"
                    //value={digitalModel.propertyInfoDigital?.landSize}
                    maxValue={unit === "imperial" ? 300000 : 30000}
                    validator={validatorWithMaxValue}
                    onChange={handleNestedInputChange}
                  />
                )}
              </>
            )}
          </>
        )}

        <ValidatedTextField
          label={t("digitalService.enterEmail")}
          type="email"
          parent="propertyInfoDigital"
          fieldName="email"
          showAdornment={false}
          validator={emailValidator}
          onChange={handleNestedInputChange}
        />

        <div className="prop-info-digital">
          <p className="gradient-text">
            <span>{t("digitalService.propertyInformation")}</span>
          </p>
          <div className="propDigital-container">
            <div className="propDigital-items">
              <ValidatedTextField
                label={t("digitalService.projectName")}
                type="text"
                parent="projectName"
                fieldName="projectName"
                tooltip={t("digitalService.projectNameTooltip")}
                validator={requiredValidator}
                onChange={handleNestedInputChange}
              />
            </div>
            <div className="propDigital-items">
              <ValidatedTextField
                label={t("digitalService.virtualTour")}
                type="text"
                parent="propertyInfoDigital"
                fieldName="virtualTower"
                showAdornment={true}
                tooltip={t("digitalService.virtualTourTooltip")}
                validator={noValidator}
                onChange={handleNestedInputChange}
              />
            </div>
            <div className="propDigital-items">
              <ValidatedTextField
                label={t("digitalService.address")}
                type="text"
                parent="propertyInfoDigital"
                fieldName="address"
                showAdornment={false}
                validator={noValidator}
                onChange={handleNestedInputChange}
              />
            </div>
            <div className="propDigital-items">
              <FormControl
                sx={{ width: { xs: "300px", md: "400px" } }}
                size="small"
              >
                <InputLabel
                  sx={{
                    fontSize: "11px",
                    padding: "8px",
                    fontWeight: "600",
                    marginLeft: "-0px",
                    textAlign: "left",
                  }}
                  id="revit"
                >
                  {t("digitalService.revitVersion")}
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  fullWidth
                  value={digitalModel?.propertyInfoDigital?.revitVersion}
                  label={t("digitalService.revitVersion")}
                  onChange={(event) =>
                    handleNestedInputChange(
                      event,
                      "propertyInfoDigital",
                      "revitVersion"
                    )
                  }
                  sx={{
                    padding: "2px", // Add padding
                    borderRadius: "12px", // Add border radius
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "12px", // Ensure the border radius is applied to the outlined input
                    },
                  }}
                >
                  <MenuItem value="Revit Version 2020">
                    {t("digitalService.revitVersionOptions.2020")}
                  </MenuItem>
                  <MenuItem value="Revit Version 2021">
                    {t("digitalService.revitVersionOptions.2021")}
                  </MenuItem>
                  <MenuItem value="Revit Version 2022">
                    {t("digitalService.revitVersionOptions.2022")}
                  </MenuItem>
                  <MenuItem value="Revit Version 2023">
                    {t("digitalService.revitVersionOptions.2023")}
                  </MenuItem>
                  <MenuItem value="Revit Version 2024">
                    {t("digitalService.revitVersionOptions.2024")}
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>

        <br />
      </div>{" "}
      <PricingCardDigital
        digitalModel={digitalModel}
        setDigitalModel={setDigitalModel}
      />
    </div>
  );
}
