import { act } from "react";


export const getAllElementsByCategory = (category, displayName, viewerInstance) => {
  return new Promise((resolve, reject) => {
    viewerInstance.model.getObjectTree(function (instanceTree) {
      const allDbIds = [];
      instanceTree.enumNodeChildren(instanceTree.getRootId(), function (dbId) {
        allDbIds.push(dbId);
      }, true);

      const elementProperties = [];
      const matchingDbIds = [];
      let count = 0;

      allDbIds.forEach(dbId => {
        viewerInstance.getProperties(dbId, function (props) {
          count++;
          const categoryProperty = props.properties.find(prop => prop.displayName.toLowerCase() === 'category');
          if (categoryProperty && categoryProperty.displayValue.toLowerCase() === displayName.toLowerCase()) {
            elementProperties.push(props);
            matchingDbIds.push(dbId);
          }
          if (count === allDbIds.length) {
            isolateElements(viewerInstance, matchingDbIds);
            const groupedElements = groupElementsByTypeName(elementProperties);
            resolve(groupedElements);
          }
        }, function (err) {
          count++;
          if (count === allDbIds.length) {
            isolateElements(viewerInstance, matchingDbIds);
            const groupedElements = groupElementsByTypeName(elementProperties);
            resolve(groupedElements);
          }
        });
      });
    });
  });
};

export const getElementDbIds = async (category, displayName, viewerInstance) => {
  return new Promise((resolve, reject) => {
    viewerInstance.model.getObjectTree(function (instanceTree) {
      const allDbIds = [];
      instanceTree.enumNodeChildren(instanceTree.getRootId(), function (dbId) {
        allDbIds.push(dbId);
      }, true);

      const matchingDbIds = [];
      let count = 0;

      allDbIds.forEach(dbId => {
        viewerInstance.getProperties(dbId, function (props) {
          count++;
          const categoryProperty = props.properties.find(prop => prop.displayName.toLowerCase() === 'category');
          if (categoryProperty && categoryProperty.displayValue.toLowerCase() === displayName.toLowerCase()) {
            matchingDbIds.push(dbId);
          }
          if (count === allDbIds.length) {
            resolve(matchingDbIds);
          }
        }, function (err) {
          count++;
          if (count === allDbIds.length) {
            resolve(matchingDbIds);
          }
        });
      });
    });
  });
};



function getElementTypeName(element) {
  if (element) {
    const typeName = element.properties.find(prop => prop.displayName.toLowerCase() === 'type name');
    if (typeName) {
      return typeName.displayValue;
    }
  }
  return null;
}

function groupElementsByTypeName(elements) {
  const grouped = {};
  elements.forEach(element => {
    const typeName = getElementTypeName(element);
    if (typeName) {
      if (!grouped[typeName]) {
        grouped[typeName] = { typeName, elements: [] };
      }
      grouped[typeName].elements.push(element);
    }
  });
  return Object.values(grouped);
}

  
export const getAllCeilings = (viewerInstance) => {
  return new Promise((resolve, reject) => {
    viewerInstance.model.getObjectTree(function (instanceTree) {
      const allDbIds = [];
      instanceTree.enumNodeChildren(instanceTree.getRootId(), function (dbId) {
        allDbIds.push(dbId);
      }, true);

      const ceilingProperties = [];
      const ceilingDbIds = [];
      let count = 0;

      allDbIds.forEach(dbId => {
        viewerInstance.getProperties(dbId, function (props) {
          count++;
          const categoryProperty = props.properties.find(prop => prop.displayName.toLowerCase() === 'category');
          if (categoryProperty && categoryProperty.displayValue.toLowerCase().includes('ceiling')) {
            ceilingProperties.push(props);
            ceilingDbIds.push(dbId);
          }
          if (count === allDbIds.length) {
            isolateElements(viewerInstance, ceilingDbIds);
            const groupedCeilings = groupElementsByTypeName(ceilingProperties);

            // Calculate total area for each group
            const totalAreas = {};
            groupedCeilings.forEach(group => {
              const areaSum = group.elements.reduce((sum, ceiling) => {
                const area = parseFloat(getArea(ceiling)); // Use the getArea function
                return sum + area;
              }, 0);
              group.area = areaSum.toFixed(2);// Create a new property 'area' for the group
            });

            resolve(groupedCeilings);
          }
        }, function (err) {
          count++;
          if (count === allDbIds.length) {
            isolateElements(viewerInstance, ceilingDbIds);
            const groupedCeilings = groupElementsByTypeName(ceilingProperties);
            resolve(groupedCeilings);
          }
        });
      });
    });
  });
};


function getArea(element) {
    
  if (element) {
    const areaProperty = element.properties.find(prop => prop.displayName.toLowerCase() === 'area');
    if (areaProperty) {
      return parseFloat(areaProperty.displayValue).toFixed(2);
    }
  }
  return null; // Return null if the wall or area property is not found
}

function getVolume(element) {
    
  if (element) {
    const areaProperty = element.properties.find(prop => prop.displayName.toLowerCase() === 'volume');
    if (areaProperty) {
      return parseFloat(areaProperty.displayValue).toFixed(2);
    }
  }
  return 0; // Return 0 if the wall or volume property is not found
}

let isolatedDbIds = [];
export function isolateElements(viewerInstance, dbIds) {
  isolatedDbIds = dbIds; 
    viewerInstance.isolate(dbIds);
    viewerInstance.fitToView(dbIds);
}

export function removeElementsFromIsolation(viewerInstance, dbIdsToRemove) {
  // Get currently isolated elements
  isolatedDbIds = isolatedDbIds.filter(id => !dbIdsToRemove.includes(id));

  // Reapply isolation with the updated list
  viewerInstance.isolate(isolatedDbIds.length > 0 ? isolatedDbIds : []);
  viewerInstance.fitToView(isolatedDbIds.length > 0 ? isolatedDbIds : null);
}


export function getAllWalls(viewerInstance) {
  return new Promise((resolve, reject) => {
      viewerInstance?.model?.getObjectTree(function(instanceTree) {
          const allDbIds = [];
          instanceTree.enumNodeChildren(instanceTree.getRootId(), function(dbId) {
              allDbIds.push(dbId);
          }, true);

          const wallProperties = [];
          const wallDbIds = [];
          const groupedWalls = [];

          let count = 0;

          allDbIds.forEach(dbId => {
              viewerInstance.getProperties(dbId, function(props) {
                  count++;
                  const categoryProperty = props.properties.find(prop => prop.displayName.toLowerCase() === 'category');
                  if (categoryProperty && categoryProperty.displayValue.toLowerCase() === 'revit walls') {
                      wallProperties.push(props);
                      wallDbIds.push(dbId);
                  }
                  if (count === allDbIds.length) {
                      isolateElements(viewerInstance, wallDbIds);
                      const groupedWalls = groupElementsByTypeName(wallProperties);

                      // Calculate total area for each group
                      groupedWalls.forEach(group => {
                          // const areaSum = group.elements.reduce((sum, wall) => {
                          //     const area = parseFloat(getArea(wall)); // Use the getArea function
                          //     return sum + area;
                          // }, 0);
                          // group.area = areaSum.toFixed(2); // Create a new property 'area' for the group

                          const volumeSum = group.elements.reduce((sum, wall) => {
                            const volume = parseFloat(getVolume(wall)); // Use the getVolume function
                            return sum + volume;
                        }, 0);
                        group.volume = volumeSum.toFixed(2);
                      });

                      resolve(groupedWalls);
                  }
              }, function(err) {
                  count++;
                  if (count === allDbIds.length) {
                      isolateElements(viewerInstance, wallDbIds);
                      const groupedWalls = groupElementsByTypeName(wallProperties);
                      resolve(groupedWalls);
                  }
              });
          });
      });
  });
}



export function getAllFloors(viewerInstance) {
  return new Promise((resolve, reject) => {
      viewerInstance.model.getObjectTree(function(instanceTree) {
          const allDbIds = [];
          instanceTree.enumNodeChildren(instanceTree.getRootId(), function(dbId) {
              allDbIds.push(dbId);
          }, true);

          const floorProperties = [];
          const floorDbIds = [];
          const groupedFloors = [];
          let count = 0;

          allDbIds.forEach(dbId => {
              viewerInstance.getProperties(dbId, function(props) {
                  count++;
                  const categoryProperty = props.properties.find(prop => prop.displayName.toLowerCase() === 'category');
                  if (categoryProperty && categoryProperty.displayValue.toLowerCase().includes('floor')) {
                      floorProperties.push(props);
                      floorDbIds.push(dbId);
                  }
                  if (count === allDbIds.length) {
                      isolateElements(viewerInstance, floorDbIds);
                      const groupedFloors = groupElementsByTypeName(floorProperties);

                      // Calculate total area for each group
                      groupedFloors.forEach(group => {
                          const areaSum = group.elements.reduce((sum, floor) => {
                              const area = parseFloat(getArea(floor)); // Use the getArea function
                              return sum + area;
                          }, 0);
                          group.area = areaSum.toFixed(2); // Create a new property 'area' for the group
                      });

                      resolve(groupedFloors);
                  }
              });
          });
      });
  });
}


  
  export  function getAllStructuralColumns(viewerInstance) {
   return getAllElementsByCategory('structural columns', 'Revit Structural Columns', viewerInstance);
  }
  
  
  export  function getAllDoors(viewerInstance) {
    return getAllElementsByCategory('doors', 'Revit Doors', viewerInstance);
  }
  
  export function getAllWindows(viewerInstance) {
   return getAllElementsByCategory('windows', 'Revit Windows', viewerInstance);
  }
  
  export  function getAllStairs(viewerInstance) {
    return getAllElementsByCategory('stairs', 'Revit Stairs', viewerInstance);
  }
  
  export  function getAllColumns(viewerInstance) {
    return getAllElementsByCategory('columns', 'Revit Columns', viewerInstance);
  }
  
  export  function getAllStructuralFraming(viewerInstance) {
    return getAllElementsByCategory('structuralFraming', 'Revit Structural Framing', viewerInstance);
  }
  
  export  function getAllStructuralColumn(viewerInstance) {
    return getAllElementsByCategory('structuralColumn', 'Revit Structural Column', viewerInstance);
  }
  
  // function getAllFloors() {
  //   getAllElementsByCategory('floors', 'Revit Floors');
  // }
  
  export  function getAllRailings(viewerInstance) {
    return getElementsAndGroupByLength('railings', 'Revit Railings', viewerInstance);
  }
  
  export  function getAllPlumbingFixtures(viewerInstance) {
    return getAllElementsByCategory('plumbingFixtures', 'Revit Plumbing Fixtures', viewerInstance);
  }
  
  export  function getAllCasework(viewerInstance) {
    return getAllElementsByCategory('casework', 'Revit Casework', viewerInstance);
  }
  
  export  function getAllLightingFixtures(viewerInstance) {
    return getAllElementsByCategory('lightingFixtures', 'Revit Lighting Fixtures', viewerInstance);
  }
  
  export  function getAllFurniture( viewerInstance) {
    return getAllElementsByCategory('furniture', 'Revit Furniture', viewerInstance);
  }

  export  function getAllSites( viewerInstance) {
    return getAllElementsByCategory('site', 'Revit Site', viewerInstance);
  }

  export  function getAllPlantings( viewerInstance) {
    return getAllElementsByCategory('planting', 'Revit Planting', viewerInstance);
  }


  export  function getAllGenericModels(viewerInstance) {
    return getAllElementsByCategory('genericModels', 'Revit Generic Models', viewerInstance);
  }
  
  export  function getAllSpecialityEquipment(viewerInstance) {
    return getAllElementsByCategory('specialtyEquipment', 'Revit Specialty Equipment', viewerInstance);
  }
  
  export  function getAllMechanicalEquipment( viewerInstance) {
    return getAllElementsByCategory('mechanicalEquipment', 'Revit Mechanical Equipment', viewerInstance);
  }
  
  
  export  function getAllDucts( viewerInstance) {
    return getElementsAndGroupByLength('ducts', 'Revit Ducts', viewerInstance);
  }
  
  export function getAllConduits( viewerInstance) {
    return getElementsAndGroupByLength('conduits', 'Revit Conduits', viewerInstance);
  } 
  
  export  function getAllPipeFittings(viewerInstance) {
    return getMEP('pipeFittings', 'Revit Pipe Fittings', viewerInstance);
  }
  
  export  function getAllDuctFittings( viewerInstance) {
    return getAllElementsByCategory('ductFittings', 'Revit Duct Fittings', viewerInstance);
  }
  
  export  function getAllConduitFittings(viewerInstance) {
    return getAllElementsByCategory('conduitFittings', 'Revit Conduit Fittings', viewerInstance);
  }
  
 export function getAllTopography(viewerInstance) {
    return new Promise((resolve, reject) => {
      viewerInstance?.model?.getObjectTree(function(instanceTree) {
          const allDbIds = [];
          instanceTree.enumNodeChildren(instanceTree.getRootId(), function(dbId) {
              allDbIds.push(dbId);
          }, true);

          const topohraphyProperties = [];
          const topographyDbIds = [];
          let count = 0;

          allDbIds.forEach(dbId => {
              viewerInstance.getProperties(dbId, function(props) {
                  count++;
                  const categoryProperty = props.properties.find(prop => prop.displayName.toLowerCase() === 'category');
                  if (categoryProperty && categoryProperty.displayValue.toLowerCase() === 'revit topography') {
                    topohraphyProperties.push(props);
                    topographyDbIds.push(dbId);
                  }
                  if (count === allDbIds.length) {
                      isolateElements(viewerInstance, topographyDbIds);
                      const groupedTopographies = groupElementsByTypeName(topohraphyProperties);

                      // Calculate total area for each group
                      groupedTopographies.forEach(group => {
                          // const areaSum = group.elements.reduce((sum, wall) => {
                          //     const area = parseFloat(getArea(wall)); // Use the getArea function
                          //     return sum + area;
                          // }, 0);
                          // group.area = areaSum.toFixed(2); // Create a new property 'area' for the group

                          const volumeSum = group.elements.reduce((sum, topography) => {
                            const volume = parseFloat(getVolume(topography)); // Use the getVolume function
                            return sum + volume;
                        }, 0);
                        group.volume = volumeSum.toFixed(2);
                      });

                      resolve(groupedTopographies);
                  }
              }, function(err) {
                  count++;
                  if (count === allDbIds.length) {
                      isolateElements(viewerInstance, topographyDbIds);
                      const groupedTopographies = groupElementsByTypeName(topographyDbIds);
                      resolve(groupedTopographies);
                  }
              });
          });
      });
  });
  }
  
   export function getAllPipes(viewerInstance) {
    return getElementsAndGroupByLength('pipes', 'Revit Pipes', viewerInstance);
  }
  
  export const getAllArchitectureCategory = async (viewerInstance, action) => {
    try {
      // Resolve all promises for the categories
      const [wallsIDs, ceilingIDs,floorsIDs, doorsIDs, structuralColumnIds, columnsIds] = await Promise.all([
        getElementDbIds('walls', 'Revit walls', viewerInstance),
        getElementDbIds('ceilings', 'Revit ceilings', viewerInstance),
        getElementDbIds('floors', 'Revit floors', viewerInstance),
        getElementDbIds('doors', 'Revit Doors', viewerInstance),
        getElementDbIds('structuralColumn', 'Revit Structural Column', viewerInstance),
        getElementDbIds('columns', 'Revit Columns', viewerInstance)
      ]);
  
  
      // Combine all IDs into a single array
      const allIDs = [
        ...(wallsIDs || []),
         ...(ceilingIDs || []),
        //...(floorsIDs || []),
        ...(doorsIDs || []),
        ...(structuralColumnIds || []),
        ...(columnsIds || [])
      ];
  
      if(action === 'add'){
        isolateElements(viewerInstance, allIDs);
        return;
      }
      removeElementsFromIsolation(viewerInstance, allIDs)
    } catch (error) {

    }
  };

  export const getAllFurnitureCategory = async (viewerInstance, action) => {
    try {
      // Resolve all promises for the categories
      const [furnituresIDs, specialtyEquipmentIds,caseworkIds, genericModelsIds] = await Promise.all([
        getElementDbIds('furnitures', 'Revit furnitures', viewerInstance),
        getElementDbIds('specialtyEquipment', 'Revit Specialty Equipment', viewerInstance),
        getElementDbIds('casework', 'Revit Casework', viewerInstance),
        getElementDbIds('genericModels', 'Revit Generic Models', viewerInstance),
 
      ]);
  
      // Combine all IDs into a single array
      const allIDs = [
        ...(furnituresIDs || []),
        ...(specialtyEquipmentIds || []),
        ...(caseworkIds || []),
        ...(genericModelsIds || [])
      ];
  
      // Pass the combined IDs to the isolateElements function
      if(action === 'add'){
        isolateElements(viewerInstance, allIDs);
        return;
      }
      removeElementsFromIsolation(viewerInstance, allIDs)
    } catch (error) {
  
    }
  };

  export const getAllMEPFCategory = async (viewerInstance, action) => {
    try {
      // Resolve all promises for the categories
      const [plumbingFixturesIDs, lightingFixturesIds,airTerminalsIds,electricalEquipmentIds,
        electricalFixturesIds,  mechanicalEquipmentIds] = await Promise.all([
        getElementDbIds('plumbingFixtures', 'Revit Plumbing Fixtures', viewerInstance),
        getElementDbIds('lightingFixtures', 'Revit Lighting Fixtures', viewerInstance),
        getElementDbIds('airTerminals', 'Revit Air Terminals', viewerInstance),
        getElementDbIds('electricalEquipment', 'Revit Electrical Equimpent', viewerInstance),
        getElementDbIds('electricalFixtures', 'Revit Electrical Fixtures', viewerInstance),
        getElementDbIds('mechanicalEquipment', 'Revit Mechanical Equipment', viewerInstance),
      ]);
  
      // Combine all IDs into a single array
      const allIDs = [
        ...(plumbingFixturesIDs || []),
        ...(lightingFixturesIds || []),
        ...(airTerminalsIds || []),
        ...(electricalEquipmentIds || []),
        ...(electricalFixturesIds || []),
        ...(mechanicalEquipmentIds || []),
      ];
  
      // Pass the combined IDs to the isolateElements function
      if(action === 'add'){
        isolateElements(viewerInstance, allIDs);
        return;
      }
      removeElementsFromIsolation(viewerInstance, allIDs)
    } catch (error) {

    }
  };
  
  export const getMEP = (category, displayName, viewerInstance) => {
    return new Promise((resolve, reject) => {
      viewerInstance.model.getObjectTree(function(instanceTree) {
        const allDbIds = [];
        instanceTree.enumNodeChildren(instanceTree.getRootId(), function(dbId) {
          allDbIds.push(dbId);
        }, true);
  
        const elementProperties = [];
        const matchingDbIds = [];
        let count = 0;
  
        allDbIds.forEach(dbId => {
          viewerInstance.getProperties(dbId, function(props) {
            count++;
            const categoryProperty = props.properties.find(prop => prop.displayName.toLowerCase() === 'category');
            if (categoryProperty && categoryProperty.displayValue.toLowerCase() === displayName.toLowerCase()) {
              const lengthProperty = props.properties.find(prop => prop.displayName.toLowerCase() === 'length');
              const lengthValue = lengthProperty ? lengthProperty.displayValue : 'N/A';
              elementProperties.push({ ...props, length: lengthValue });
              matchingDbIds.push(dbId);
            }
            if (count === allDbIds.length) {
              isolateElements(viewerInstance, matchingDbIds);
              const groupedElements = groupElementsByTypeName(elementProperties);
              resolve(groupedElements);
            }
          }, function(err) {
            count++;
            if (count === allDbIds.length) {
              isolateElements(viewerInstance, matchingDbIds);
              const groupedElements = groupElementsByTypeName(elementProperties);
              resolve(groupedElements);
            }
          });
        });
      });
    });
  };
  

  export const getElementsAndGroupByLength = (category, displayName, viewerInstance) => {
    return new Promise((resolve, reject) => {
      viewerInstance.model.getObjectTree(function(instanceTree) {
        const allDbIds = [];
        instanceTree.enumNodeChildren(instanceTree.getRootId(), function(dbId) {
          allDbIds.push(dbId);
        }, true);
  
        const elementProperties = [];
        const matchingDbIds = [];
        let count = 0;
  
        allDbIds.forEach(dbId => {
          viewerInstance.getProperties(dbId, function(props) {
            count++;
            const categoryProperty = props.properties.find(prop => prop.displayName.toLowerCase() === 'category');
            if (categoryProperty && categoryProperty.displayValue.toLowerCase() === displayName.toLowerCase()) {
              elementProperties.push(props);
              matchingDbIds.push(dbId);
            }
            if (count === allDbIds.length) {
              isolateElements(viewerInstance, matchingDbIds);
              const groupedElements = groupElementsByTypeName(elementProperties);
  
              // Calculate total length for each group
              Object.values(groupedElements).forEach(group => {
                const totalLength = group.elements.reduce((sum, element) => {
                  const length = parseFloat(getLength(element)) || 0;
                  return sum + length;
                }, 0);
                group.length = getLength(group.elements[0]); // Add totalLength property to each group
              });
  
              resolve(groupedElements);
            }
          }, function(err) {
            count++;
            if (count === allDbIds.length) {
              isolateElements(viewerInstance, matchingDbIds);
              const groupedElements = groupElementsByTypeName(elementProperties);
  
              // Calculate total length for each group
              Object.values(groupedElements).forEach(group => {
                // const totalLength = group.elements.reduce((sum, element) => {
                //   const length = parseFloat(getLength(element)) || 0;
                //   return sum + length;
                // }, 0);
                group.length = getLength(group.elements[0]); // Add totalLength property to each group
              });

              resolve(groupedElements);
            }
          });
        });
      });
    });
  };

  // Helper function to get the length of an element
function getLength(element) {
  if (element) {
    const lengthProperty = element.properties.find(prop => prop.displayName.toLowerCase() === 'length');
    if (lengthProperty) {
      return parseFloat(lengthProperty.displayValue).toFixed(2);
    }
  }
  return 0; // Return 0 if the length property is not found
}

 
  