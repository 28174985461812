import i18n from "../i18n";

export const textValidator = value => {
  if (!/^[a-zA-Z ]+$/.test(value))
    return i18n.t('textValidation.lettersAndSpacesOnly'); // Translation key for "Input must contain only letters and spaces"
  return '';
};
export const isValidURL = (url) => {
  const urlRegex = new RegExp(
    '^(https?:\\/\\/)?' + // Protocol
    '((([a-zA-Z0-9$-_.+!*\'(),;:&=]+\\.)+[a-zA-Z]{2,})|' + // Domain name or IP address
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
    '(:\\d+)?(\\/[-a-zA-Z0-9@:%_+.~#?&/=]*)?' + // Port and path
    '(\\?[;&a-zA-Z0-9%_.~+=-]*)?' + // Query string
    '(#[-a-zA-Z0-9_]*)?$', 'i' // Fragment locator
  );

  return urlRegex.test(url);
};

export const emailValidator = value => {
  if (!/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/.test(value))
    return i18n.t('textValidation.invalidEmail'); // Translation key for "Invalid email address"
  return '';
};

export const positiveNumberValidator = value => {
  if (!/^[0-9]+$/.test(value)) {
    return i18n.t('textValidation.numbersOnly'); // Translation key for "Value must contain only numbers"
  }
  if (value <= 0) {
    return i18n.t('textValidation.greaterThanZero'); // Translation key for "Value must be greater than 0"
  }
  return '';
};

export const noValidator = () => {
  return '';
}

export const validatePassword = (password) => {
  const minLength = 8;
  const hasNumber = /\d/;
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
  let error = '';

  if (password.length < minLength) {
    error += i18n.t('textValidation.minLength', { minLength }); // Translation key for "Password must be at least X characters long."
  }

  if (!hasNumber.test(password)) {
    error += i18n.t('textValidation.mustContainNumber'); // Translation key for "Password must contain at least one number."
  }

  if (!hasSpecialChar.test(password)) {
    error += i18n.t('textValidation.mustContainSpecialChar'); // Translation key for "Password must contain at least one special character."
  }

  return error;
};

export const validateConfirmPassword = (confirmPassword, password) => {
  if (confirmPassword !== password) {
    return i18n.t('textValidation.passwordsDoNotMatch'); // Translation key for "Passwords do not match."
  }
  return '';
};

export const phoneNumberValidator = value => {
  const phoneRegex = /^(\(\d{3}\) \d{3}-\d{4}|\d{3}-\d{3}-\d{4}|\d{1,15})$/;

  if (!phoneRegex.test(value)) {
    return i18n.t('textValidation.invalidPhoneNumber'); // Translation key for "Invalid phone number. Accepted formats: (123) 456-7890, 123-456-7890, or up to 15 digits."
  }

  return '';
};

export const requiredValidator = value => {
  if (value === '' || value === null || value === 'undefined') {
    return i18n.t('textValidation.required'); // Translation key for "This field is required. Must not be empty."
  }

  return '';
};

export const validatorWithMaxValue = (value, maxValue, fieldName) => {

  if (fieldName === 'SizeOfSite' && (isNaN(value) || value <= 0 || value === '')) {

    return '';
  }
  if (!/^[0-9]+$/.test(value)) {
    return i18n.t('textValidation.numbersOnly'); // Translation key for "Value must contain only numbers"
  }
  if (value <= 0 )  {
    return i18n.t('textValidation.greaterThanZero'); // Translation key for "Value must be greater than 0"
  }
  if (value > maxValue) {
    return i18n.t('textValidation.maxValue', { maxValue }); // Translation key for "Value must not be bigger than X"
  }
  return '';
};

export const validateFields = (payload) => {
  const errors = [];
  
  if (!payload.propertyInfoScan.address.country || payload.propertyInfoScan.address.country.trim() === '') {
    errors.push(i18n.t('textValidation.countryRequired')); // Translation key for "Country is required"
  }

  if (!payload.propertyInfoScan.address.region || payload.propertyInfoScan.address.region.trim() === '') {
    errors.push(i18n.t('textValidation.regionRequired')); // Translation key for "Region is required"
  }

  if (!payload.propertyInfoScan.sizeOfSpace || payload.propertyInfoScan.sizeOfSpace <= 0) {
    errors.push(i18n.t('textValidation.sizeOfSpaceRequired')); // Translation key for "Size of Space must be a positive number"
  }
  if(!(isNaN(payload.propertyInfoScan.sizeOfSite ) || payload.propertyInfoScan.sizeOfSite  <= 0 || payload.propertyInfoScan.sizeOfSite  === '')){
     if (!payload.propertyInfoScan.sizeOfSite || payload.propertyInfoScan.sizeOfSite <= 0) {
    errors.push(i18n.t('textValidation.sizeOfSiteRequired')); // Translation key for "Size of Site must be a positive number"
  }
  }
 

  if (!payload.propertyInfoScan.propertyType || payload.propertyInfoScan.propertyType.length === 0) {
    errors.push(i18n.t('textValidation.propertyTypeRequired')); // Translation key for "Select a property type."
  }

  if (!payload.propertyInfoScan.scanningType || payload.propertyInfoScan.scanningType.length === 0) {
    errors.push(i18n.t('textValidation.scanningTypeRequired')); // Translation key for "Select a scan type."
  }

  if (payload.propertyInfoScan.scanningType && payload.propertyInfoScan.scanningType.length === 1 &&  
    payload.propertyInfoScan.scanningType[0].type === 'site'
  ) {
    errors.push(i18n.t('textValidation.interiorOrExteriorRequired')); // Translation key for "At least interior or exterior scan type should be selected."
  }
  
  // if (!payload.bookingMeeting) {
  //   errors.push(i18n.t('textValidation.bookingMeetingRequired')); // Translation key for "Booking Meeting is required"
  // }

  if ((!payload.businessName || payload.businessName === '') && (payload.propertyInfoScan.propertyType.length > 0 &&
    payload.googleMap === true)) {
    errors.push(i18n.t('textValidation.businessNameRequired')); // Translation key for "Enter a valid Business Name"
  }

  if (!payload.siteContact.name || payload.siteContact.name.trim() === '') {
    errors.push(i18n.t('textValidation.siteContactNameRequired')); // Translation key for "Site Contact Name is required"
  }

  if (!payload.siteContact.email || !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(payload.siteContact.email)) {
    errors.push(i18n.t('textValidation.validEmailRequired')); // Translation key for "Enter a valid email address"
  }

  if ((payload.propertyInfoScan.propertyType.length > 0 &&  payload.googleMap === true) 
    && (!payload.emailBusiness || !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(payload.emailBusiness)) && 
    payload.propertyInfoScan.propertyType[0]?.type !== 'residence') {
    errors.push(i18n.t('textValidation.validEmailRequired')); // Translation key for "Enter a valid email address"
  }

  const phoneNumberRegex = /^\+?[1-9]\d{1,14}$/;
  if (!payload.siteContact.phoneNumber || !phoneNumberRegex.test(payload.siteContact.phoneNumber)) {
    errors.push(i18n.t('textValidation.validPhoneNumberRequired')); // Translation key for "Enter a valid phone number"
  }

  if (!payload.propertyInfoScan.description || payload.propertyInfoScan.description.trim() === '') {
    errors.push(i18n.t('textValidation.descriptionRequired')); // Translation key for "Description is required"
  }

  if (!payload.projectName || payload.projectName.trim() === '') {
    errors.push(i18n.t('textValidation.projectNameRequired')); // Translation key for "Project Name must not be empty"
  }

  return errors;
};

export const validateDigitalProject = (project) => {
  let errors = [];

  if (!project.projectUrl.trim() || !isValidURL(project.projectUrl)) {
    errors.push(i18n.t('textValidation.validProjectUrlRequired')); // Translation key for "Valid project URL(transfer link) is required."
  }

  const propertyInfo = project.propertyInfoDigital;
  if (propertyInfo.propertySize <= 0) {
    errors.push(i18n.t('textValidation.propertySizePositive')); // Translation key for "Property size must be greater than 0."
  }

  if (!propertyInfo.unit.trim()) {
    errors.push(i18n.t('textValidation.unitRequired')); // Translation key for "Unit is required."
  }

  if (propertyInfo.unit === 'imperial' && propertyInfo.propertySize > 300000) {
    errors.push(i18n.t('textValidation.sizeImperialMax', { maxValue: 300000 })); // Translation key for "Size should be less than 300,000 for unit imperial"
  }

  if (propertyInfo.unit === 'metric' && propertyInfo.propertySize > 30000) {
    errors.push(i18n.t('textValidation.sizeMetricMax', { maxValue: 30000 })); // Translation key for "Size should be less than 30,000 for unit metric"
  }

  if (!Array.isArray(propertyInfo.propertyType) || propertyInfo.propertyType.length === 0) {
    errors.push(i18n.t('textValidation.propertyTypeRequired')); // Translation key for "At least one property type is required."
  }

  if ((!Array.isArray(propertyInfo.interiorScope) || !Array.isArray(propertyInfo.exteriorScope)) ||
       (propertyInfo.interiorScope.length === 0 && propertyInfo.exteriorScope.length === 0)) {
    errors.push(i18n.t('textValidation.interiorScopeRequired')); // Translation key for "At least one interior scope type is required."
  }
  if(propertyInfo.landDigital === true && (propertyInfo.landSize <= 0 || propertyInfo.landSize === '' || isNaN(propertyInfo.landSize ))){
    errors.push(i18n.t('textValidation.invalidSizeSite')); // Translation key for "Please enter a valid size site"
  }
  if (!project.email.trim()) {
    errors.push(i18n.t('textValidation.invalidEmail')); // Translation key for "Unit is required."
  }

  if (!project.projectName.trim()) {
    errors.push(i18n.t('textValidation.projectNameRequired')); // Translation key for "Project name is required."
  }

  if (!propertyInfo.revitVersion.trim()) {
    errors.push(i18n.t('textValidation.revitVersion')); // Translation key for "Unit is required."
  }

  return errors;
};
