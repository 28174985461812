export const workingDays = {
    "0-25": {
        "Interior": {
            "Architecture": "2-4",
            "Architecture & Installations": "2-5",
            "Architecture & Furniture": "2-5",
            "Architecture & Furniture & Installations": "2-5",
            "Complex Installations": "4-7"
        },
        "Exterior": {
            "Architecture": "1-2",
            "Architecture & Installations": "1-3",
            "Architecture & Furniture": "2-4",
            "Architecture & Furniture & Installations": "2-7",
            "Site": "1-2"
        }
    },
    "26-50": {
        "Interior": {
            "Architecture": "2-4",
            "Architecture & Installations": "2-5",
            "Architecture & Furniture": "2-5",
            "Architecture & Furniture & Installations": "2-5",
            "Complex Installations": "4-7"
        },
        "Exterior": {
            "Architecture": "1-2",
            "Architecture & Installations": "1-3",
            "Architecture & Furniture": "2-4",
            "Architecture & Furniture & Installations": "2-7",
            "Site": "1-2"
        }
    },
    "51-75": {
        "Interior": {
            "Architecture": "2-4",
            "Architecture & Installations": "2-5",
            "Architecture & Furniture": "2-5",
            "Architecture & Furniture & Installations": "3-6",
            "Complex Installations": "4-10"
        },
        "Exterior": {
            "Architecture": "1-2",
            "Architecture & Installations": "1-3",
            "Architecture & Furniture": "3-5",
            "Architecture & Furniture & Installations": "3-8",
            "Site": "1-2"
        }
    },
    "76-100": {
        "Interior": {
            "Architecture": "2-4",
            "Architecture & Installations": "2-5",
            "Architecture & Furniture": "2-5",
            "Architecture & Furniture & Installations": "3-6",
            "Complex Installations": "5-14"
        },
        "Exterior": {
            "Architecture": "1-2",
            "Architecture & Installations": "1-3",
            "Architecture & Furniture": "3-5",
            "Architecture & Furniture & Installations": "2-7",
            "Site": "1-2"
        }
    },
    "101-200": {
        "Interior": {
            "Architecture": "3-5",
            "Architecture & Installations": "3-6",
            "Architecture & Furniture": "3-6",
            "Architecture & Furniture & Installations": "3-8",
            "Complex Installations": "7-19"
        },
        "Exterior": {
            "Architecture": "1-2",
            "Architecture & Installations": "1-3",
            "Architecture & Furniture": "3-5",
            "Architecture & Furniture & Installations": "2-7",
            "Site": "1-2"
        }
    },
    "201-300": {
        "Interior": {
            "Architecture": "3-5",
            "Architecture & Installations": "3-6",
            "Architecture & Furniture": "3-6",
            "Architecture & Furniture & Installations": "4-9",
            "Complex Installations": "10-22"
        },
        "Exterior": {
            "Architecture": "1-2",
            "Architecture & Installations": "1-3",
            "Architecture & Furniture": "4-6",
            "Architecture & Furniture & Installations": "3-8",
            "Site": "1-2"
        }
    },
    "301-400": {
        "Interior": {
            "Architecture": "3-6",
            "Architecture & Installations": "3-8",
            "Architecture & Furniture": "3-8",
            "Architecture & Furniture & Installations": "4-9",
            "Complex Installations": "13-25"
        },
        "Exterior": {
            "Architecture": "1-2",
            "Architecture & Installations": "1-3",
            "Architecture & Furniture": "4-6",
            "Architecture & Furniture & Installations": "3-8",
            "Site": "1-2"
        }
    },
    "401-500": {
        "Interior": {
            "Architecture": "4-7",
            "Architecture & Installations": "4-9",
            "Architecture & Furniture": "4-9",
            "Architecture & Furniture & Installations": "4-9",
            "Complex Installations": "13-25"
        },
        "Exterior": {
            "Architecture": "1-2",
            "Architecture & Installations": "2-4",
            "Architecture & Furniture": "4-7",
            "Architecture & Furniture & Installations": "3-9",
            "Site": "1-2"
        }
    },
    "501-600": {
        "Interior": {
            "Architecture": "4-7",
            "Architecture & Installations": "4-9",
            "Architecture & Furniture": "4-10",
            "Architecture & Furniture & Installations": "5-11",
            "Complex Installations": "16-28"
        },
        "Exterior": {
            "Architecture": "1-3",
            "Architecture & Installations": "2-4",
            "Architecture & Furniture": "5-8",
            "Architecture & Furniture & Installations": "3-11",
            "Site": "1-2"
        }
    },
    "601-700": {
        "Interior": {
            "Architecture": "4-7",
            "Architecture & Installations": "4-9",
            "Architecture & Furniture": "5-11",
            "Architecture & Furniture & Installations": "5-11",
            "Complex Installations": "17-29"
        },
        "Exterior": {
            "Architecture": "2-4",
            "Architecture & Installations": "2-4",
            "Architecture & Furniture": "5-8",
            "Architecture & Furniture & Installations": "3-11",
            "Site": "1-2"
        }
    },
    "701-800": {
        "Interior": {
            "Architecture": "4-7",
            "Architecture & Installations": "5-10",
            "Architecture & Furniture": "5-11",
            "Architecture & Furniture & Installations": "6-12",
            "Complex Installations": "18-30"
        },
        "Exterior": {
            "Architecture": "2-4",
            "Architecture & Installations": "2-4",
            "Architecture & Furniture": "5-8",
            "Architecture & Furniture & Installations": "4-12",
            "Site": "1-2"
        }
    },
    "801-900": {
        "Interior": {
            "Architecture": "4-9",
            "Architecture & Installations": "5-10",
            "Architecture & Furniture": "5-11",
            "Architecture & Furniture & Installations": "6-12",
            "Complex Installations": "18-30"
        },
        "Exterior": {
            "Architecture": "2-4",
            "Architecture & Installations": "2-4",
            "Architecture & Furniture": "5-8",
            "Architecture & Furniture & Installations": "4-12",
            "Site": "1-2"
        }
    },
    "901-1000": {
        "Interior": {
            "Architecture": "4-9",
            "Architecture & Installations": "5-10",
            "Architecture & Furniture": "5-11",
            "Architecture & Furniture & Installations": "6-12",
            "Complex Installations": "20-32"
        },
        "Exterior": {
            "Architecture": "2-4",
            "Architecture & Installations": "2-4",
            "Architecture & Furniture": "6-9",
            "Architecture & Furniture & Installations": "4-12",
            "Site": "1-2"
        }
    },
    "1001-1500": {
        "Interior": {
            "Architecture": "4-12",
            "Architecture & Installations": "5-13",
            "Architecture & Furniture": "5-13",
            "Architecture & Furniture & Installations": "6-14",
            "Complex Installations": "22-34"
        },
        "Exterior": {
            "Architecture": "2-4",
            "Architecture & Installations": "2-5",
            "Architecture & Furniture": "6-11",
            "Architecture & Furniture & Installations": "4-13",
            "Site": "1-2"
        }
    },
    "1501-2000": {
        "Interior": {
            "Architecture": "4-13",
            "Architecture & Installations": "5-14",
            "Architecture & Furniture": "5-14",
            "Architecture & Furniture & Installations": "6-15",
            "Complex Installations": "22-34"
        },
        "Exterior": {
            "Architecture": "2-4",
            "Architecture & Installations": "2-5",
            "Architecture & Furniture": "6-11",
            "Architecture & Furniture & Installations": "4-13",
            "Site": "1-2"
        }
    },
    "2001-2500": {
        "Interior": {
            "Architecture": "4-13",
            "Architecture & Installations": "5-14",
            "Architecture & Furniture": "5-14",
            "Architecture & Furniture & Installations": "7-16",
            "Complex Installations": "22-34"
        },
        "Exterior": {
            "Architecture": "2-4",
            "Architecture & Installations": "2-7",
            "Architecture & Furniture": "6-11",
            "Architecture & Furniture & Installations": "4-13",
            "Site": "1-2"
        }
    },
    "2501-3000": {
        "Interior": {
            "Architecture": "5-14",
            "Architecture & Installations": "7-16",
            "Architecture & Furniture": "7-16",
            "Architecture & Furniture & Installations": "10-19",
            "Complex Installations": "22-34"
        },
        "Exterior": {
            "Architecture": "2-4",
            "Architecture & Installations": "3-8",
            "Architecture & Furniture": "8-13",
            "Architecture & Furniture & Installations": "5-14",
            "Site": "1-2"
        }
    },
    "3001-3500": {
        "Interior": {
            "Architecture": "5-16",
            "Architecture & Installations": "8-17",
            "Architecture & Furniture": "8-17",
            "Architecture & Furniture & Installations": "10-19",
            "Complex Installations": "22-34"
        },
        "Exterior": {
            "Architecture": "2-4",
            "Architecture & Installations": "3-8",
            "Architecture & Furniture": "9-14",
            "Architecture & Furniture & Installations": "6-15",
            "Site": "1-2"
        }
    },
    "3501-4000": {
        "Interior": {
            "Architecture": "8-19",
            "Architecture & Installations": "9-18",
            "Architecture & Furniture": "9-18",
            "Architecture & Furniture & Installations": "10-19",
            "Complex Installations": "24-36"
        },
        "Exterior": {
            "Architecture": "3-5",
            "Architecture & Installations": "4-9",
            "Architecture & Furniture": "11-16",
            "Architecture & Furniture & Installations": "7-16",
            "Site": "2-3"
        }
    },
    "4001-4500": {
        "Interior": {
            "Architecture": "8-19",
            "Architecture & Installations": "9-18",
            "Architecture & Furniture": "9-18",
            "Architecture & Furniture & Installations": "10-19",
            "Complex Installations": "24-36"
        },
        "Exterior": {
            "Architecture": "3-5",
            "Architecture & Installations": "4-9",
            "Architecture & Furniture": "11-16",
            "Architecture & Furniture & Installations": "7-16",
            "Site": "2-3"
        }
    },
    "4501-5000": {
        "Interior": {
            "Architecture": "8-19",
            "Architecture & Installations": "9-18",
            "Architecture & Furniture": "9-18",
            "Architecture & Furniture & Installations": "11-20",
            "Complex Installations": "24-42"
        },
        "Exterior": {
            "Architecture": "3-5",
            "Architecture & Installations": "4-9",
            "Architecture & Furniture": "11-16",
            "Architecture & Furniture & Installations": "7-16",
            "Site": "2-4"
        }
    },
    "5001-6250": {
        "Interior": {
            "Architecture": "9-20",
            "Architecture & Installations": "10-19",
            "Architecture & Furniture": "10-19",
            "Architecture & Furniture & Installations": "12-21",
            "Complex Installations": "29-47"
        },
        "Exterior": {
            "Architecture": "3-5",
            "Architecture & Installations": "5-10",
            "Architecture & Furniture": "12-17",
            "Architecture & Furniture & Installations": "8-17",
            "Site": "2-4"
        }
    },
    "6251-7500": {
        "Interior": {
            "Architecture": "9-20",
            "Architecture & Installations": "10-21",
            "Architecture & Furniture": "10-21",
            "Architecture & Furniture & Installations": "12-21",
            "Complex Installations": "29-47"
        },
        "Exterior": {
            "Architecture": "3-6",
            "Architecture & Installations": "5-10",
            "Architecture & Furniture": "12-17",
            "Architecture & Furniture & Installations": "8-17",
            "Site": "2-4"
        }
    },
    "7501-8750": {
        "Interior": {
            "Architecture": "10-22",
            "Architecture & Installations": "11-23",
            "Architecture & Furniture": "11-23",
            "Architecture & Furniture & Installations": "13-24",
            "Complex Installations": "31-49"
        },
        "Exterior": {
            "Architecture": "3-6",
            "Architecture & Installations": "5-10",
            "Architecture & Furniture": "13-18",
            "Architecture & Furniture & Installations": "8-17",
            "Site": "2-4"
        }
    },
    "8751-10000": {
        "Interior": {
            "Architecture": "10-24",
            "Architecture & Installations": "11-25",
            "Architecture & Furniture": "11-25",
            "Architecture & Furniture & Installations": "13-25",
            "Complex Installations": "31-49"
        },
        "Exterior": {
            "Architecture": "3-6",
            "Architecture & Installations": "5-10",
            "Architecture & Furniture": "13-18",
            "Architecture & Furniture & Installations": "8-17",
            "Site": "2-4"
        }
    },
    "10001-12500": {
        "Interior": {
            "Architecture": "10-24",
            "Architecture & Installations": "11-25",
            "Architecture & Furniture": "11-25",
            "Architecture & Furniture & Installations": "13-25",
            "Complex Installations": "33-51"
        },
        "Exterior": {
            "Architecture": "3-6",
            "Architecture & Installations": "6-10",
            "Architecture & Furniture": "12-17",
            "Architecture & Furniture & Installations": "8-19",
            "Site": "2-4"
        }
    },
    "12501-15000": {
        "Interior": {
            "Architecture": "12-26",
            "Architecture & Installations": "13-27",
            "Architecture & Furniture": "13-27",
            "Architecture & Furniture & Installations": "16-28",
            "Complex Installations": "35-53"
        },
        "Exterior": {
            "Architecture": "4-7",
            "Architecture & Installations": "6-11",
            "Architecture & Furniture": "12-17",
            "Architecture & Furniture & Installations": "10-21",
            "Site": "2-4"
        }
    },
    "15001-17500": {
        "Interior": {
            "Architecture": "15-29",
            "Architecture & Installations": "16-31",
            "Architecture & Furniture": "16-31",
            "Architecture & Furniture & Installations": "19-31",
            "Complex Installations": "37-55"
        },
        "Exterior": {
            "Architecture": "5-8",
            "Architecture & Installations": "7-13",
            "Architecture & Furniture": "12-23",
            "Architecture & Furniture & Installations": "12-26",
            "Site": "3-5"
        }
    },
    "17501-20000": {
        "Interior": {
            "Architecture": "19-33",
            "Architecture & Installations": "19-34",
            "Architecture & Furniture": "19-34",
            "Architecture & Furniture & Installations": "22-34",
            "Complex Installations": "39-57"
        },
        "Exterior": {
            "Architecture": "6-9",
            "Architecture & Installations": "9-15",
            "Architecture & Furniture": "12-24",
            "Architecture & Furniture & Installations": "14-28",
            "Site": "3-5"
        }
    },
    "20001-22500": {
        "Interior": {
            "Architecture": "22-36",
            "Architecture & Installations": "22-40",
            "Architecture & Furniture": "22-40",
            "Architecture & Furniture & Installations": "25-46",
            "Complex Installations": "45-63"
        },
        "Exterior": {
            "Architecture": "7-10",
            "Architecture & Installations": "10-16",
            "Architecture & Furniture": "12-26",
            "Architecture & Furniture & Installations": "17-31",
            "Site": "4-6"
        }
    },
    "22501-25000": {
        "Interior": {
            "Architecture": "25-39",
            "Architecture & Installations": "25-43",
            "Architecture & Furniture": "25-43",
            "Architecture & Furniture & Installations": "28-49",
            "Complex Installations": "49-67"
        },
        "Exterior": {
            "Architecture": "8-11",
            "Architecture & Installations": "12-18",
            "Architecture & Furniture": "12-30",
            "Architecture & Furniture & Installations": "20-34",
            "Site": "4-6"
        }
    },
    "25001-27500": {
        "Interior": {
            "Architecture": "29-43",
            "Architecture & Installations": "29-47",
            "Architecture & Furniture": "29-47",
            "Architecture & Furniture & Installations": "32-53",
            "Complex Installations": "53-71"
        },
        "Exterior": {
            "Architecture": "9-12",
            "Architecture & Installations": "14-20",
            "Architecture & Furniture": "14-32",
            "Architecture & Furniture & Installations": "23-37",
            "Site": "5-7"
        }
    },
    "27501-30000": {
        "Interior": {
            "Architecture": "30-43",
            "Architecture & Installations": "33-51",
            "Architecture & Furniture": "33-51",
            "Architecture & Furniture & Installations": "36-57",
            "Complex Installations": "57-75"
        },
        "Exterior": {
            "Architecture": "10-13",
            "Architecture & Installations": "15-21",
            "Architecture & Furniture": "16-36",
            "Architecture & Furniture & Installations": "26-40",
            "Site": "5-7"
        }
    }
};

