// ThankYou.jsx
import React from 'react';
import { Container, Typography, Button, Box, Card, CardContent } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslation } from 'react-i18next';

const ThankYou = () => {
  const { t } = useTranslation();

  const handleHomeClick = () => {
    // Logic to redirect to the home page
    window.location.href = '/orders/scan';
  };

  return (
    <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
      <Card style={{ textAlign: 'center', height: 400, padding: '60px', borderRadius: '15px' }}>
        <CardContent>
          <CheckCircleIcon style={{ fontSize: '8rem', color: '#4caf50' }} />
          <Typography variant="h4" gutterBottom>
            {t('thankYou.title')}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {t('thankYou.subtitle')}
          </Typography>
          <Box mt={2}>
            <Button
              variant="contained"
              color="inherit"
              sx={{
                backgroundColor: 'white',
                width: '120px',
                padding: 1.4,
                color: '#163D6C',
                border: '1px solid #163D6C',
                transition: 'background-color 0.3s ease, color 0.3s ease',
                '&:hover': {
                  backgroundColor: '#163D6C',
                  color: 'white',
                }
              }}
              onClick={handleHomeClick}
            >
              <Typography sx={{ textTransform: 'none' }}>
                {t('thankYou.button')}
              </Typography>
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
};

export default ThankYou;
