import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Container,
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import toast from "react-hot-toast";
import { subscribCheckout, getCurrrentUser } from "../../API/apiService";
import { useEffect, useState } from "react";
import { getUserActiveSubscriptions } from "../../API/apiService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SubscriptionOptions = () => {
  const { t } = useTranslation();
  const [activeSubscription, setActiveSubscriptions] = useState([]);
  const navigate = useNavigate();

  const fetchSubscriptions = async () => {
    try {
      const currentUser = getCurrrentUser();
      if (currentUser === null) return;
      const response = await getUserActiveSubscriptions();
      setActiveSubscriptions(response.data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    fetchSubscriptions();
    if (query.get("success")) {
      toast.success(t('subscription.successMessage'), { duration: 6000 });
    } else if (query.get("canceled")) {
      //toast.error(t('subscription.errorMessage'));
    }
  }, [t]);

  const isScanPlanActive = () => {
    if (!activeSubscription) return false;
    const isActive = activeSubscription?.filter((s) => s.type === "scan");
    return isActive?.length > 0 && isActive[0].isActive;
  };

  const isDigitalPlanActive = () => {
    if (!activeSubscription) return false;
    const isActive = activeSubscription?.filter((s) => s.type === "digital");
    return isActive?.length > 0 && isActive[0].isActive;
  };

  const handleSubscribe = async (e, subsType) => {
    e.preventDefault();
    if (!subsType) {
      toast.error(t('subscription.refreshError'));
      return;
    }
    const userLogged = getCurrrentUser();
    if (userLogged === null) {
      toast.error(t('subscription.loginError'));
      navigate('/login');
      return;
    }

    try {
      const payload = {
        userId: getCurrrentUser()["id"],
        serviceType: subsType,
      };
      const response = await subscribCheckout(payload);
      if (response.success) {
        window.location.href = response.data;
      }
    } catch (error) {
    }
  };

  const SubscriptionCard = ({ title, price, details, subsType, isActive = false }) => (
    <Card
      variant="outlined"
      sx={{
        maxWidth: "100%",
        boxShadow: 4,
        borderRadius: 3,
        m: 2,
        p: { xs: 2, sm: 5 },
        boxSizing: "border-box",
      }}
    >
      <CardContent>
        <Typography
          variant="h5"
          component="div"
          gutterBottom
          sx={{
            wordWrap: "break-word",
            textAlign: "center",
            overflowWrap: "break-word",
          }}
        >
          {title}
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
          <MonetizationOnIcon sx={{ mt: 2 }} color="primary" fontSize="large" />
          <Typography
            variant="h4"
            color="primary"
            sx={{ ml: 1, wordWrap: "break-word", overflowWrap: "break-word"}}
          >
            ${price}/month
          </Typography>
        </Box>
        <List sx={{ paddingRight: { xs: 0, sm: 2 }, textAlign: "start" }}>
          {details.map((detail, index) => (
            <ListItem
              key={index}
              disableGutters
              sx={{ display: "flex", alignItems: "flex-start" }}
            >
              <ListItemIcon>
                <CheckCircleIcon sx={{ mt: 1, ml: -0 }} color="primary" />
              </ListItemIcon>
              <ListItemText
                primary={detail}
                sx={{                 
                  paddingRight: {xs:20, md:10, lg:0},
                  textAlign: "start",
                  marginLeft:'-15px',
                  marginTop:0.5,
                }}
              />
            </ListItem>
          ))}
        </List>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          sx={{ mt: 2 }}
          onClick={(e) => handleSubscribe(e, subsType)}
        >
          {isActive ? t('subscription.activePlan') : t('subscription.subscribeNow')}
        </Button>
      </CardContent>
    </Card>
  );

  return (
    <Container sx={{ mt: { xs: 10, sm: 20 }, pb: { xs: 10, sm: 0 },  minHeight:'90vh', }}>
      <Typography
        color="#163D6C"
        variant="h4"
        component="h1"
        gutterBottom
        align="center"
      >
        {t('subscription.title')}
      </Typography>
      <Grid container alignItems="center" justifyContent="center" spacing={4}>
        <Grid item xs={12} sm={8} md={6}>
          <SubscriptionCard
            title={t('subscription.scanningTitle')}
            price="4"
            subsType="scan"
            isActive={isScanPlanActive()}
            details={[
              t('subscription.scanningDetail1'),
              t('subscription.scanningDetail2'),
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={6}>
          <SubscriptionCard
            title={t('subscription.digitalTitle')}
            price="1"
            subsType="digital"
            isActive={isDigitalPlanActive()}
            details={[
              t('subscription.digitalDetail1'),
              t('subscription.digitalDetail2'),
            ]}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default SubscriptionOptions;
