import { pricingScan } from "../Models/pricingScan";
import { pricingDigital } from "../Models/pricingDigital";
import { workingDays } from "../Models/workingDays";


export const getRegionTypePrice = (region) => {
  if (region === "Tirane" || region === "Peje") {
    return 55;
  } else if (
    region === "Elbasan" ||
    region === "Durres" ||
    region === "Gjakove" ||
    region === "Mitrovice"
  ) {
    return 95;
  } else if (
    region === "Vlore" ||
    region === "Korce" ||
    region === "Diber" ||
    region === "Kukes" ||
    region === "Shkoder" ||
    region === "Fier" ||
    region === "Lezhe" ||
    region === "Berat" ||
    region === "Gjirokaster" ||
    region === "Ferizaj" ||
    region === "Gjilan" ||
    region === "Prishtine" ||
    region === "Prizren"
  ) {
    return 140;
  }
};

export const getPriceBasedOnSiteSize = (siteSize, propertyType) => {
    let price = 0;
   
    if((isNaN(siteSize) || siteSize <= 0 || siteSize === '')){
     
        return price;
    }

  if (propertyType === "residence") {
    price += siteSize * pricingScan.Site.Residentiale;

  } else if (propertyType === "commercial") {
    price += siteSize * pricingScan.Site.Commerciale;

  } else if (propertyType === "institutional") {
    price += siteSize * pricingScan.Site.Institucionale;

  } else if (propertyType === "industrial") {
    price += siteSize * pricingScan.Site.Industriale;
  }
  return price;
};

export const getPiceBasedOnPopertyAndScanType = (
  spaceSize,
  propertyType,
  scanTypes
) => {
  let price = 0;
  propertyType = propertyType.name;
  if (propertyType === "residence") {
    scanTypes.forEach((scanType) => {
      if (scanType.name === "interior") {
        price += spaceSize * pricingScan.Interior.Residentiale;
      } else if (scanType.name === "exterior") {
        price += spaceSize * pricingScan.Exterior.Residentiale;
      } 
    //   else if (scanType.name === "site") {
    //     price += spaceSize * pricingScan.Site.Residentiale;
    //   }
    });
  } else if (propertyType === "commercial") {
    scanTypes.forEach((scanType) => {
      if (scanType.name === "interior") {
        price += spaceSize * pricingScan.Interior.Commerciale;
      } else if (scanType.name === "exterior") {
        price += spaceSize * pricingScan.Exterior.Commerciale;
      } 
    //   else if (scanType.name === "site") {
    //     price += spaceSize * pricingScan.Site.Commerciale;
    //   }
    });
  } else if (propertyType === "institutional") {
    scanTypes.forEach((scanType) => {
      if (scanType.name === "interior") {
        price += spaceSize * pricingScan.Interior.Institucionale;
      } else if (scanType.name === "exterior") {
        price += spaceSize * pricingScan.Exterior.Institucionale;
      } 
    //   else if (scanType.name === "site") {
    //     price += spaceSize * pricingScan.Site.Institucionale;
    //   }
    });
  } else if (propertyType === "industrial") {
    scanTypes.forEach((scanType) => {
      if (scanType.name === "interior") {
        price += spaceSize * pricingScan.Interior.Industriale;
      } else if (scanType.name === "exterior") {
        price += spaceSize * pricingScan.Exterior.Industriale;
      } 
    //   else if (scanType.name === "site") {
    //     price += spaceSize * pricingScan.Site.Industriale;
    //   }
    });
  }
  return price;
};

export const getTotalPrice = (
  regionPrice,
  propertyAndScanPrice,
  sitePrice,
  scanFiles,
  googleMap
) => {
  let total = 0;
  const scanFilesPrice = scanFiles ? 95 : 0;
  const googleMapPrice = googleMap ? 17 : 0;

  total =
    regionPrice +
    propertyAndScanPrice +
    sitePrice +
    scanFilesPrice +
    googleMapPrice;
  return parseFloat(total.toFixed(2));
};

//calculate price digital


const filterDifferentTypeFromArchitecture = (scope) => {
  return scope.find((obj) => obj.type !== "architecture");
};

const getSizeRangeForWorkingDays = (size, unit) => {
  if (unit === "imperial") {
    size = size / 10;
  }
  // Get only the integer part of the size
  size = Math.floor(size);

  const ranges = Object.keys(workingDays);
  for (const range of ranges) {
    const [min, max] = range.split("-").map(Number);
    if (size >= min && size <= max) {
      return range;
    }
  }
  return false;
};

export const getSizeRange = (size, unit) => {
  if (unit === "metric") {
    size = size * 10;
  }
  if (size > 300000) {
    return false;
  }

  // Get the range keys from the data
  const rangeKeys = Object.keys(pricingDigital["For Clients"]["Price"]);

  // Find the appropriate range
  for (let i = 0; i < rangeKeys.length; i++) {
    // Remove the " SF" suffix and split the range into min and max
    let [min, max] = rangeKeys[i].replace(" SF", "").split("-").map(Number);
    if (size >= min && size <= max) {
      return `${min}-${max} SF`;
    }
  }

  // If the size is greater than the largest range, return a message
  return "Size exceeds defined ranges";
};

const getScopeTypeForWD = (scope) => {
  const mapScopeType = {
    architecture: "Architecture",
    furniture: "Furniture",
    installations: "Installations",
  };

  if (scope.length === 1) {
    return "Architecture";
  } else if (scope.length === 2) {
    const interiorScope2 = filterDifferentTypeFromArchitecture(scope);
    return `Architecture & ${mapScopeType[interiorScope2.type]}`;
  } else if (scope.length === 3) {
    return "Architecture & Furniture & Installations";
  }
};

const getScopeType = (scope) => {
  const mapScopeType = {
    architecture: "ARCH",
    furniture: "FURNITURE",
    installations: "MEP",
  };

  if (scope.length === 1) {
    return "ARCH";
  } else if (scope.length === 2) {
    const interiorScope2 = filterDifferentTypeFromArchitecture(scope);
    return `ARCH & ${mapScopeType[interiorScope2.type]}`;
  } else if (scope.length === 3) {
    return "ARCH & FURNITURE & MEP";
  }
};

const capitalizeFirstChar = (str) => {
  if (!str) return str; // Return if the string is empty or undefined
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getTotalPriceDigital = (
  spaceSize,
  unit,
  property,
  interiorScope,
  exteriorScope,
  siteSize
) => {
  let total = 0;

  const propName = capitalizeFirstChar(property[0].type);
  const scopeInteriorType = getScopeType(interiorScope);

  // interiorPrice = (Standart/Industrial) - SizeRange - interiorType - Price
  // exteriorPrice = (SizeRange) - ExteriorType - Price
  const sizeRange = getSizeRange(spaceSize, unit);
  if (sizeRange === false) return;

  let interiorPrice = 0;
  if(interiorScope && interiorScope.length > 0){
     if (propName === "Standart") {
    interiorPrice =
      pricingDigital["For Clients"]["Price"][sizeRange]["Interior"][
        scopeInteriorType
      ]["Standart"];

  } else {
    interiorPrice =
      pricingDigital["For Clients"]["Price"][sizeRange]["Interior"][
        scopeInteriorType
      ]["Industrial"];
  }

  }
 

  let exteriorPrice = 0;
  if (exteriorScope && exteriorScope.length > 0) {
    const scopeExteriorType = getScopeType(exteriorScope);

    exteriorPrice =
      pricingDigital["For Clients"]["Price"][sizeRange]["Exterior"][
        scopeExteriorType
      ]["Standart"];

  }

  let sitePrice = 0;

  if (siteSize > 0) {
    const sizeRangeForSite = getSizeRange(siteSize, unit);

    if (sizeRangeForSite !== false) {
      sitePrice =
        pricingDigital["For Clients"]["Price"][sizeRangeForSite]["Exterior"][
          "SITE"
        ]["Standart"];

    }
  }

  total = interiorPrice + exteriorPrice + sitePrice;

  return parseFloat(total.toFixed(2));
};

export const getWokringDaysRequired = (
  size,
  property,
  interiorScope,
  exteriorScope,
  siteSize,
  unit
) => {
  const totalWD = [];
  const sizeRange = getSizeRangeForWorkingDays(size, unit);
  if (sizeRange === false) return;
  const propName = capitalizeFirstChar(property[0].type);

  const intScope = getScopeTypeForWD(interiorScope);

  let exterioWD = "";
  let interiorWD = "";
  if (propName === "Standart") {
    interiorWD = workingDays[sizeRange]["Interior"][intScope];
  } else {
    interiorWD = workingDays[sizeRange]["Interior"]["Complex Installations"];
  }

  if (exteriorScope.length > 0) {
    const extScope = getScopeTypeForWD(exteriorScope);

    exterioWD = workingDays[sizeRange]["Exterior"][extScope];
  }

  let siteWD = "";

  if (siteSize > 0) {
    const siteSizeRange = getSizeRangeForWorkingDays(siteSize, unit);
 
    if (siteSizeRange !== false) {
      siteWD = workingDays[siteSizeRange]["Exterior"]["Site"];
    
    }
  }
  totalWD.push(interiorWD, exterioWD, siteWD);

  return totalWD;
};

export const getCurrentLanguage = () => {
  const language = localStorage.getItem("language");

  if (language) return language;
  return "en";
};


