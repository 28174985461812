import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import KeyIcon from '../../../assets/Icons/keyIcon.png';

const PasswordField = ({
    label,
    fieldName,
    parent,
    validator,
    onChange,
    compareValue,
    errorForm
  }) => {
    const [error, setError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
  
    const handleChange = (e) => {
        const newValue = e.target.value;
        let errorMessage = '';
   if (fieldName === 'confirmPassword') {
    errorMessage = validator(newValue, compareValue)
        } else {
          errorMessage = validator(newValue);
        }
        setError(errorMessage);
        onChange(e, parent, fieldName);
      };
    
  
    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
  
    return (
      <FormControl
        variant="outlined"
        fullWidth
        
        size='small'
        sx={{
          marginBottom: 2,
          '& .MuiOutlinedInput-root': {
            borderRadius: '12px',
            fontSize: '12px',
            padding: '6px',
            paddingRight: '20px'
          },
          '& .MuiInputLabel-root': {
            fontSize: '11px',
            fontWeight: '600',
            fontFamily: 'Roboto, sans-serif',
            padding: '6px',
            marginLeft: '-3px',
          },
          width: { xs: '300px', md: '400px' },
        }}
      >
        <OutlinedInput
          id={fieldName}
          type={showPassword ? 'text' : 'password'}
          onChange={handleChange}
          placeholder={label}
          error={!!error}
          startAdornment={
            <InputAdornment position="start">
              <img style={{ marginLeft: '10px', width: '15px' }} 
              src={KeyIcon} alt='key-icon' />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                sx={{marginTop:2}}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          sx={{
            '& .MuiOutlinedInput-input::placeholder': {
              color: 'black', // Change this to your desired color
              fontWeight: '500'
            },
          }}
        />
        {error  && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    );
  };
  
  export default PasswordField;