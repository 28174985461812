import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Grid,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  getAdminsAsync,
  createAdminsAsync,
  manageAdminStatusAsync,
  fetchUserRole
} from "../../../API/apiService";
import toast from "react-hot-toast";


const AdminManagement = () => {
  const [admins, setAdmins] = useState([]);
  const [adminInsert, setAdminInsert] = useState(false);
  const [newAdmin, setNewAdmin] = useState({
    name: "",
    surname: "",
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const userRoles = fetchUserRole();
  useEffect(() => {
    const toastId = toast.loading("...Loading");
    if(userRoles.includes('Superadmin')){
    //  toast.error(userRoles)
    }
    const getAdmins = async () => {
      try {
        const response = await getAdminsAsync();
        setAdmins(response.data);
      } catch (error) {
        toast.error("Something went wrong.");
      } finally {
        toast.dismiss(toastId);
      }
    };

    getAdmins();
  }, [adminInsert]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAdmin((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const createAdmin = async () => {
    try {
      const payload = {
        name: newAdmin.name,
        surname: newAdmin.surname,
        email: newAdmin.email,
        password: newAdmin.password,
      };
      const response = await createAdminsAsync(payload);
      return response;
    } catch (error) {
      throw error;
    } finally {
      setAdminInsert(!adminInsert);
      setNewAdmin({ name: "", surname: "", email: "", password: "" });
    }
  };

  const handleCreateAdmin = () => {
    if (
      newAdmin.name &&
      newAdmin.surname &&
      newAdmin.email &&
      newAdmin.password
    ) {
      var response = createAdmin();

      toast.promise(
        response, // Ensure this returns a promise
        {
          loading: "Creating new admin...",
          success: "Admin created successfully!",
          error: "Admin creation failed. Please try again.",
        },
        {
          success: {
            duration: 5000,
          },
        }
      );
    } else {
      toast.error("Please enter all required fields");
    }
  };

  const manageAdminStatus = async (selectedAdmin) => {
    try {
      const payload = {
        userId: selectedAdmin.id,
        isActive: !selectedAdmin.isActive,
      };
      var response = await manageAdminStatusAsync(payload);
      return response;
    } catch (error) {
      throw error;
    } finally {
      setAdminInsert(!adminInsert);
    }
  };

  const handleToggleActive = (selectedAdmin) => {
    var response = manageAdminStatus(selectedAdmin);
    toast.promise(
      response, 
      {
        loading: "Changing status...",
        success: "Admin status changed successfully!",
        error: "Admin status change failed. Please try again.",
      },
      {
        success: {
          duration: 5000,
        },
      }
    );
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Admin Management
      </Typography>

      {/* Admin Creation Form */}
      <Paper elevation={3} style={{ padding: "20px", marginBottom: "20px" }}>
        <Typography variant="h6" gutterBottom>
          Create Admin User
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Name"
              name="name"
              fullWidth
              InputLabelProps={{
                sx: {
                  marginLeft: 0,
                },
              }}
              value={newAdmin.name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Surname"
              name="surname"
              fullWidth
              InputLabelProps={{
                sx: {
                  marginLeft: 0,
                },
              }}
              value={newAdmin.surname}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email"
              name="email"
              type="email"
              fullWidth
              InputLabelProps={{
                sx: {
                  marginLeft: 0,
                },
              }}
              value={newAdmin.email}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Password"
              name="password"
              type={showPassword ? "text" : "password"}
              fullWidth
              InputLabelProps={{
                sx: {
                  marginLeft: 0,
                },
              }}
              value={newAdmin.password}
              onChange={handleInputChange}
              InputProps={{  
                endAdornment: (
                  <InputAdornment position="end"
                  sx={{mt:2}}
                  >
                    
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleCreateAdmin}
            >
              Create Admin
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {/* Admin Users Grid */}
      <Typography variant="h6" gutterBottom>
        Admin Users
      </Typography>
      <TableContainer component={Paper} 
      style={{ maxHeight: 400, overflowY: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Surname</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Active</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {admins?.map((admin) => (
              <TableRow key={admin.id}>
                <TableCell>{admin.name}</TableCell>
                <TableCell>{admin.surname}</TableCell>
                <TableCell>{admin.email}</TableCell>
                <TableCell>
                  <Switch
                    checked={admin.isActive}
                    onChange={() => handleToggleActive(admin)}
                    color="primary"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default AdminManagement;
