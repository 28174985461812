import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { Edit, Visibility, VisibilityOff } from "@mui/icons-material";
import UserAvatar from "../../assets/Images/UserAvatar.png";
import {
  getCurrrentUser,
  changeEmail,
  changePassword,
  getCustomerBillingPortal,
  getUserActiveSubscriptions,
} from "../../API/apiService";
import {
  validatePassword,
  validateConfirmPassword,
} from "../../Validators/validator";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const UserProfile = () => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [selectedSection, setSelectedSection] = useState("profile");
  const [name, setName] = useState(getCurrrentUser()["firstName"]);
  const [surname, setSurname] = useState(getCurrrentUser()["lastName"]);
  const [email, setEmail] = useState(getCurrrentUser()["email"]);
  const [changeEmailField, setChhangeEmailField] = useState(
    getCurrrentUser()["email"]
  );
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailConfirmSent, setEmilConfirmSent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [activeSubscription, setActiveSubscriptions] = useState([]);

  const navigate = useNavigate();
  const validateName = (name) => /^[A-Za-z]+$/.test(name);
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  //const validatePass= validatePassword()


  useEffect(() => {
 
    if (getCurrrentUser() === null) {
      navigate("/");
      return;
    }
    const fetchSubscriptions = async () => {
      const response = await getUserActiveSubscriptions();
      setActiveSubscriptions(response.data);
    };

    fetchSubscriptions();
    setCurrentPassword("");
  }, []);

  const isScanPlanActive = () => {
    if (!activeSubscription) return false;
    var isActive = activeSubscription?.filter((s) => s.type === "scan");
    if (isActive?.length > 0 && isActive[0].isActive) return true;
    return false;
  };

  const isScanPlan = () => {
    if (!activeSubscription) return false;
    var exist = activeSubscription?.filter((s) => s.type === "scan");
    if(exist) return true
    return false;
  };

  const isDigitalPlanActive = () => {
    if (!activeSubscription) return false;
    var isActive = activeSubscription?.filter((s) => s.type === "digital");
    if (isActive?.length > 0 && isActive[0].isActive) return true;
    return false;
  };

  const isDigitalPlan = () => {
    if (!activeSubscription) return false;
    var exist = activeSubscription?.filter((s) => s.type === "digital");
    if (exist) return true;
    return false;
  };

  const handleChangeEmail = async () => {
    if (!validateEmail(changeEmailField)) {
      toast.error(t("userProfile.enterValidEmail"));
      return;
    }
    if (changeEmailField === email) {
      toast.error(t("userProfile.currentEmail"));
      return;
    }

    const changeEmailDto = {
      id: getCurrrentUser()["id"],
      newEmail: changeEmailField,
    };

    try {
      const emailChangeResponse = await changeEmail(changeEmailDto);
      if (emailChangeResponse.success) {
        toast.success(t("userProfile.emailSent"), { duration: 5000 });
        setEmilConfirmSent(true);
      } else {
        toast.error(emailChangeResponse.data);
      }
    } catch (error) {
      toast.error(t("userProfile.somethingWentWrong"));    }
  };

  const handleChangePassword = async () => {
    if (validatePassword(currentPassword)) {
      toast.error(t("userProfile.currentPasswordError") + validatePassword(currentPassword));      return;
    } else if (validatePassword(currentPassword)) {
      toast.error(t("userProfile.newPasswordError") + validatePassword(newPassword));      return;
    } else if (validateConfirmPassword(confirmPassword, newPassword)) {
      toast.error(t("userProfile.confirmPasswordError") + validateConfirmPassword(confirmPassword, newPassword));
      return;
    }

    const payload = {
      userId: getCurrrentUser()["id"],
      currentPassword: currentPassword,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    };
    try {
      var passwordChangeResponse = await changePassword(payload);
      if (passwordChangeResponse?.success) {
        localStorage.removeItem("userVirtunex");
        navigate("/password-confirmed");
      } else if (passwordChangeResponse.success === false) {
        toast.error(passwordChangeResponse.errorMessage);
      }
    } catch (error) {

    } finally {
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    }
  };

  const handleBillingPortal = async (type) => {
    if (type === "scan") {
      var scanPlan = activeSubscription?.filter((s) => s.type === "scan");
      const response = await getCustomerBillingPortal(scanPlan[0].customerId);
      window.location.href = response.data;
    } else if (type === "digital") {
      var digitalPlan = activeSubscription?.filter((s) => s.type === "digital");
      const response = await getCustomerBillingPortal(
        digitalPlan[0].customerId
      );

      window.location.href = response.data;
    }
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const renderContent = () => {
    switch (selectedSection) {
      case "profile":
        return (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Avatar
                src={UserAvatar}
                sx={{
                  width: 100,
                  height: 100,
                  mb: 2,
                  backgroundColor: "white",
                }}
              />
              <Typography variant="h5" gutterBottom>
                {t('userProfile.userProfile')}
              </Typography>
            </Box>
            <Grid marginTop={2} container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label={t('userProfile.name')}
                  variant="outlined"
                  InputLabelProps={{
                    sx: {
                      marginLeft: 0,
                    },
                  }}
                  value={name}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label={t('userProfile.surname')}
                  variant="outlined"
                  value={surname}
                  InputLabelProps={{
                    sx: {
                      marginLeft: 0,
                    },
                  }}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t('userProfile.email')}
                  variant="outlined"
                  value={email}
                  InputLabelProps={{
                    sx: {
                      marginLeft: 0,
                    },
                  }}
                  InputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>
          </>
        );
      case "change-email":
        return (
          <Box>
            <Typography variant="h6">{t('userProfile.changeEmail')}</Typography>
            <TextField
              fullWidth
              label={t('userProfile.newEmail')}
              variant="outlined"
              value={changeEmailField}
              InputLabelProps={{
                sx: {
                  marginLeft: 0,
                },
              }}
              onChange={(e) => setChhangeEmailField(e.target.value)}
              error={!validateEmail(changeEmailField)}
              helperText={
                !validateEmail(changeEmailField)
                  ? t('userProfile.invalidEmail')
                  : ""
              }
              sx={{ mt: 2 }}
            />
            {!emailConfirmSent ? (
              <Button
                disabled={!validateEmail(changeEmailField)}
                variant="contained"
                color="primary"
                onClick={handleChangeEmail}
                sx={{ mt: 2 }}
              >
                {t('userProfile.changeEmailButton')}
              </Button>
            ) : (
              <Box
                sx={{mt:2, display:'flex', gap:1}}
              >
                <Typography sx={{color:'grey'}}>{t('userProfile.didNotReceiveEmail')}</Typography>
                <Typography
                  sx={{color:'#1a477f', cursor:'pointer'}}
                  onClick={handleChangeEmail}
                >
                  {t('userProfile.resend')}
                </Typography>
              </Box>
            )}
          </Box>
        );
      case "change-password":
        return (
          <Box>
            <Typography variant="h6">{t('userProfile.changePassword')}</Typography>
            <TextField
              fullWidth
              label={t('userProfile.currentPassword')}
              variant="outlined"
              type={showPassword ? "text" : "password"}
              value={currentPassword}
              error={currentPassword ? validatePassword(currentPassword) : ""}
              helperText={
                currentPassword ? validatePassword(currentPassword) : ""
              }
              InputLabelProps={{
                sx: {
                  marginLeft: 0,
                },
              }}
              onChange={(e) => setCurrentPassword(e.target.value)}
              sx={{ mt: 2 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              label={t('userProfile.newPassword')}
              variant="outlined"
              type={showPassword ? "text" : "password"}
              value={newPassword}
              error={newPassword ? validatePassword(newPassword) : ""}
              helperText={newPassword ? validatePassword(newPassword) : ""}
              InputLabelProps={{
                sx: {
                  marginLeft: 0,
                },
              }}
              onChange={(e) => setNewPassword(e.target.value)}
              sx={{ mt: 2 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              label={t('userProfile.confirmNewPassword')}
              variant="outlined"
              type={showPassword ? "text" : "password"}
              value={confirmPassword}
              error={
                confirmPassword
                  ? validateConfirmPassword(confirmPassword, newPassword)
                  : ""
              }
              helperText={
                confirmPassword
                  ? validateConfirmPassword(confirmPassword, newPassword)
                  : ""
              }
              InputLabelProps={{
                sx: {
                  marginLeft: 0,
                },
              }}
              onChange={(e) => setConfirmPassword(e.target.value)}
              sx={{ mt: 2 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              onClick={handleChangePassword}
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
              {t('userProfile.changePasswordButton')}
            </Button>
          </Box>
        );
      case "manage-subscription":
        return (
          <Box>
            <Typography sx={{ mb: 2 }} variant="h6">
              {t('userProfile.manageSubscription')}
            </Typography>
            {activeSubscription.length === 0 ? (
              <>
                <Typography>{t('userProfile.noActiveSubscription')}</Typography>
                <Button
                  onClick={() => navigate("/subscription-options")}
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                >
                  {t('userProfile.startHere')}
                </Button>
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                {isScanPlan() ? (
                  <Button
                    variant="outlined"
                    onClick={() => handleBillingPortal("scan")}
                  >
                    {t('userProfile.manageScanPlan')} 
                  </Button>
               ) : null} 
                {isDigitalPlan() ? ( 
                  <Button
                    variant="outlined"
                    onClick={() => handleBillingPortal("digital")}
                  >
                    {t('userProfile.manageDigitalPlan')}
                  </Button>
                ) : null} 
              </Box>
            )}
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Container maxWidth="md" sx={{ height: "100vh", marginTop: "100px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection:{xs:'column', md:'row'},
          alignItems:{xs:'center', md:'flex-start'},
          gap:2,
          padding: 4,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 3,
        }}

      >
        {/* Sidebar */}
        <Box
          sx={{
            width: "200px",
            marginRight: 4,
            bgcolor: "background.default",
            borderRadius: 2,
            boxShadow: 1,

            "&:hover": {
              boxShadow: 2,
            },
          }}
        >
          <List sx={{ width: "200px" }}>
            <ListItem disablePadding>
              <ListItemButton onClick={() => setSelectedSection("profile")}>
                <ListItemText primary={t('userProfile.sidebar.profile')} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => setSelectedSection("change-email")}
              >
                <ListItemText primary={t('userProfile.sidebar.changeEmail')} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => setSelectedSection("change-password")}
              >
                <ListItemText primary={t('userProfile.sidebar.changePassword')} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => setSelectedSection("manage-subscription")}
              >
                <ListItemText primary={t('userProfile.sidebar.manageSubscription')} />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>

        {/* Main Content */}
        <Box
          sx={{
            flexGrow: 1,
            position: "relative",
            padding: 4,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 3,
          }}
        >
          {renderContent()}
        </Box>
      </Box>
    </Container>
  );
};

export default UserProfile;
