import React from 'react';
import { useTranslation } from 'react-i18next';
import HomeImg from '../../assets/Images/HomeImg.png';
import ScanBanner from '../../assets/Images/scanBanner.png';
import DigitalBanner from '../../assets/Images/digitalBanner.png';
import p1Img from '../../assets/Images/p1.png';
import p2Img from '../../assets/Images/p2.png';
import p3Img from '../../assets/Images/p3.png';
import p4Img from '../../assets/Images/p4.png';
import s1Img from '../../assets/Images/s1.png';
import s2Img from '../../assets/Images/s2.png';
import s3Img from '../../assets/Images/s3.png';
import s4Img from '../../assets/Images/s4.png';
import BannerTop from "../Resource/BannerTop";

export default function Home() {
  const { t } = useTranslation();

  return (
    <>
      <BannerTop />
      <div className='home-container'>
        <div className='home-cont1'>
          <div className='home-content1'>
            <div>
              <p><span className='gradient-text'><span>{t('home.digitalTwin')}</span></span></p>
              <p>{t('home.bannerText')}</p>
            </div>
            <div>
              <p className='tt'><span className='gradient-text'><span>{t('home.ourInnovation')}</span></span></p>
              <ul>
                <li>{t('home.predictPrices')}</li>
                <li>{t('home.standardProduct')}</li>
                <li>{t('home.aiIntegration')}</li>
                <li>{t('home.onlineAccess')}</li>
              </ul>
            </div>
          </div>
          <div>
            <img src={HomeImg} alt='home-img' />
          </div>
        </div>

        <div className='home-cont2'>
          <p className='gradient-text'><span>{t('home.whatWeOffer')}</span></p>
          <div className='content-in-background'>
            <div>
              <img src={ScanBanner} alt='scan-banner-image' />
              <p className='p-offer'>{t('home.propertyScanning')}</p>
              <p className='p-offer'>{t('home.scanningDescription')}</p>
            </div>
            <div>
              <img src={DigitalBanner} alt='digital-baner-img' />
              <p className='p-offer'>{t('home.propertyDigitalization')}</p>
              <p className='p-offer'>{t('home.digitalizationDescription')}</p>
            </div>
          </div>
        </div>

        <div className='home-cont3'>
          <p className='gradient-text'><span>{t('home.useCases')}</span></p>
          <img src={ScanBanner} alt='property-scan-img' />
          <p className='title-custom'>{t('home.propertyScanning')}</p>
          <div>
            <div className='home-card-cont'>
              <div className='card-content-home'>
                <img src={p1Img} alt='card-image' />
                <p>{t('home.realEstateAgencies')}</p>
                <p>{t('home.realEstateDescription')}</p>
              </div>
            </div>
            <div className='home-card-cont'>
              <div className='card-content-home'>
                <img src={p2Img} alt='card-image' />
                <p>{t('home.hotels')}</p>
                <p>{t('home.hotelsDescription')}</p>
              </div>
            </div>
            <div className='home-card-cont'>
              <div className='card-content-home'>
                <img src={p3Img} alt='card-image' />
                <p>{t('home.individuals')}</p>
                <p>{t('home.individualsDescription')}</p>
              </div>
            </div>
            <div className='home-card-cont'>
              <div className='card-content-home'>
                <img src={p4Img} alt='card-image' />
                <p>{t('home.business')}</p>
                <p>{t('home.businessDescription')}</p>
              </div>
            </div>
          </div>

          <img src={DigitalBanner} alt='property-scan-img' />
          <p className='title-custom'>{t('home.propertyDigitalization')}</p>
          <div className='home-card-top'>
            <div className='home-card-cont'>
              <div className='card-content-home'>
                <img src={s1Img} alt='card-image' />
                <p>{t('home.architectureEngineering')}</p>
                <p >{t('home.scanToBIM')}</p>
                <p style={{marginTop:'-16px'}}>{t('home.cadToBIM')}</p>
              </div>
            </div>
            <div className='home-card-cont'>
              <div className='card-content-home'>
                <img src={s2Img} alt='card-image' />
                <p>{t('home.individuals')}</p>
                <p>{t('home.individualsDescription')}</p>
              </div>
            </div>
            <div className='home-card-cont'>
              <div className='card-content-home'>
                <img src={s3Img} alt='card-image' />
                <p>{t('home.valuers')}</p>
                <p>{t('home.valuersDescription')}</p>
              </div>
            </div>
            <div className='home-card-cont'>
              <div className='card-content-home'>
                <img src={s4Img} alt='card-image' />
                <p>{t('home.constructionFirms')}</p>
                <p>{t('home.scanToBIMConstruction')}</p>
                {/* <p>{t('home.cadToBIMConstruction')}</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
