// Unauthorized.jsx
import React from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTranslation } from 'react-i18next';

const Unauthorized = () => {
  const { t } = useTranslation();

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
      }}
    >
      <Box
        sx={{
          mb: 3,
          p: 3,
          bgcolor: 'background.paper',
          boxShadow: 3,
          borderRadius: 2,
          textAlign: 'center',
        }}
      >
        <ErrorOutlineIcon color="error" sx={{ fontSize: 80, mb: 2 }} />
        <Typography variant="h4" component="h1" gutterBottom>
          {t('unauthorized.title')}
        </Typography>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          {t('unauthorized.message')}
        </Typography>
        <br />
        <Button
          component={Link}
          to='/'
          sx={{
            background: 'none',
            bgcolor: '',
            border: '1.5px solid #1a477f',
            borderRadius: '8px',
            width: 'auto',
            maxWidth: '250px',
            textTransform: 'none',
            color: '#1a477f',

            '&:hover': {
              background: 'none',
              bgcolor: '#1a477f',
              color: 'white',
            },
          }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 500,
              transition: 'color 0.3s ease',
              color: 'inherit',
            }}
          >
            {t('unauthorized.button')}
          </Typography>
        </Button>
      </Box>
    </Container>
  );
};

export default Unauthorized;
