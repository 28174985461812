import React, { useState, useEffect } from 'react';
import {
  Container,
  TextField,
  Button,
  Grid,
  Switch,
  FormControlLabel,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from '@mui/material';
import toast from 'react-hot-toast';
import { getCoupons, createCoupon, updateCouponStatus } from '../../../API/apiService';
import { GlobalContext } from "../../../GlobalContext/GlobalContext";
import { useContext } from "react";

const Coupons = () => {
  const [couponName, setCouponName] = useState('');
  const [couponPercentage, setCouponPercentage] = useState('');
  const [coupons, setCoupons] = useState([]);
  const [newCouponCreated, setNewCouponCreated] = useState(false);
  const {handleActiveTab } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true);
    toast.dismiss();
    const toastId = toast.loading('...Loading coupons')
    const fetchCoupons = async () => {
      try {
        const response = await getCoupons();

        if(response.success){
          setCoupons(response.data)
        }
      } catch (error) {

      }finally{
        setLoading(false)
        toast.dismiss(toastId)
      }

     
    } 
    fetchCoupons();
    handleActiveTab('coupons')
  }, [newCouponCreated])

  const handleCreateCoupon = async () => {

    if(Number(couponPercentage) === 0 ) return toast.error('Please enter a valid coupon percentage')
      if(Number(couponPercentage) >= 100 ) return toast.error("Coupon percentage should not be more than '100'")
    if (couponName && couponPercentage) {
      const newCoupon = {
        name: couponName,
        couponPercentage: couponPercentage,
        isActive: true,
      };
     // setCoupons([...coupons, newCoupon]);
     try {
      const response = await createCoupon(newCoupon);
      if(response.success) {
        setNewCouponCreated(!newCouponCreated);
        return toast.success('Coupon created')
      }
     } catch (error) {
     }finally{
      setCouponName('');
      setCouponPercentage('');
     }
      
    }
  };

  const handleToggleActive = async (index, isActive, id) => {
    const updateCoupon = {
      couponId: id,
      isActive: !isActive,
    };

    try {
      const response = await updateCouponStatus(updateCoupon);
      if(response.success) {
        setNewCouponCreated(!newCouponCreated);
        return toast.success('Coupon updated')
      }
     } catch (error) {
   
     }finally{

     }
      
    const updatedCoupons = coupons.map((coupon, i) =>
      i === index ? { ...coupon, isActive: !coupon.isActive } : coupon
    );
    setCoupons(updatedCoupons);
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px', marginTop:'40px' }}>
        <Typography variant="h6" gutterBottom>
          Create Coupon
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Coupon Name"
              variant="outlined"
              fullWidth
              value={couponName}
              InputLabelProps={{
                sx: {
                  marginLeft:0 
                },
              }}
              onChange={(e) => setCouponName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Coupon Percentage"
              variant="outlined"
              fullWidth
              value={couponPercentage}
              InputLabelProps={{
                sx: {
                  marginLeft:0 
                },
              }}
              onChange={(e) => setCouponPercentage(e.target.value)}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={2} style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              sx={{padding:1.7}}
              variant="contained"
              color="primary"
              onClick={handleCreateCoupon}
              fullWidth
            >
              Create
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <TableContainer component={Paper} style={{ maxHeight: 500, overflowY: 'auto' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Coupon Name</TableCell>
              <TableCell>Coupon Percentage</TableCell>
              <TableCell>Used</TableCell>
              <TableCell align="center">Active</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {coupons.map((coupon, index) => (
              <TableRow key={index}>
                <TableCell>{coupon.name}</TableCell>
                <TableCell>{coupon.couponPercentage}%</TableCell>
                <TableCell>{coupon.used}</TableCell>
                <TableCell align="center">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={coupon.isActive}
                        onChange={() => handleToggleActive(index, coupon.isActive, coupon.id)}
                        color="primary"
                      />
                    }
                    label={coupon.isActive ? 'Active' : 'Inactive'}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default Coupons;
