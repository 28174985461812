import React, {useState, useEffect} from 'react'
import OrderScan from './OrderScan';
import OrderDigital from './OrderDigital';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ChatIcon from '../../assets/Icons/chatIcon.png'
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Orders(){

  const { type } = useParams(); // Get the current tab type from URL
  const navigate = useNavigate();
  const [value, setValue] = useState(type || 'scan'); // Set default to 'scan' if type is undefined
  const { t } = useTranslation();

  useEffect(() => {
    if (type !== value) {
      setValue(type); // Sync state with URL parameter
    }
  }, [type]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`/orders/${newValue}`); // Update URL on tab change
  };
    
    return(       
        <>
      
         <div className='order-top-container'>

              <div  className='cont-order-title'>
                 <p className='gradient-text order-title'><span>{t('nav.orders')}</span></p>
              </div>
                 
            <TabContext value={value}>
                <Box >
                <TabList            
                onChange={handleChange} 
                aria-label="Services Tab"
                sx={{
                    '& .MuiTab-root': { // This targets all Tab components inside TabList
                     // Your custom color for all tabs
                      color: '#1a477f',
                      fontSize:{xs:'14px' ,sm:'16px', md:'18px'},
                      textTransform:'none',
                      '&.Mui-selected': { // This targets the selected Tab
                        fontWeight:600,
                        color:'#1a477f'
                      }
                    }
                  }}
                >
                    <Tab label={t('orderScan.ordersScanning')} value="scan" />
                    <Tab label={t('orderScan.ordersDigital')} value="digital" />
                </TabList>
                </Box>
                  <div className='order-tab-container'>
                  <TabPanel 
                  sx={{width:'100%', overflow:'hidden'}}
                  value="scan">
                <OrderScan/>
                </TabPanel>

                <TabPanel
                 sx={{width:'100%'}}
                value="digital">
                <OrderDigital/>
                </TabPanel>
                  </div>
              
            </TabContext>
         </div>
        </>
    );
}