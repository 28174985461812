import React from "react";
import AboutUsImg1 from '../../assets/Images/aboutUs1.png';
import AboutUsImg2 from '../../assets/Images/aboutUs2.png';
import { useTranslation } from 'react-i18next';



export default function AboutUs() {
  const { t } = useTranslation();

  return (
    <div className="about-us-container">
      <p className="gradient-text"><span>{t('aboutUs.title')}</span></p>
      <div className="about-us-content1">
        <div>
          <p>{t('aboutUs.welcomeText')}</p> 
          <p>{t('aboutUs.mission')}</p>
        </div>
        <div>
          <img src={AboutUsImg1} alt="about-us-1" />
        </div>
      </div>
      <p className="gradient-text"><span>{t('aboutUs.whyChoose')}</span></p>
      <div className="about-us-content2">
        <div>
          <p>{t('aboutUs.commitment')}</p> 
        </div>
        <div>
          <img src={AboutUsImg2} alt="about-us-2" />
        </div>
      </div>
      <p>{t('aboutUs.explore')}</p>
   
    </div>
  );
}
