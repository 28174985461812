const candidate = {
    city: '',
    name:'',
    surname:'',
    phoneNumber:'',
    email:'',
    cv:'',
    portfolio:'',
    type: ''
}

export default candidate;