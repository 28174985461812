import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import ChatIcon from '../../assets/Icons/chatIcon.png';
import CandidateSpecialist from './CandidateSpecialist';

export default function Orders() {
  const { t } = useTranslation(); // Initialize useTranslation
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className='order-top-container'>
        <div className='cont-order-title'>
          <p className='gradient-text order-title'>
            <span>{t('candidate.pageTitle')}</span>
          </p>
        </div>

        <TabContext value={value}>
          <Box>
            <TabList
              onChange={handleChange}
              aria-label="Candidate Tab"
              sx={{
                '& .MuiTab-root': {
                  color: '#1a477f',
                  fontSize: { xs: '14px', sm: '16px', md: '18px' },
                  textTransform: 'none',
                  '&.Mui-selected': {
                    fontWeight: 600,
                    color: '#1a477f',
                  }
                }
              }}
            >
              <Tab label={t('candidate.tabScanningSpecialist')} value="1" />
              <Tab label={t('candidate.tabBIMModeler')} value="2" />
            </TabList>
          </Box>
          <div className='order-tab-container'>
            <TabPanel value="1">
              <CandidateSpecialist candidateType={'scanning'} />
            </TabPanel>

            <TabPanel value="2">
              <CandidateSpecialist candidateType={'bim'} />
            </TabPanel>
          </div>
        </TabContext>
      </div>
    </>
  );
}
