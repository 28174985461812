import React, { useState, useEffect } from "react";
import VideoInfo from "../../assets/Images/video-info.png";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField, Button } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";
import InputAdornment from "@mui/material/InputAdornment";
import ClockIcon from "../../assets/Icons/clockIcon.png";
import MsgIcon from "../../assets/Icons/msgIcon.png";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import VideoPlayer from "./VideoPlayer";
import CalendlyWidget from "./CalendlyWidget";
import {
  scheduleDemo,
  getAllDisableDates,
  getCurrrentUser,
} from "../../API/apiService";

import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"; 
import "dayjs/locale/en";
import "dayjs/locale/sq";

export default function MoreInfo() {
  const { t, i18n } = useTranslation(); 
  const [email, setEmail] = useState("");
  const [formattedDateTime, setFormattedDateTime] = useState("");
  const [selectedDateTime, setSelectedDateTime] = useState(dayjs());
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [disabledDates, setDisabledDates] = useState([]);

  // useEffect(() => {
  //   const getDisableDates = async () => {
  //     try {
  //       const response = await getAllDisableDates();
  //       const convertedArray = response.data.map((item) => ({
  //         id: item.id,
  //         disabledDate: dayjs(item.disableDate),
  //       }));
  //       setDisabledDates(convertedArray);
  //     } catch (error) {
  //     }
  //   };

  //   if (getCurrrentUser() !== null) {
  //     getDisableDates();
  //   }
  // }, []);

  const handleDateChange = (newValue) => {
    if (!newValue || !newValue.isValid()) {
      return;
    }
    const formatted = newValue.format("DD/MM/YYYY HH:mm");
    setFormattedDateTime(formatted);
    setSelectedDateTime(newValue);
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const scheduleDemoReq = async (currentUser) => {
    try {
      setLoading(true);
      const payload = {
        email: email,
        scheduleDateTime: formattedDateTime,
        name: currentUser["firstName"],
        surname: currentUser["lastName"],
        userId: currentUser["id"],
      };
      const response = await scheduleDemo(payload);
      return response;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
      setEmail('')
      setFormattedDateTime('')
    }
  };

  const handleScheduleDemo = async () => {
    
    const currentUser = getCurrrentUser();
    if (currentUser === null) {
      navigate("/login");
      return;
    }
    if (formattedDateTime === "") {
      toast.error(t("present.selectDateTime")); // Use translation
      return;
    }
    if (email === "") {
      toast.error(t("present.enterEmail")); // Use translation
      return;
    }

    const response = scheduleDemoReq(currentUser);
    toast.promise(
      response,
      {
        loading: t("present.sendingRequest"), // Use translation
        success: t("present.requestSent"), // Use translation
        error: (err) => {
          return (
            err?.response?.data?.errorMessage || t("present.requestFailed")
          ); // Use translation
        },
      },
      {
        success: {
          duration: 5000,
        },
      }
    );
  };
  const handleReset = () => {
    setSelectedDateTime(null); // Clear the selected date and time
    setFormattedDateTime("");
  };
  const CustomActionBar = () => <></>;
  function shouldDisableSpecificDates(date) {
    return disabledDates?.some((disabledDate) =>
      dayjs(date).isSame(disabledDate.disabledDate, "day")
    );
  }

  const textFieldCustom = (label, id, icon) => (
    <TextField
      size="small"
      type="text"
      id={id}
      placeholder={label}
      value={formattedDateTime}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: "12px",
          fontSize: "12px",
          padding: "4px",
        },
        width: { xs: "300px", md: "400px" },
      }}
      InputProps={{
        fontSize: "12px",
        padding: "14px",
        startAdornment: (
          <InputAdornment position="start">
            <img
              style={{ marginLeft: "10px", width: "15px" }}
              src={icon}
              alt="clock-icon"
            />
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        sx: {
          fontSize: "11px",
          fontWeight: "600",
          fontFamily: "Roboto, sans-serif",
          padding: "3px",
        },
      }}
      style={{ marginBottom: 26, fontSize: "12px" }}
    />
  );

  const textFieldCustom2 = (label, id, icon) => (
    <TextField
      size="small"
      type="text"
      id={id}
      placeholder={label}
      value={email}
      onChange={handleChange}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: "12px",
          fontSize: "12px",
          padding: "4px",
        },
        width: { xs: "300px", md: "400px" },
      }}
      InputProps={{
        fontSize: "12px",
        padding: "14px",
        startAdornment: (
          <InputAdornment position="start">
            <img
              style={{ marginLeft: "10px", width: "15px" }}
              src={icon}
              alt="clock-icon"
            />
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        sx: {
          fontSize: "11px",
          fontWeight: "600",
          fontFamily: "Roboto, sans-serif",
          padding: "3px",
        },
      }}
      style={{ marginBottom: 26, fontSize: "12px" }}
    />
  );

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div className="more-info-cont">
      <div className="more-info-content">
        <p className="gradient-text">
          <span>{t("present.demoVideo")}</span>
        </p>
        <p>{t("present.askToLearn")}</p>
        <VideoPlayer />
        {/* <img className="more-info-img" src={VideoInfo} alt="video-explain" /> */}
      </div>
      <div>
        <p className="gradient-text more-info-title">
          <span>{t("present.wantMoreInfo")}</span>
        </p>
        <div className="ii-top">
          <div className="info-inputs-cont">
            <CalendlyWidget
            scan={false}
            />
            {/* <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={i18n.language === "en" ? "en" : "sq"}
            >
              <div style={{ width: isSmallScreen ? "305px" : "100%" }}>
                <StaticDateTimePicker
                  orientation={isSmallScreen ? "portrait" : "landscape"}
                  value={selectedDateTime}
                  onChange={handleDateChange}
                  minDateTime={dayjs()}
                  shouldDisableDate={shouldDisableSpecificDates}
                  ampm={false}
                  minutesStep={1}
                  sx={{ width: "100%", backgroundColor: "transparent" }}
                  components={{
                    ActionBar: CustomActionBar, // Use the custom ActionBar component
                  }}
                />
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleReset}
                  style={{ marginTop: "10px" }}
                >
                  Reset
                </Button>
              </div>
            </LocalizationProvider> */}

            {/* <div>
              {textFieldCustom(t("present.dateAndTime"), "datetime", ClockIcon)}
              {textFieldCustom2(t("present.emailAddress"), "email", MsgIcon)}
            </div>
          </div>
          <div>
            <button
              onClick={handleScheduleDemo}
              disabled={loading}
              style={{ backgroundColor: loading ? "#cccccc" : "" }}
            >
              {t("present.schedulePresentation")}
            </button>*/}
         </div> 
       </div> 
      </div>
    </div>
  );
}
