import React from "react";
import Services from "./Services/Services";
import Navbar from "./Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Geocoding from "./Geocoding";
import PricingCard from "./PricingCard";
import Testgeo from "./testgeo";
import Orders from "./Orders/Orders";
import OrderDetailsScan from "./Orders/OrderDetailsScan";
import OrderDetailsDigital from "./Orders/OrderDetailsDigital";
import RealEstateScan from "./Services/RealEstateScan";
import RealEstateDigital from "./Services/RealEstateDigital";
import Candidate from "./Candidate/Candidate";
import "../Styles/media.css";
import AboutUs from "./Resource/AboutUs";
import Home from "../Components/Resource/Home";
import PrivacyPolicy from "./Resource/PrivacyPolicy";
import TermsAndCondition from "./Resource/TermsAndCondition";
import Projects from "./Resource/Projects";
import Footer from "./Resource/Footer";
import RegisterForm from "./Orders/Auth/RegisterForm";
import toast, { Toaster } from "react-hot-toast";
import MoreInfo from "./Resource/MoreInfo";
import LoginForm from "./Orders/Auth/LoginForm";
import Unauthorized from "./Resource/Unauthorized";
import ServerError from "./Resource/ServerError";
import NotFoundPage from "./Resource/NotFoundPage";
import ResetPassword from "./Resource/ResetPassword";
import ConfirmNewPassword from "./Resource/ConfirmNewPassword";
import ResetLinkSent from "./Resource/ResetLinkSent";
import PasswordConfirmed from "./Resource/PasswordConfirmed";
import CongratsRegister from "./Resource/CongratsRegister";
import ForgeViewer from "./AutoDeskForge/ForgeViewer";
import PaymentCard from "./Resource/Payment";
import SuccessfulFrom from "../Components/Resource/SuccessfulForm";
import AdminPanel from "../Components/Admin/AdminPanel";
import SubscriptionOptions from "./Subscription/SubscriptionOptions";
import UserProfile from "./User/UserProfile";
import EmailChangeConfirmation from "./Resource/EmailChangeConfirmation";
import EmailConfirmed from "./Resource/EmailConfirmed";
import WhatsAppButton from "./ChatBot";
import ConfirmEmail from "./Resource/ConfirmEmail";
import AdminManagement from "./Admin/Users/AdminManagement";
import ScrollToTop from "./Resource/ScrollToTop";

export default function Layout() {
  return (
    <div className="layout-container">
      <Toaster position="top-center" reverseOrder={false} />

      <Navbar />

      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/register" element={<RegisterForm />} />
        <Route exact path="/login" element={<LoginForm />} />
        <Route path="/services/:type" element={<Services />} />
        <Route path="/terms-condition" element={<TermsAndCondition />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/candidate" element={<Candidate />} />
        <Route path="/orders/:type" element={<Orders />} />
        <Route
          exact
          path="/orders/scan/:orderId"
          element={<OrderDetailsScan />}
        />
        <Route
          exact
          path="/orders/digital/:orderId"
          element={<OrderDetailsDigital />}
        />
        <Route exact path="/schedule-meeting" element={<MoreInfo />} />
        <Route exact path="/unauthorized" element={<Unauthorized />} />
        <Route exact path="/server-error" element={<ServerError />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/new-password" element={<ConfirmNewPassword />} />
        <Route path="/reset-link-sent" element={<ResetLinkSent />} />
        <Route path="/password-confirmed" element={<PasswordConfirmed />} />
        <Route path="/email-confirmed" element={<EmailConfirmed />} />
        <Route path="/congrats-register" element={<CongratsRegister />} />
        <Route path="/request-successful" element={<SuccessfulFrom />} />
        <Route exact path="/admin-panel/*" element={<AdminPanel />} />
        <Route path="/subscription-options" element={<SubscriptionOptions />} />
        <Route path="/user-profile" element={<UserProfile />} />
        <Route path="/admin-management" element={<AdminManagement />} />
        <Route
          path="/confirm-email-change"
          element={<EmailChangeConfirmation />}
        />
        <Route path="/confirm-email" element={<ConfirmEmail />} />
      </Routes>

      <Footer />
    </div>
  );
}
