import React, { useState } from "react";
import FieldRegister from "./FieldRegister";
import PasswordField from "./PasswordField";
import { loginUser } from "../../../API/apiService";
import outMsg from '../../../assets/Icons/outMsg.png';
import {
  emailValidator,
  validatePassword,
} from "../../../Validators/validator";
import Logo from '../../../assets/Images/Logo.png';
import LoginImg from '../../../assets/Images/Login.png';
import toast from "react-hot-toast";
import { useNavigate } from 'react-router-dom';
import { initialLoginUser } from "../../../Models/user";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { getCurrrentUser } from "../../../API/apiService";


export default function LoginForm() {
  const { t } = useTranslation();
  const [loginData, setLoginData] = useState(initialLoginUser);
  const navigate = useNavigate();

  useEffect(() => {
    if(getCurrrentUser() !== null){
      navigate('/');
    }
  },[])

  const handleInputChange = (event, parent, child) => {
    const value = event.target.value;
    setLoginData(prevState => ({
      ...prevState,
      [child]: value
    }));
  };

  const handleUserLogin = async (e) => {
    e.preventDefault();

    let errors = {};
    errors.email = emailValidator(loginData.email);
    errors.password = validatePassword(loginData.password);

    if (errors.email === '' && errors.password === '') {
      const toastId = toast.loading();
      try {
        const response = await loginUser(loginData);
          if(response?.success){
            setLoginData(initialLoginUser);
            toast.success(t('login.successMessage'));
            navigate('/');
          }
      } catch (error) {

      }finally{
        toast.dismiss(toastId)
      }
    } else {
      toast.error(t('login.validationError'));
    }
  };

  return (
    <div className="login-container">
      <div className="login-content">
        <div>
          <img src={Logo} alt="logo-img" />
        </div>

        <div className="login-form">
          <div>
            <img src={LoginImg} alt="login-img" />
          </div>
          <div className="login-fields">
            <h3>{t('login.welcomeBack')}</h3>
            <FieldRegister
              label={t('login.emailPlaceholder')}
              type="email"
              fieldName="email"
              icon={outMsg}
              showAdornment={false}
              validator={emailValidator}
              onChange={handleInputChange}
            />

            <PasswordField
              label={t('login.passwordPlaceholder')}
              fieldName="password"
              validator={validatePassword}
              onChange={handleInputChange}
            />
            <div className="forgot-password">
              <Link
                className="link"
                to='/reset-password'
              >
                <p className="forgot-password-link">{t('login.forgotPassword')}</p>
              </Link>
            </div>

            <div>
              <button
                onClick={handleUserLogin}
                type="submit"
                className="btn-register"
              >
                {t('login.loginButton')}
              </button>
            </div>
            <div className="login-links">
              <p>{t('login.noAccount')}
                <Link
                  className="link"
                  to='/register'
                >
                  <span>
                    &nbsp; {t('login.signUp')}
                  </span>
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="terms-cont">
          <p className="p-terms">
            <Link
              className="link"
              to='/terms-Condition'
            >
              <span className="term-policy-link">{t('login.termsOfUse')}</span>
            </Link>
            <span> &nbsp; | &nbsp; </span>
            <Link
              className="link"
              to='/privacy-policy'
            >
              <span className="term-policy-link">{t('login.privacyPolicy')}</span>
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}
