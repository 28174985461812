import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import BuildingImg from "../../assets/Images/building.png";
import BuildingImg2 from "../../assets/Images/BuildImg2.png";
import { Link, useNavigate } from "react-router-dom";
import {
  getCurrrentUser,
  getScanProjectsByUserId,
  cancelProject,
} from "../../API/apiService";
import NoOrders from "./NoOrders";
import toast from "react-hot-toast";
export default function OrderScan() {
  const { t } = useTranslation(); // Translation hook

  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();
  const [orderCancel, setOrderCancel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (getCurrrentUser() === null) {
      navigate("/login");
      return;
    }

    const projectsScan = async () => {
      try {
        setIsLoading(true);
        const result = await getScanProjectsByUserId(getCurrrentUser()["id"]);

        if (result.success === true) {
          setOrders(result);
        }
      } catch (error) {
        navigate("/server-error");
      } finally {
        setIsLoading(false);
      }
    };

    projectsScan();
  }, [orderCancel]);

  const cancelProjectAsync = async (order) => {
    try {
      const payload = {
        projectId: order.projectId,
        serviceType: "Scan",
      };
      const response = await cancelProject(payload);
      return response;
    } catch (error) {
      throw error;
    } finally {
      setOrderCancel(!orderCancel);
    }
  };

  const handleCancelproject = async (event, order) => {
    event.stopPropagation();
    event.preventDefault();
    if (order.status === "Draft") {
      const response = cancelProjectAsync(order);
      toast.promise(
        response,
        {
          loading: t("orderScan.loading"),
          success: <b>{t("orderScan.success")}</b>,
          error: <b>{t("orderScan.error")}</b>,
        },
        {
          success: { duration: 6000 },
        }
      );
    } else {
      toast.error(t("orderScan.onlyDraftCancellation"));
    }
  };

  return orders?.data?.length === 0 ? (
    <div className="no-order">
      <NoOrders redirectTo="/services/scan" />
    </div>
  ) : (
    <div className="order-container">
      {isLoading ? (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      ) : (
        orders?.data?.map((order) => (
          <Link
            className="link"
            key={order.projectId}
            to={`/orders/scan/${order.projectId}`}
          >
            <div key={order.projectId} className="order-card-container">
              <div className="order-card-info">
                <img
                  className="img-project"
                  src={order?.photoUrl || BuildingImg2}
                  alt="projectPhoto"
                />

                <p style={{alignSelf:'start'}}>{order.projectName}</p>
                <div className="cancel-order">
                  <p>{order.propertyInfoScan.address.addressLine}</p>
                  {order.status === "Draft" && (
                    <div>
                      <p
                        onClick={(event) => handleCancelproject(event, order)}
                        className="refound-btn"
                      >
                        {t("orderScan.cancelButton")}
                      </p>
                      <div className="tooltip">
                        {t("orderScan.cancelTooltip")}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="card-param-cont">
                <p className="order-param param-label">
                  {t("orderScan.propertyTypeLabel")}
                </p>
                <p className="order-param param-label">
                  {t("orderScan.spaceSiteSizeLabel")}
                </p>
                <p className="order-param param-label">{t("orderScan.statusLabel")}</p>
                <p className="order-param param-info param-info1">
                  {order.propertyInfoScan.propertyType[0].type}
                </p>
                <p className="order-param param-info param-info2">
                  {order.propertyInfoScan.sizeOfSpace}/
                  {order.propertyInfoScan.sizeOfSite} m²
                </p>

                <p
                  className={`order-param param-info param-info3 status-${order.status}`}
                >
                 {order.status === "InProgress" 
                  ? t('status.progress')
                  : order.status === "Draft" 
                  ? t('status.draft')
                  : order.status === "Canceled" 
                  ? t('status.canceled')
                  : order.status === "Completed" 
                  ? t('status.completed')
                  : null
                }

                </p>
              </div>
            </div>
          </Link>
        ))
      )}
    </div>
  );
}
