import React from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        bgcolor: '#fff',
        color: '#1a477f',
      }}
    >
      <Box>
        <Typography variant="h1" sx={{ fontSize: '6rem', fontWeight: 'bold' }}>
          {t('notFoundPage.title')}
        </Typography>
        <Typography variant="h5" sx={{ mb: 3 }}>
          {t('notFoundPage.message')}
        </Typography>
        <Button
          variant="outlined"
          component={Link}
          to="/"
          sx={{
            borderColor: '#1a477f',
            color: '#1a477f',
            '&:hover': {
              bgcolor: '#1a477f',
              color: '#fff',
            },
          }}
        >
          {t('notFoundPage.buttonText')}
        </Button>
      </Box>
    </Container>
  );
};

export default NotFoundPage;
